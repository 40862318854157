// summary stats JSON source files
const acute_blood_gene_stats = require('./stats/acute_blood');
const acute_muscle_gene_stats = require('./stats/acute_muscle');
const longterm_blood_gene_stats = require('./stats/longterm_blood');
const longterm_muscle_gene_stats = require('./stats/longterm_muscle');

export const summaryStats = {
  acute_blood: acute_blood_gene_stats,
  acute_muscle: acute_muscle_gene_stats,
  longterm_blood: longterm_blood_gene_stats,
  longterm_muscle: longterm_muscle_gene_stats,
};

const MetaAnalysisGenes = [
  'A1BG-AS1',
  'A2M',
  'A2ML1',
  'A4GNT',
  'AAAS',
  'AACS',
  'AADAT',
  'AAGAB',
  'AAK1',
  'AAMDC',
  'AAMP',
  'AANAT',
  'AAR2',
  'AARS1',
  'AARS2',
  'AASDH',
  'AASS',
  'AATBC',
  'ABAT',
  'ABCA1',
  'ABCA4',
  'ABCA5',
  'ABCA6',
  'ABCA7',
  'ABCA8',
  'ABCA9',
  'ABCA11P',
  'ABCA12',
  'ABCA13',
  'ABCB1',
  'ABCB4',
  'ABCB5',
  'ABCB6',
  'ABCB7',
  'ABCB8',
  'ABCB9',
  'ABCB11',
  'ABCC1',
  'ABCC2',
  'ABCC4',
  'ABCC5',
  'ABCC6',
  'ABCC6P1',
  'ABCC8',
  'ABCC9',
  'ABCC10',
  'ABCC11',
  'ABCD1',
  'ABCD2',
  'ABCD3',
  'ABCD4',
  'ABCE1',
  'ABCF1',
  'ABCF2',
  'ABCF3',
  'ABCG1',
  'ABCG4',
  'ABCG8',
  'ABHD1',
  'ABHD2',
  'ABHD3',
  'ABHD4',
  'ABHD5',
  'ABHD6',
  'ABHD8',
  'ABHD10',
  'ABHD11',
  'ABHD12',
  'ABHD12B',
  'ABHD14A',
  'ABHD14B',
  'ABHD15',
  'ABHD16A',
  'ABHD16B',
  'ABHD17A',
  'ABHD17B',
  'ABHD17C',
  'ABHD18',
  'ABI1',
  'ABI2',
  'ABI3',
  'ABI3BP',
  'ABL1',
  'ABL2',
  'ABLIM2',
  'ABLIM3',
  'ABO',
  'ABR',
  'ABRA',
  'ABRACL',
  'ABRAXAS1',
  'ABRAXAS2',
  'ABT1',
  'ABTB1',
  'ABTB2',
  'ACAA1',
  'ACAA2',
  'ACACA',
  'ACACB',
  'ACAD8',
  'ACAD9',
  'ACAD10',
  'ACAD11',
  'ACADL',
  'ACADM',
  'ACADS',
  'ACADSB',
  'ACADVL',
  'ACAN',
  'ACAP2',
  'ACAP3',
  'ACAT1',
  'ACAT2',
  'ACBD3',
  'ACBD4',
  'ACBD5',
  'ACBD6',
  'ACCS',
  'ACE',
  'ACER3',
  'ACHE',
  'ACIN1',
  'ACKR1',
  'ACKR3',
  'ACKR4',
  'ACLY',
  'ACMSD',
  'ACO1',
  'ACO2',
  'ACOT1',
  'ACOT2',
  'ACOT6',
  'ACOT7',
  'ACOT8',
  'ACOT9',
  'ACOT11',
  'ACOT13',
  'ACOX1',
  'ACOX2',
  'ACP1',
  'ACP2',
  'ACP6',
  'ACR',
  'ACRV1',
  'ACSBG1',
  'ACSF2',
  'ACSL1',
  'ACSL3',
  'ACSL4',
  'ACSL5',
  'ACSL6',
  'ACSM1',
  'ACSM2A',
  'ACSM2B',
  'ACSM3',
  'ACSM5',
  'ACSS1',
  'ACSS2',
  'ACSS3',
  'ACTA1',
  'ACTB',
  'ACTC1',
  'ACTG1',
  'ACTG2',
  'ACTL6A',
  'ACTL6B',
  'ACTL7A',
  'ACTL8',
  'ACTL9',
  'ACTN1',
  'ACTN2',
  'ACTN3',
  'ACTN4',
  'ACTR1A',
  'ACTR1B',
  'ACTR2',
  'ACTR3',
  'ACTR3B',
  'ACTR3C',
  'ACTR5',
  'ACTR6',
  'ACTR8',
  'ACTR10',
  'ACTRT2',
  'ACTRT3',
  'ACVR1',
  'ACVR1B',
  'ACVR1C',
  'ACVR2A',
  'ACVR2B-AS1',
  'ACVR2B',
  'ACVRL1',
  'ACY1',
  'ACYP1',
  'ACYP2',
  'ADA',
  'ADA2',
  'ADAD1',
  'ADAD2',
  'ADAL',
  'ADAM2',
  'ADAM6',
  'ADAM7',
  'ADAM9',
  'ADAM11',
  'ADAM12',
  'ADAM15',
  'ADAM17',
  'ADAM18',
  'ADAM19',
  'ADAM20',
  'ADAM23',
  'ADAM28',
  'ADAM30',
  'ADAM32',
  'ADAM33',
  'ADAMDEC1',
  'ADAMTS1',
  'ADAMTS2',
  'ADAMTS3',
  'ADAMTS4',
  'ADAMTS5',
  'ADAMTS6',
  'ADAMTS7',
  'ADAMTS9',
  'ADAMTS10',
  'ADAMTS12',
  'ADAMTS13',
  'ADAMTS14',
  'ADAMTS15',
  'ADAMTS16',
  'ADAMTS17',
  'ADAMTS18',
  'ADAMTS19',
  'ADAMTSL1',
  'ADAMTSL2',
  'ADAMTSL3',
  'ADAMTSL4',
  'ADAMTSL5',
  'ADAP2',
  'ADAR',
  'ADARB1',
  'ADAT1',
  'ADAT2',
  'ADCK1',
  'ADCK2',
  'ADCY1',
  'ADCY2',
  'ADCY3',
  'ADCY4',
  'ADCY6',
  'ADCY7',
  'ADCY9',
  'ADCY10P1',
  'ADCYAP1R1',
  'ADD1',
  'ADD2',
  'ADD3',
  'ADGB',
  'ADGRA1',
  'ADGRA2',
  'ADGRA3',
  'ADGRD1',
  'ADGRD2',
  'ADGRE5',
  'ADGRF2',
  'ADGRF4',
  'ADGRF5',
  'ADGRG1',
  'ADGRG2',
  'ADGRG3',
  'ADGRG4',
  'ADGRG5',
  'ADGRG6',
  'ADGRG7',
  'ADGRL1',
  'ADGRL2',
  'ADGRL3',
  'ADGRL4',
  'ADH1A',
  'ADH1B',
  'ADH1C',
  'ADH5',
  'ADHFE1',
  'ADI1',
  'ADIPOQ',
  'ADIPOR1',
  'ADIPOR2',
  'ADIRF',
  'ADK',
  'ADM',
  'ADM5',
  'ADNP',
  'ADNP2',
  'ADO',
  'ADORA1',
  'ADORA2A-AS1',
  'ADORA2A',
  'ADORA2B',
  'ADORA3',
  'ADPRH',
  'ADPRHL1',
  'ADPRHL2',
  'ADPRM',
  'ADRA1B',
  'ADRA1D',
  'ADRA2C',
  'ADRB1',
  'ADRB2',
  'ADRB3',
  'ADRM1',
  'ADSL',
  'ADSS1',
  'ADSS2',
  'ADTRP',
  'AEBP1',
  'AEBP2',
  'AEN',
  'AFAP1',
  'AFAP1L1',
  'AFAP1L2',
  'AFDN-DT',
  'AFDN',
  'AFF1',
  'AFF3',
  'AFF4',
  'AFG1L',
  'AFG3L1P',
  'AFG3L2',
  'AFM',
  'AFMID',
  'AFP',
  'AFTPH',
  'AGA',
  'AGAP1',
  'AGAP2',
  'AGAP4',
  'AGAP6',
  'AGAP9',
  'AGAP11',
  'AGBL3',
  'AGBL4',
  'AGER',
  'AGFG2',
  'AGGF1',
  'AGK',
  'AGL',
  'AGMAT',
  'AGO2',
  'AGO3',
  'AGO4',
  'AGPAT2',
  'AGPAT3',
  'AGPAT4-IT1',
  'AGPAT4',
  'AGPAT5',
  'AGPS',
  'AGR2',
  'AGRN',
  'AGT',
  'AGTPBP1',
  'AGTR1',
  'AGTRAP',
  'AGXT',
  'AGXT2',
  'AHCTF1',
  'AHCY',
  'AHCYL1',
  'AHCYL2',
  'AHDC1',
  'AHI1',
  'AHNAK',
  'AHNAK2',
  'AHR',
  'AHRR',
  'AHSA1',
  'AHSA2P',
  'AHSG',
  'AHSP',
  'AICDA',
  'AIDA',
  'AIF1',
  'AIF1L',
  'AIFM1',
  'AIFM2',
  'AIG1',
  'AIMP1',
  'AIMP2',
  'AIP',
  'AIPL1',
  'AJAP1',
  'AJM1',
  'AJUBA',
  'AK1',
  'AK2',
  'AK3',
  'AK4',
  'AK7',
  'AK8',
  'AK9',
  'AKAP1',
  'AKAP3',
  'AKAP4',
  'AKAP6',
  'AKAP7',
  'AKAP8',
  'AKAP8L',
  'AKAP9',
  'AKAP12',
  'AKAP13',
  'AKIP1',
  'AKIRIN1',
  'AKIRIN2',
  'AKNA',
  'AKNAD1',
  'AKR1A1',
  'AKR1B1',
  'AKR1B10',
  'AKR1C1',
  'AKR1C2',
  'AKR1C3',
  'AKR1E2',
  'AKT1',
  'AKT1S1',
  'AKT2',
  'AKT3',
  'AKTIP',
  'ALAS1',
  'ALAS2',
  'ALB',
  'ALCAM',
  'ALDH1A1',
  'ALDH1A2',
  'ALDH1A3',
  'ALDH1B1',
  'ALDH1L1',
  'ALDH1L2',
  'ALDH2',
  'ALDH3A2',
  'ALDH3B1',
  'ALDH3B2',
  'ALDH4A1',
  'ALDH5A1',
  'ALDH6A1',
  'ALDH7A1',
  'ALDH8A1',
  'ALDH9A1',
  'ALDH16A1',
  'ALDH18A1',
  'ALDOA',
  'ALDOC',
  'ALG1',
  'ALG2',
  'ALG3',
  'ALG5',
  'ALG6',
  'ALG8',
  'ALG9',
  'ALG10',
  'ALG11',
  'ALG12',
  'ALG13',
  'ALG14',
  'ALKAL2',
  'ALKBH1',
  'ALKBH3',
  'ALKBH4',
  'ALKBH5',
  'ALKBH6',
  'ALKBH7',
  'ALKBH8',
  'ALMS1',
  'ALOX5AP',
  'ALOX12P2',
  'ALOX15',
  'ALOX15B',
  'ALPK2',
  'ALPK3',
  'ALPL',
  'ALS2',
  'ALS2CL',
  'ALX1',
  'ALX3',
  'ALYREF',
  'AMACR',
  'AMBN',
  'AMBRA1',
  'AMD1',
  'AMDHD1',
  'AMDHD2',
  'AMER3',
  'AMFR',
  'AMHR2',
  'AMIGO1',
  'AMMECR1',
  'AMN1',
  'AMOT',
  'AMOTL1',
  'AMOTL2',
  'AMPD1',
  'AMPD2',
  'AMPD3',
  'AMPH',
  'AMT',
  'AMY2A',
  'AMY2B',
  'AMZ2',
  'ANAPC1',
  'ANAPC2',
  'ANAPC4',
  'ANAPC5',
  'ANAPC7',
  'ANAPC11',
  'ANAPC13',
  'ANAPC15',
  'ANAPC16',
  'ANGEL1',
  'ANGPT1',
  'ANGPT2',
  'ANGPT4',
  'ANGPTL1',
  'ANGPTL2',
  'ANGPTL4',
  'ANGPTL6',
  'ANGPTL8',
  'ANK1',
  'ANK2',
  'ANK3',
  'ANKAR',
  'ANKDD1A',
  'ANKEF1',
  'ANKFN1',
  'ANKFY1',
  'ANKH',
  'ANKHD1-EIF4EBP3',
  'ANKHD1',
  'ANKIB1',
  'ANKLE1',
  'ANKLE2',
  'ANKMY1',
  'ANKMY2',
  'ANKRA2',
  'ANKRD1',
  'ANKRD2',
  'ANKRD6',
  'ANKRD9',
  'ANKRD10',
  'ANKRD11',
  'ANKRD12',
  'ANKRD13A',
  'ANKRD13B',
  'ANKRD13C',
  'ANKRD13D',
  'ANKRD16',
  'ANKRD17',
  'ANKRD19P',
  'ANKRD20A4',
  'ANKRD23',
  'ANKRD26',
  'ANKRD27',
  'ANKRD28',
  'ANKRD29',
  'ANKRD30A',
  'ANKRD30BP3',
  'ANKRD31',
  'ANKRD33',
  'ANKRD33B',
  'ANKRD34C',
  'ANKRD35',
  'ANKRD36B',
  'ANKRD36BP2',
  'ANKRD36C',
  'ANKRD37',
  'ANKRD39',
  'ANKRD40',
  'ANKRD40CL',
  'ANKRD44',
  'ANKRD45',
  'ANKRD46',
  'ANKRD49',
  'ANKRD50',
  'ANKRD52',
  'ANKRD53',
  'ANKRD54',
  'ANKRD65',
  'ANKS1A',
  'ANKS1B',
  'ANKS3',
  'ANKS6',
  'ANKZF1',
  'ANLN',
  'ANO1',
  'ANO2',
  'ANO3',
  'ANO4',
  'ANO5',
  'ANO6',
  'ANO7',
  'ANO8',
  'ANO10',
  'ANOS1',
  'ANP32A',
  'ANP32B',
  'ANP32D',
  'ANP32E',
  'ANPEP',
  'ANTKMT',
  'ANTXR1',
  'ANTXR2',
  'ANXA1',
  'ANXA2',
  'ANXA2R',
  'ANXA3',
  'ANXA4',
  'ANXA5',
  'ANXA6',
  'ANXA7',
  'ANXA11',
  'AOAH',
  'AOC1',
  'AOC2',
  'AOC3',
  'AOPEP',
  'AOX1',
  'AP1AR',
  'AP1B1',
  'AP1G1',
  'AP1G2',
  'AP1M1',
  'AP1S1',
  'AP1S2',
  'AP1S3',
  'AP2A1',
  'AP2B1',
  'AP2M1',
  'AP2S1',
  'AP3B1',
  'AP3D1',
  'AP3M2',
  'AP3S1',
  'AP3S2',
  'AP4E1',
  'AP4M1',
  'AP4S1',
  'AP5M1',
  'AP5S1',
  'AP5Z1',
  'APAF1',
  'APBA1',
  'APBA3',
  'APBB2',
  'APBB3',
  'APC',
  'APC2',
  'APCDD1',
  'APCDD1L',
  'APCS',
  'APEH',
  'APEX1',
  'APEX2',
  'APH1B',
  'API5',
  'APIP',
  'APLF',
  'APLN',
  'APLNR',
  'APLP2',
  'APMAP',
  'APOA1',
  'APOA2',
  'APOB',
  'APOBEC2',
  'APOBEC3B',
  'APOBEC3C',
  'APOBEC3D',
  'APOBEC3F',
  'APOBEC3G',
  'APOBR',
  'APOC1',
  'APOD',
  'APOE',
  'APOL1',
  'APOL2',
  'APOL3',
  'APOL4',
  'APOL6',
  'APOLD1',
  'APOM',
  'APOO',
  'APP',
  'APPL1',
  'APPL2',
  'APTX',
  'AQP1',
  'AQP3',
  'AQP4',
  'AQP7',
  'AQP8',
  'AQP9',
  'AQP12A',
  'AQP12B',
  'AR',
  'ARAF',
  'ARAP1',
  'ARAP2',
  'ARAP3',
  'ARC',
  'AREG',
  'ARF1',
  'ARF3',
  'ARF4',
  'ARF5',
  'ARF6',
  'ARFGAP1',
  'ARFGAP2',
  'ARFGAP3',
  'ARFGEF3',
  'ARFIP1',
  'ARFRP1',
  'ARG2',
  'ARGLU1',
  'ARHGAP1',
  'ARHGAP4',
  'ARHGAP5',
  'ARHGAP6',
  'ARHGAP9',
  'ARHGAP10',
  'ARHGAP11B',
  'ARHGAP12',
  'ARHGAP15',
  'ARHGAP17',
  'ARHGAP18',
  'ARHGAP19',
  'ARHGAP20',
  'ARHGAP21',
  'ARHGAP24',
  'ARHGAP26',
  'ARHGAP27',
  'ARHGAP27P1',
  'ARHGAP28',
  'ARHGAP29',
  'ARHGAP30',
  'ARHGAP31',
  'ARHGAP32',
  'ARHGAP33',
  'ARHGAP36',
  'ARHGAP44',
  'ARHGAP45',
  'ARHGDIA',
  'ARHGDIB',
  'ARHGEF1',
  'ARHGEF2',
  'ARHGEF3',
  'ARHGEF4',
  'ARHGEF5',
  'ARHGEF6',
  'ARHGEF7',
  'ARHGEF9',
  'ARHGEF10',
  'ARHGEF10L',
  'ARHGEF11',
  'ARHGEF12',
  'ARHGEF15',
  'ARHGEF17',
  'ARHGEF18',
  'ARHGEF19',
  'ARHGEF25',
  'ARHGEF26',
  'ARHGEF28',
  'ARHGEF37',
  'ARHGEF38',
  'ARHGEF39',
  'ARHGEF40',
  'ARID1A',
  'ARID1B',
  'ARID2',
  'ARID3B',
  'ARID4A',
  'ARID4B',
  'ARID5A',
  'ARID5B',
  'ARIH1',
  'ARIH2',
  'ARIH2OS',
  'ARL1',
  'ARL2',
  'ARL2BP',
  'ARL3',
  'ARL4A',
  'ARL4C',
  'ARL4D',
  'ARL5A',
  'ARL5B',
  'ARL5C',
  'ARL6IP1',
  'ARL6IP4',
  'ARL6IP5',
  'ARL6IP6',
  'ARL8A',
  'ARL8B',
  'ARL10',
  'ARL11',
  'ARL14EP',
  'ARL15',
  'ARL17A',
  'ARL17B',
  'ARMC1',
  'ARMC3',
  'ARMC5',
  'ARMC6',
  'ARMC7',
  'ARMC8',
  'ARMCX1',
  'ARMCX2',
  'ARMCX3',
  'ARMCX5',
  'ARMCX6',
  'ARMH3',
  'ARMH4',
  'ARNT',
  'ARNT2',
  'ARNTL',
  'ARPC1A',
  'ARPC1B',
  'ARPC2',
  'ARPC3',
  'ARPC4',
  'ARPC5',
  'ARPC5L',
  'ARPIN',
  'ARPP19',
  'ARPP21',
  'ARR3',
  'ARRB1',
  'ARRDC1-AS1',
  'ARRDC1',
  'ARRDC2',
  'ARRDC3',
  'ARRDC4',
  'ARSA',
  'ARSB',
  'ARSD',
  'ARSF',
  'ARSJ',
  'ARSK',
  'ART1',
  'ART3',
  'ART4',
  'ART5',
  'ARV1',
  'ARVCF',
  'ARX',
  'AS3MT',
  'ASAH1',
  'ASAH2B',
  'ASAP1',
  'ASAP2',
  'ASAP3',
  'ASB1',
  'ASB2',
  'ASB4',
  'ASB5',
  'ASB6',
  'ASB7',
  'ASB8',
  'ASB9',
  'ASB10',
  'ASB11',
  'ASB12',
  'ASB13',
  'ASB14',
  'ASB15',
  'ASB16-AS1',
  'ASB16',
  'ASB17',
  'ASCC1',
  'ASCC2',
  'ASCL2',
  'ASCL5',
  'ASF1A',
  'ASF1B',
  'ASGR2',
  'ASH1L-AS1',
  'ASH1L',
  'ASH2L',
  'ASIC1',
  'ASIC3',
  'ASIC4',
  'ASL',
  'ASMTL',
  'ASNSD1',
  'ASPA',
  'ASPDH',
  'ASPH',
  'ASPM',
  'ASPN',
  'ASPSCR1',
  'ASRGL1',
  'ASS1',
  'ASTN1',
  'ASTN2',
  'ASXL1',
  'ASXL2',
  'ATAD1',
  'ATAD2',
  'ATAD2B',
  'ATAD3A',
  'ATAD3B',
  'ATAD5',
  'ATAT1',
  'ATCAY',
  'ATE1',
  'ATF1',
  'ATF2',
  'ATF3',
  'ATF4',
  'ATF6',
  'ATF6B',
  'ATF7',
  'ATF7IP',
  'ATF7IP2',
  'ATG2A',
  'ATG2B',
  'ATG3',
  'ATG4A',
  'ATG4B',
  'ATG4D',
  'ATG5',
  'ATG7',
  'ATG9A',
  'ATG9B',
  'ATG10',
  'ATG12',
  'ATG13',
  'ATG14',
  'ATG16L1',
  'ATG16L2',
  'ATG101',
  'ATIC',
  'ATL1',
  'ATL2',
  'ATL3',
  'ATM',
  'ATMIN',
  'ATN1',
  'ATOH7',
  'ATOX1',
  'ATP1A1',
  'ATP1A2',
  'ATP1A4',
  'ATP1B1',
  'ATP1B2',
  'ATP1B3',
  'ATP1B4',
  'ATP2A1',
  'ATP2A2',
  'ATP2A3',
  'ATP2B1-AS1',
  'ATP2B2',
  'ATP2B4',
  'ATP2C1',
  'ATP4A',
  'ATP4B',
  'ATP5F1A',
  'ATP5F1B',
  'ATP5F1C',
  'ATP5F1D',
  'ATP5F1E',
  'ATP5IF1',
  'ATP5MC1',
  'ATP5MC2',
  'ATP5MC3',
  'ATP5MD',
  'ATP5ME',
  'ATP5MF',
  'ATP5MG',
  'ATP5MPL',
  'ATP5PB',
  'ATP5PD',
  'ATP5PF',
  'ATP5PO',
  'ATP6AP1',
  'ATP6AP2',
  'ATP6V0A1',
  'ATP6V0A2',
  'ATP6V0B',
  'ATP6V0C',
  'ATP6V0D1',
  'ATP6V0D2',
  'ATP6V0E1',
  'ATP6V1A',
  'ATP6V1B1',
  'ATP6V1B2',
  'ATP6V1C2',
  'ATP6V1D',
  'ATP6V1E1',
  'ATP6V1E2',
  'ATP6V1F',
  'ATP6V1FNB',
  'ATP6V1G1',
  'ATP6V1H',
  'ATP7A',
  'ATP8A1',
  'ATP8B1',
  'ATP8B2',
  'ATP8B4',
  'ATP8B5P',
  'ATP9A',
  'ATP9B',
  'ATP10A',
  'ATP10B',
  'ATP10D',
  'ATP11A',
  'ATP11B',
  'ATP12A',
  'ATP13A1',
  'ATP13A3',
  'ATP13A4',
  'ATP13A5',
  'ATP23',
  'ATPAF1',
  'ATPAF2',
  'ATPSCKMT',
  'ATR',
  'ATRAID',
  'ATRIP',
  'ATRN',
  'ATRNL1',
  'ATRX',
  'ATXN1',
  'ATXN2',
  'ATXN2L',
  'ATXN3',
  'ATXN7',
  'ATXN7L1',
  'ATXN7L2',
  'ATXN7L3',
  'ATXN7L3B',
  'ATXN10',
  'AUH',
  'AUNIP',
  'AUP1',
  'AURKA',
  'AURKAIP1',
  'AURKB',
  'AURKC',
  'AUTS2',
  'AVEN',
  'AVIL',
  'AVL9',
  'AVPI1',
  'AVPR1A',
  'AVPR1B',
  'AVPR2',
  'AWAT1',
  'AXIN1',
  'AXIN2',
  'AXL',
  'AZGP1',
  'AZI2',
  'AZIN1',
  'B2M',
  'B3GALNT2',
  'B3GALT1',
  'B3GALT2',
  'B3GALT5-AS1',
  'B3GALT6',
  'B3GAT2',
  'B3GAT3',
  'B3GLCT',
  'B3GNT2',
  'B3GNT3',
  'B3GNT4',
  'B3GNT5',
  'B3GNT6',
  'B3GNT9',
  'B4GALNT1',
  'B4GALNT3',
  'B4GALNT4',
  'B4GALT1',
  'B4GALT2',
  'B4GALT3',
  'B4GALT4',
  'B4GALT5',
  'B4GALT6',
  'B4GALT7',
  'BAALC',
  'BAAT',
  'BABAM2',
  'BACE1',
  'BACE2',
  'BACH1',
  'BACH2',
  'BAG1',
  'BAG2',
  'BAG3',
  'BAG4',
  'BAG5',
  'BAG6',
  'BAHD1',
  'BAIAP2-DT',
  'BAIAP3',
  'BAK1',
  'BANF1',
  'BANK1',
  'BANP',
  'BAP1',
  'BARD1',
  'BASP1-AS1',
  'BASP1',
  'BAX',
  'BAZ1A',
  'BAZ1B',
  'BAZ2A',
  'BAZ2B',
  'BBOF1',
  'BBOX1',
  'BBS1',
  'BBS2',
  'BBS4',
  'BBS5',
  'BBS7',
  'BBS9',
  'BBS10',
  'BBS12',
  'BBX',
  'BCAM',
  'BCAN',
  'BCAP29',
  'BCAP31',
  'BCAR1',
  'BCAR3',
  'BCAS1',
  'BCAS2',
  'BCAS3',
  'BCAS4',
  'BCAT1',
  'BCAT2',
  'BCCIP',
  'BCDIN3D',
  'BCHE',
  'BCKDHA',
  'BCKDHB',
  'BCKDK',
  'BCL2',
  'BCL2L1',
  'BCL2L10',
  'BCL2L11',
  'BCL2L13',
  'BCL2L14',
  'BCL3',
  'BCL6',
  'BCL6B',
  'BCL7A',
  'BCL7B',
  'BCL9',
  'BCL9L',
  'BCL10',
  'BCLAF1',
  'BCLAF3',
  'BCO2',
  'BCOR',
  'BCORL1',
  'BCORP1',
  'BCR',
  'BCS1L',
  'BDH1',
  'BDH2',
  'BDNF',
  'BDP1',
  'BEAN1',
  'BECN1',
  'BEGAIN',
  'BEND2',
  'BEND3',
  'BEND5',
  'BEND6',
  'BEND7',
  'BEST1',
  'BEST3',
  'BET1',
  'BET1L',
  'BEX3',
  'BEX4',
  'BFAR',
  'BFSP1',
  'BGLAP',
  'BGN',
  'BHLHB9',
  'BHLHE22',
  'BHLHE40',
  'BHLHE41',
  'BHMT',
  'BHMT2',
  'BICC1',
  'BICD1',
  'BICD2',
  'BICDL1',
  'BICRA',
  'BICRAL',
  'BID',
  'BIN1',
  'BIN2',
  'BIN3',
  'BIRC2',
  'BIRC3',
  'BIRC5',
  'BIVM',
  'BLCAP',
  'BLM',
  'BLMH',
  'BLNK',
  'BLOC1S1',
  'BLOC1S4',
  'BLOC1S6',
  'BLVRA',
  'BLVRB',
  'BLZF1',
  'BMF',
  'BMI1',
  'BMP1',
  'BMP2K',
  'BMP4',
  'BMP5',
  'BMP6',
  'BMP7',
  'BMP8A',
  'BMP8B',
  'BMP10',
  'BMP15',
  'BMPER',
  'BMPR1A',
  'BMPR1B',
  'BMS1',
  'BMS1P1',
  'BMT2',
  'BMX',
  'BNC2',
  'BNIP1',
  'BNIP2',
  'BNIP3',
  'BNIP3L',
  'BOC',
  'BOD1',
  'BOD1L1',
  'BOK',
  'BOLA1',
  'BOLA2',
  'BOLA2B',
  'BOLA3',
  'BOLL',
  'BOP1',
  'BORCS5',
  'BORCS6',
  'BORCS7',
  'BORCS8-MEF2B',
  'BPGM',
  'BPHL',
  'BPI',
  'BPIFA3',
  'BPIFA4P',
  'BPIFB1',
  'BPIFB4',
  'BPTF',
  'BRAF',
  'BRAP',
  'BRCA1',
  'BRCA2',
  'BRD1',
  'BRD2',
  'BRD3',
  'BRD3OS',
  'BRD4',
  'BRD7',
  'BRD8',
  'BRD9',
  'BRF1',
  'BRF2',
  'BRI3BP',
  'BRICD5',
  'BRINP2',
  'BRINP3',
  'BRIX1',
  'BRK1',
  'BRMS1',
  'BRMS1L',
  'BROX',
  'BRPF1',
  'BRPF3',
  'BRSK2',
  'BRWD1',
  'BSCL2',
  'BSDC1',
  'BSG',
  'BSN',
  'BSND',
  'BST2',
  'BTAF1',
  'BTBD1',
  'BTBD2',
  'BTBD3',
  'BTBD7',
  'BTBD9',
  'BTBD10',
  'BTBD16',
  'BTBD18',
  'BTBD19',
  'BTC',
  'BTD',
  'BTF3L4',
  'BTG1',
  'BTG2',
  'BTG3',
  'BTK',
  'BTLA',
  'BTN2A2',
  'BTN2A3P',
  'BTN3A1',
  'BTN3A2',
  'BTN3A3',
  'BTNL2',
  'BTNL8',
  'BTNL9',
  'BTRC',
  'BUB1B',
  'BUB3',
  'BUD13',
  'BUD23',
  'BUD31',
  'BVES',
  'BYSL',
  'BZW1',
  'C1D',
  'C1GALT1',
  'C1GALT1C1L',
  'C1orf21',
  'C1orf43',
  'C1orf50',
  'C1orf52',
  'C1orf53',
  'C1orf54',
  'C1orf56',
  'C1orf61',
  'C1orf68',
  'C1orf74',
  'C1orf94',
  'C1orf100',
  'C1orf105',
  'C1orf112',
  'C1orf115',
  'C1orf116',
  'C1orf122',
  'C1orf127',
  'C1orf131',
  'C1orf158',
  'C1orf159',
  'C1orf162',
  'C1orf167',
  'C1orf174',
  'C1orf198',
  'C1orf210',
  'C1orf216',
  'C1orf226',
  'C1QA',
  'C1QB',
  'C1QBP',
  'C1QC',
  'C1QL1',
  'C1QTNF1',
  'C1QTNF3',
  'C1QTNF4',
  'C1QTNF5',
  'C1QTNF6',
  'C1QTNF7',
  'C1QTNF9',
  'C1QTNF9B',
  'C1QTNF12',
  'C1R',
  'C1RL-AS1',
  'C1RL',
  'C1S',
  'C2',
  'C2CD2',
  'C2CD2L',
  'C2CD3',
  'C2CD4B',
  'C2CD5',
  'C2orf15',
  'C2orf16',
  'C2orf42',
  'C2orf49',
  'C2orf68',
  'C2orf69',
  'C2orf74',
  'C2orf76',
  'C2orf80',
  'C2orf88',
  'C2orf92',
  'C3',
  'C3AR1',
  'C3orf18',
  'C3orf20',
  'C3orf33',
  'C3orf35',
  'C3orf38',
  'C3orf49',
  'C3orf52',
  'C3orf56',
  'C3orf62',
  'C3orf70',
  'C4A',
  'C4B',
  'C4BPB',
  'C4orf17',
  'C4orf19',
  'C4orf33',
  'C4orf36',
  'C4orf45',
  'C5',
  'C5AR1',
  'C5AR2',
  'C5orf15',
  'C5orf22',
  'C5orf24',
  'C5orf30',
  'C5orf46',
  'C5orf51',
  'C5orf63',
  'C6',
  'C6orf47',
  'C6orf52',
  'C6orf58',
  'C6orf62',
  'C6orf89',
  'C6orf120',
  'C6orf136',
  'C6orf141',
  'C6orf163',
  'C6orf201',
  'C6orf223',
  'C7',
  'C7orf25',
  'C7orf50',
  'C7orf77',
  'C8A',
  'C8G',
  'C8orf31',
  'C8orf37',
  'C8orf44',
  'C8orf48',
  'C8orf58',
  'C8orf74',
  'C8orf82',
  'C8orf88',
  'C9',
  'C9orf16',
  'C9orf24',
  'C9orf40',
  'C9orf43',
  'C9orf47',
  'C9orf50',
  'C9orf64',
  'C9orf72',
  'C9orf78',
  'C9orf85',
  'C10orf55',
  'C10orf62',
  'C10orf67',
  'C10orf71',
  'C10orf88',
  'C10orf95',
  'C10orf113',
  'C11orf1',
  'C11orf16',
  'C11orf24',
  'C11orf45',
  'C11orf49',
  'C11orf54',
  'C11orf58',
  'C11orf68',
  'C11orf71',
  'C11orf74',
  'C11orf80',
  'C11orf87',
  'C11orf88',
  'C11orf95',
  'C11orf96',
  'C12orf4',
  'C12orf10',
  'C12orf40',
  'C12orf42',
  'C12orf43',
  'C12orf45',
  'C12orf49',
  'C12orf50',
  'C12orf54',
  'C12orf56',
  'C12orf57',
  'C12orf60',
  'C12orf65',
  'C12orf75',
  'C12orf76',
  'C14orf28',
  'C14orf39',
  'C14orf93',
  'C14orf119',
  'C14orf132',
  'C14orf178',
  'C14orf180',
  'C15orf39',
  'C15orf40',
  'C15orf41',
  'C15orf54',
  'C15orf61',
  'C15orf62',
  'C16orf46',
  'C16orf58',
  'C16orf71',
  'C16orf72',
  'C16orf82',
  'C16orf86',
  'C16orf87',
  'C16orf91',
  'C16orf92',
  'C16orf95',
  'C17orf58',
  'C17orf75',
  'C17orf80',
  'C17orf99',
  'C18orf21',
  'C18orf25',
  'C18orf32',
  'C18orf54',
  'C19orf12',
  'C19orf18',
  'C19orf25',
  'C19orf33',
  'C19orf44',
  'C19orf47',
  'C19orf53',
  'C19orf54',
  'C19orf57',
  'C20orf85',
  'C20orf96',
  'C20orf141',
  'C20orf144',
  'C20orf173',
  'C20orf194',
  'C20orf202',
  'C21orf58',
  'C21orf91',
  'C22orf34',
  'C22orf39',
  'C22orf42',
  'C22orf46',
  'CA2',
  'CA3',
  'CA4',
  'CA5A',
  'CA6',
  'CA8',
  'CA9',
  'CA11',
  'CA13',
  'CA14',
  'CAAP1',
  'CAB39',
  'CAB39L',
  'CABLES1',
  'CABLES2',
  'CABP1',
  'CABP4',
  'CABP5',
  'CABP7',
  'CABS1',
  'CABYR',
  'CACHD1',
  'CACNA1A',
  'CACNA1C',
  'CACNA1D',
  'CACNA1E',
  'CACNA1G',
  'CACNA1S',
  'CACNA2D1',
  'CACNA2D2',
  'CACNA2D3',
  'CACNA2D4',
  'CACNB1',
  'CACNB2',
  'CACNB3',
  'CACNB4',
  'CACNG1',
  'CACNG6',
  'CACNG8',
  'CACTIN',
  'CACUL1',
  'CACYBP',
  'CADM1',
  'CADM2',
  'CADM3',
  'CADM4',
  'CADPS',
  'CADPS2',
  'CALB1',
  'CALB2',
  'CALCB',
  'CALCOCO1',
  'CALCOCO2',
  'CALCRL',
  'CALD1',
  'CALHM2',
  'CALHM3',
  'CALHM4',
  'CALHM5',
  'CALM1',
  'CALM2',
  'CALM3',
  'CALML3',
  'CALML4',
  'CALML6',
  'CALN1',
  'CALR3',
  'CALU',
  'CALY',
  'CAMK1',
  'CAMK2A',
  'CAMK2B',
  'CAMK2D',
  'CAMK2G',
  'CAMK2N1',
  'CAMK2N2',
  'CAMK4',
  'CAMKK1',
  'CAMKK2',
  'CAMKMT',
  'CAMLG',
  'CAMSAP1',
  'CAMSAP2',
  'CAMSAP3',
  'CAMTA1',
  'CAMTA2',
  'CAND1',
  'CAND2',
  'CANT1',
  'CANX',
  'CAP1',
  'CAP2',
  'CAPG',
  'CAPN1',
  'CAPN2',
  'CAPN3',
  'CAPN5',
  'CAPN6',
  'CAPN7',
  'CAPN10',
  'CAPN12',
  'CAPN15',
  'CAPNS1',
  'CAPRIN2',
  'CAPS',
  'CAPS2',
  'CAPSL',
  'CAPZA1',
  'CAPZA2',
  'CAPZB',
  'CARD6',
  'CARD8',
  'CARD9',
  'CARD10',
  'CARD11',
  'CARD16',
  'CARD19',
  'CARF',
  'CARHSP1',
  'CARM1',
  'CARMIL1',
  'CARMIL2',
  'CARNS1',
  'CARS1',
  'CARS2',
  'CASC1',
  'CASC3',
  'CASC4',
  'CASC15',
  'CASD1',
  'CASK',
  'CASKIN1',
  'CASKIN2',
  'CASP1',
  'CASP2',
  'CASP3',
  'CASP4',
  'CASP6',
  'CASP7',
  'CASP8',
  'CASP8AP2',
  'CASP9',
  'CASP10',
  'CASP12',
  'CASP14',
  'CASQ1',
  'CASQ2',
  'CASR',
  'CASS4',
  'CAST',
  'CASTOR1',
  'CASTOR2',
  'CASTOR3',
  'CASZ1',
  'CATSPER2P1',
  'CATSPER3',
  'CATSPERE',
  'CAV1',
  'CAV2',
  'CAV3',
  'CAVIN1',
  'CAVIN2',
  'CAVIN3',
  'CAVIN4',
  'CBARP',
  'CBFA2T2',
  'CBFA2T3',
  'CBL',
  'CBLB',
  'CBLL1',
  'CBLN1',
  'CBLN3',
  'CBLN4',
  'CBR1',
  'CBR3',
  'CBR4',
  'CBWD1',
  'CBWD2',
  'CBWD6',
  'CBX1',
  'CBX2',
  'CBX3',
  'CBX4',
  'CBX5',
  'CBX6',
  'CBX7',
  'CBX8',
  'CBY1',
  'CC2D1A',
  'CC2D2A',
  'CCAR1',
  'CCAR2',
  'CCDC3',
  'CCDC6',
  'CCDC7',
  'CCDC8',
  'CCDC9',
  'CCDC9B',
  'CCDC12',
  'CCDC13',
  'CCDC14',
  'CCDC15',
  'CCDC18',
  'CCDC22',
  'CCDC25',
  'CCDC27',
  'CCDC28A',
  'CCDC28B',
  'CCDC30',
  'CCDC32',
  'CCDC36',
  'CCDC38',
  'CCDC39',
  'CCDC43',
  'CCDC47',
  'CCDC50',
  'CCDC51',
  'CCDC54',
  'CCDC57',
  'CCDC58',
  'CCDC59',
  'CCDC61',
  'CCDC62',
  'CCDC66',
  'CCDC69',
  'CCDC71',
  'CCDC77',
  'CCDC80',
  'CCDC81',
  'CCDC84',
  'CCDC85A',
  'CCDC85B',
  'CCDC85C',
  'CCDC86',
  'CCDC87',
  'CCDC88B',
  'CCDC88C',
  'CCDC89',
  'CCDC90B',
  'CCDC91',
  'CCDC92',
  'CCDC93',
  'CCDC97',
  'CCDC102A',
  'CCDC102B',
  'CCDC103',
  'CCDC106',
  'CCDC107',
  'CCDC110',
  'CCDC112',
  'CCDC113',
  'CCDC115',
  'CCDC116',
  'CCDC117',
  'CCDC120',
  'CCDC122',
  'CCDC124',
  'CCDC126',
  'CCDC127',
  'CCDC130',
  'CCDC134',
  'CCDC136',
  'CCDC138',
  'CCDC141',
  'CCDC144A',
  'CCDC144B',
  'CCDC146',
  'CCDC148',
  'CCDC149',
  'CCDC151',
  'CCDC153',
  'CCDC157',
  'CCDC158',
  'CCDC159',
  'CCDC160',
  'CCDC167',
  'CCDC168',
  'CCDC169',
  'CCDC171',
  'CCDC172',
  'CCDC174',
  'CCDC185',
  'CCDC186',
  'CCDC189',
  'CCDC197',
  'CCDC198',
  'CCHCR1',
  'CCK',
  'CCKAR',
  'CCKBR',
  'CCL2',
  'CCL4',
  'CCL5',
  'CCL7',
  'CCL8',
  'CCL13',
  'CCL15',
  'CCL16',
  'CCL19',
  'CCL21',
  'CCL22',
  'CCM2',
  'CCM2L',
  'CCN1',
  'CCN2',
  'CCN3',
  'CCN4',
  'CCN5',
  'CCN6',
  'CCNB1',
  'CCNB1IP1',
  'CCNB2',
  'CCNB3',
  'CCND1',
  'CCND2',
  'CCND3',
  'CCNDBP1',
  'CCNE2',
  'CCNG1',
  'CCNG2',
  'CCNH',
  'CCNJ',
  'CCNJL',
  'CCNK',
  'CCNL1',
  'CCNL2',
  'CCNO',
  'CCNT1',
  'CCNY',
  'CCNYL1',
  'CCP110',
  'CCPG1',
  'CCR1',
  'CCR2',
  'CCR3',
  'CCR5',
  'CCRL2',
  'CCS',
  'CCSER2',
  'CCT2',
  'CCT4',
  'CCT5',
  'CCT6B',
  'CCT7',
  'CCT8',
  'CCZ1',
  'CCZ1B',
  'CD1A',
  'CD1C',
  'CD1D',
  'CD1E',
  'CD2AP',
  'CD2BP2',
  'CD3D',
  'CD3E',
  'CD3EAP',
  'CD3G',
  'CD4',
  'CD5L',
  'CD6',
  'CD8A',
  'CD8B',
  'CD14',
  'CD19',
  'CD22',
  'CD24',
  'CD28',
  'CD33',
  'CD34',
  'CD36',
  'CD37',
  'CD38',
  'CD40',
  'CD44',
  'CD47',
  'CD52',
  'CD53',
  'CD55',
  'CD58',
  'CD59',
  'CD63',
  'CD68',
  'CD69',
  'CD70',
  'CD72',
  'CD74',
  'CD79A',
  'CD79B',
  'CD80',
  'CD81',
  'CD82',
  'CD83',
  'CD84',
  'CD86',
  'CD93',
  'CD96',
  'CD99',
  'CD99L2',
  'CD109',
  'CD151',
  'CD160',
  'CD163',
  'CD163L1',
  'CD164',
  'CD177',
  'CD180',
  'CD200',
  'CD209',
  'CD247',
  'CD248',
  'CD274',
  'CD276',
  'CD300A',
  'CD300C',
  'CD300LF',
  'CD300LG',
  'CD302',
  'CD320',
  'CDADC1',
  'CDAN1',
  'CDC5L',
  'CDC6',
  'CDC7',
  'CDC14A',
  'CDC16',
  'CDC20',
  'CDC20B',
  'CDC23',
  'CDC25A',
  'CDC25B',
  'CDC34',
  'CDC37',
  'CDC37L1',
  'CDC42BPA',
  'CDC42BPB',
  'CDC42BPG',
  'CDC42EP1',
  'CDC42EP2',
  'CDC42EP3',
  'CDC42EP4',
  'CDC42EP5',
  'CDC42SE1',
  'CDC45',
  'CDC73',
  'CDCA2',
  'CDCA3',
  'CDCA5',
  'CDCA7',
  'CDCA7L',
  'CDH1',
  'CDH2',
  'CDH4',
  'CDH5',
  'CDH6',
  'CDH8',
  'CDH11',
  'CDH13',
  'CDH15',
  'CDH16',
  'CDH22',
  'CDH23',
  'CDH24',
  'CDHR1',
  'CDHR3',
  'CDHR5',
  'CDIPT',
  'CDK1',
  'CDK2',
  'CDK2AP1',
  'CDK2AP2',
  'CDK3',
  'CDK4',
  'CDK5',
  'CDK5RAP1',
  'CDK5RAP2',
  'CDK5RAP3',
  'CDK6',
  'CDK8',
  'CDK9',
  'CDK10',
  'CDK11A',
  'CDK11B',
  'CDK12',
  'CDK13',
  'CDK14',
  'CDK15',
  'CDK16',
  'CDK17',
  'CDK18',
  'CDK19',
  'CDK20',
  'CDKAL1',
  'CDKL1',
  'CDKL2',
  'CDKL3',
  'CDKL5',
  'CDKN1A',
  'CDKN1B',
  'CDKN1C',
  'CDKN2A',
  'CDKN2AIPNL',
  'CDKN2B',
  'CDKN2C',
  'CDNF',
  'CDO1',
  'CDON',
  'CDPF1',
  'CDR2',
  'CDR2L',
  'CDRT4',
  'CDRT15L2',
  'CDS2',
  'CDSN',
  'CDT1',
  'CDV3',
  'CDYL',
  'CDYL2',
  'CEACAM1',
  'CEACAM3',
  'CEACAM6',
  'CEACAM19',
  'CEACAM21',
  'CEBPA',
  'CEBPB',
  'CEBPD',
  'CEBPE',
  'CEBPG',
  'CEBPZ',
  'CECR2',
  'CEL',
  'CELA2B',
  'CELA3A',
  'CELF1',
  'CELF2-AS1',
  'CELF2',
  'CELF6',
  'CELP',
  'CELSR2',
  'CEMIP2',
  'CEMP1',
  'CEND1',
  'CENPA',
  'CENPBD1',
  'CENPBD1P1',
  'CENPC',
  'CENPF',
  'CENPH',
  'CENPJ',
  'CENPK',
  'CENPL',
  'CENPM',
  'CENPN',
  'CENPO',
  'CENPT',
  'CENPU',
  'CENPV',
  'CENPW',
  'CENPX',
  'CEP19',
  'CEP44',
  'CEP55',
  'CEP57',
  'CEP63',
  'CEP68',
  'CEP70',
  'CEP72',
  'CEP76',
  'CEP78',
  'CEP85',
  'CEP85L',
  'CEP89',
  'CEP95',
  'CEP97',
  'CEP104',
  'CEP126',
  'CEP131',
  'CEP152',
  'CEP162',
  'CEP170',
  'CEP170B',
  'CEP170P1',
  'CEP250',
  'CEP290',
  'CEP295',
  'CEP350',
  'CEPT1',
  'CERCAM',
  'CERK',
  'CERS1',
  'CERS2',
  'CERS3',
  'CERS4',
  'CERS5',
  'CERS6',
  'CERT1',
  'CES1',
  'CES1P1',
  'CES2',
  'CES3',
  'CETN2',
  'CETN3',
  'CETP',
  'CFAP20',
  'CFAP36',
  'CFAP43',
  'CFAP44',
  'CFAP45',
  'CFAP46',
  'CFAP47',
  'CFAP53',
  'CFAP57',
  'CFAP58',
  'CFAP61',
  'CFAP69',
  'CFAP70',
  'CFAP73',
  'CFAP97',
  'CFAP100',
  'CFAP126',
  'CFAP206',
  'CFAP298',
  'CFAP300',
  'CFAP410',
  'CFC1B',
  'CFD',
  'CFDP1',
  'CFH',
  'CFHR1',
  'CFI',
  'CFL1',
  'CFL2',
  'CFLAR',
  'CFP',
  'CGA',
  'CGAS',
  'CGB1',
  'CGB3',
  'CGB5',
  'CGB7',
  'CGB8',
  'CGGBP1',
  'CGNL1',
  'CGRRF1',
  'CH25H',
  'CHAC1',
  'CHAC2',
  'CHAD',
  'CHADL',
  'CHAF1A',
  'CHAF1B',
  'CHAMP1',
  'CHAT',
  'CHCHD1',
  'CHCHD2',
  'CHCHD3',
  'CHCHD4',
  'CHCHD6',
  'CHCHD7',
  'CHCHD10',
  'CHD3',
  'CHD4',
  'CHD6',
  'CHD7',
  'CHD8',
  'CHD9',
  'CHDH',
  'CHEK2',
  'CHERP',
  'CHFR',
  'CHGB',
  'CHI3L2',
  'CHIA',
  'CHIC1',
  'CHIC2',
  'CHID1',
  'CHIT1',
  'CHKA',
  'CHKB',
  'CHM',
  'CHML',
  'CHMP1A',
  'CHMP1B',
  'CHMP2A',
  'CHMP2B',
  'CHMP3',
  'CHMP4A',
  'CHMP4B',
  'CHMP4C',
  'CHMP6',
  'CHMP7',
  'CHN1',
  'CHN2',
  'CHODL',
  'CHORDC1',
  'CHP1',
  'CHP2',
  'CHPF',
  'CHPF2',
  'CHPT1',
  'CHRAC1',
  'CHRDL1',
  'CHRDL2',
  'CHRFAM7A',
  'CHRM2',
  'CHRM3',
  'CHRM4',
  'CHRNA1',
  'CHRNA4',
  'CHRNA5',
  'CHRNA7',
  'CHRNA9',
  'CHRNB1',
  'CHRNB4',
  'CHRND',
  'CHRNG',
  'CHST1',
  'CHST2',
  'CHST3',
  'CHST5',
  'CHST6',
  'CHST7',
  'CHST8',
  'CHST10',
  'CHST11',
  'CHST12',
  'CHST13',
  'CHST14',
  'CHST15',
  'CHSY1',
  'CHSY3',
  'CHTF8',
  'CHTOP',
  'CHUK',
  'CHURC1',
  'CIAO1',
  'CIAO2A',
  'CIAO2B',
  'CIAO3',
  'CIAPIN1',
  'CIART',
  'CIB1',
  'CIB2',
  'CIB3',
  'CIC',
  'CIDEB',
  'CIDEC',
  'CIDECP1',
  'CIITA',
  'CILP',
  'CILP2',
  'CINP',
  'CIP2A',
  'CIPC',
  'CIR1',
  'CIRBP',
  'CISD1',
  'CIT',
  'CITED2',
  'CITED4',
  'CIZ1',
  'CKAP4',
  'CKAP5',
  'CKB',
  'CKM',
  'CKMT2',
  'CKS1B',
  'CKS2',
  'CLASP1',
  'CLASP2',
  'CLASRP',
  'CLC',
  'CLCA1',
  'CLCC1',
  'CLCF1',
  'CLCN1',
  'CLCN3',
  'CLCN4',
  'CLCN5',
  'CLCN6',
  'CLCN7',
  'CLCNKA',
  'CLDN2',
  'CLDN4',
  'CLDN5',
  'CLDN7',
  'CLDN8',
  'CLDN10',
  'CLDN12',
  'CLDN16',
  'CLDN20',
  'CLDN22',
  'CLDND2',
  'CLEC1A',
  'CLEC1B',
  'CLEC2B',
  'CLEC2D',
  'CLEC3B',
  'CLEC4A',
  'CLEC4E',
  'CLEC4F',
  'CLEC7A',
  'CLEC10A',
  'CLEC11A',
  'CLEC12A',
  'CLEC14A',
  'CLEC16A',
  'CLEC18A',
  'CLEC18C',
  'CLHC1',
  'CLIC1',
  'CLIC2',
  'CLIC4',
  'CLIC5',
  'CLIC6',
  'CLINT1',
  'CLIP1',
  'CLIP2',
  'CLIP3',
  'CLIP4',
  'CLK1',
  'CLK2',
  'CLK4',
  'CLMN',
  'CLMP',
  'CLN3',
  'CLN5',
  'CLN6',
  'CLN8',
  'CLNK',
  'CLNS1A',
  'CLPB',
  'CLPTM1',
  'CLPTM1L',
  'CLPX',
  'CLRN1',
  'CLSTN1',
  'CLSTN2',
  'CLSTN3',
  'CLTA',
  'CLTB',
  'CLTC',
  'CLTCL1',
  'CLTRN',
  'CLU',
  'CLUAP1',
  'CLUH',
  'CLUHP3',
  'CLVS1',
  'CLVS2',
  'CLYBL',
  'CMA1',
  'CMAS',
  'CMBL',
  'CMC1',
  'CMC2',
  'CMIP',
  'CMKLR1',
  'CMPK1',
  'CMPK2',
  'CMSS1',
  'CMTM3',
  'CMTM4',
  'CMTM5',
  'CMTM6',
  'CMTM7',
  'CMTM8',
  'CMTR1',
  'CMYA5',
  'CNBD1',
  'CNBD2',
  'CNBP',
  'CNDP2',
  'CNEP1R1',
  'CNFN',
  'CNGA1',
  'CNGB1',
  'CNIH2',
  'CNIH3',
  'CNIH4',
  'CNKSR1',
  'CNKSR2',
  'CNKSR3',
  'CNN1',
  'CNN2',
  'CNN3',
  'CNNM2',
  'CNNM3',
  'CNNM4',
  'CNOT1',
  'CNOT2',
  'CNOT3',
  'CNOT4',
  'CNOT6',
  'CNOT7',
  'CNOT8',
  'CNOT9',
  'CNOT10',
  'CNP',
  'CNPPD1',
  'CNPY2',
  'CNPY3',
  'CNPY4',
  'CNR1',
  'CNR2',
  'CNRIP1',
  'CNST',
  'CNTD1',
  'CNTF',
  'CNTFR',
  'CNTLN',
  'CNTN1',
  'CNTN4',
  'CNTN5',
  'CNTNAP1',
  'CNTNAP2',
  'CNTNAP3',
  'CNTNAP3B',
  'CNTNAP5',
  'CNTRL',
  'CNTROB',
  'COA1',
  'COA3',
  'COA5',
  'COA6',
  'COA7',
  'COA8',
  'COASY',
  'COBL',
  'COBLL1',
  'COG1',
  'COG2',
  'COG3',
  'COG4',
  'COG5',
  'COG6',
  'COG7',
  'COG8',
  'COIL',
  'COL1A1',
  'COL1A2',
  'COL3A1',
  'COL4A1',
  'COL4A2',
  'COL4A3',
  'COL4A4',
  'COL4A5',
  'COL5A1',
  'COL5A2',
  'COL5A3',
  'COL6A1',
  'COL6A2',
  'COL6A3',
  'COL6A6',
  'COL7A1',
  'COL9A3',
  'COL10A1',
  'COL11A1',
  'COL11A2',
  'COL12A1',
  'COL14A1',
  'COL15A1',
  'COL16A1',
  'COL18A1-AS1',
  'COL18A1',
  'COL19A1',
  'COL21A1',
  'COL24A1',
  'COL26A1',
  'COL28A1',
  'COLCA2',
  'COLEC12',
  'COLGALT1',
  'COLGALT2',
  'COLQ',
  'COMMD1',
  'COMMD3',
  'COMMD4',
  'COMMD5',
  'COMMD6',
  'COMMD7',
  'COMMD8',
  'COMMD9',
  'COMMD10',
  'COMT',
  'COMTD1',
  'COP1',
  'COPA',
  'COPB1',
  'COPB2',
  'COPE',
  'COPG1',
  'COPG2',
  'COPRS',
  'COPS2',
  'COPS3',
  'COPS5',
  'COPS6',
  'COPS7A',
  'COPS7B',
  'COPS8',
  'COPS9',
  'COPZ1',
  'COQ2',
  'COQ3',
  'COQ4',
  'COQ5',
  'COQ6',
  'COQ7',
  'COQ8A',
  'COQ8B',
  'COQ9',
  'COQ10A',
  'COQ10B',
  'CORIN',
  'CORO1A',
  'CORO1B',
  'CORO1C',
  'CORO2B',
  'CORO6',
  'CORO7',
  'COTL1',
  'COX4I1',
  'COX4I2',
  'COX5A',
  'COX5B',
  'COX6A1',
  'COX6A2',
  'COX6B1',
  'COX6C',
  'COX7A1',
  'COX7A2',
  'COX7A2L',
  'COX7B',
  'COX7C',
  'COX8A',
  'COX10',
  'COX11',
  'COX14',
  'COX15',
  'COX16',
  'COX17',
  'COX18',
  'COX20',
  'CP',
  'CPA1',
  'CPA2',
  'CPA3',
  'CPA4',
  'CPA5',
  'CPAMD8',
  'CPB2',
  'CPD',
  'CPE',
  'CPEB1',
  'CPEB2',
  'CPEB3',
  'CPEB4',
  'CPED1',
  'CPLANE2',
  'CPLX1',
  'CPLX3',
  'CPM',
  'CPNE1',
  'CPNE2',
  'CPNE3',
  'CPNE5',
  'CPNE7',
  'CPNE8',
  'CPO',
  'CPOX',
  'CPPED1',
  'CPQ',
  'CPS1-IT1',
  'CPSF1',
  'CPSF2',
  'CPSF3',
  'CPSF4',
  'CPSF6',
  'CPSF7',
  'CPT1A',
  'CPT1B',
  'CPT1C',
  'CPT2',
  'CPTP',
  'CPVL',
  'CPXM1',
  'CPXM2',
  'CPZ',
  'CR1',
  'CRABP2',
  'CRACD',
  'CRACR2A',
  'CRACR2B',
  'CRADD',
  'CRAT',
  'CRB1',
  'CRB3',
  'CRBN',
  'CRCP',
  'CREB3L1',
  'CREB3L2',
  'CREB3L3',
  'CREB3L4',
  'CREB5',
  'CREBBP',
  'CREBL2',
  'CREBRF',
  'CREBZF',
  'CREG2',
  'CRELD1',
  'CRELD2',
  'CREM',
  'CRH',
  'CRHBP',
  'CRHR1',
  'CRHR2',
  'CRIM1',
  'CRIP1',
  'CRIP2',
  'CRIPT',
  'CRISPLD1',
  'CRISPLD2',
  'CRK',
  'CRKL',
  'CRLF1',
  'CRLS1',
  'CRMP1',
  'CRNKL1',
  'CROCC',
  'CROCCP2',
  'CROT',
  'CRTAM',
  'CRTAP',
  'CRTC1',
  'CRTC2',
  'CRTC3',
  'CRX',
  'CRY1',
  'CRY2',
  'CRYAB',
  'CRYBG1',
  'CRYBG3',
  'CRYL1',
  'CRYM',
  'CRYZL1',
  'CS',
  'CSDC2',
  'CSDE1',
  'CSE1L',
  'CSF1',
  'CSF1R',
  'CSF2',
  'CSF2RB',
  'CSF3',
  'CSF3R',
  'CSGALNACT1',
  'CSGALNACT2',
  'CSH1',
  'CSK',
  'CSKMT',
  'CSMD2',
  'CSNK1A1',
  'CSNK1D',
  'CSNK1E',
  'CSNK1G2',
  'CSNK1G3',
  'CSNK2A1',
  'CSNK2A2',
  'CSNK2B',
  'CSPG4',
  'CSPP1',
  'CSRNP1',
  'CSRNP2',
  'CSRNP3',
  'CSRP1',
  'CSRP2',
  'CSRP3',
  'CST2',
  'CST3',
  'CST4',
  'CST7',
  'CST9L',
  'CST11',
  'CST13P',
  'CSTA',
  'CSTB',
  'CSTF2',
  'CSTF2T',
  'CSTF3',
  'CSTL1',
  'CT62',
  'CTAGE4',
  'CTAGE9',
  'CTBP1',
  'CTBP2',
  'CTC1',
  'CTCF',
  'CTDNEP1',
  'CTDSP1',
  'CTDSP2',
  'CTDSPL',
  'CTF1',
  'CTH',
  'CTHRC1',
  'CTLA4',
  'CTNNA1',
  'CTNNA2',
  'CTNNA3',
  'CTNNAL1',
  'CTNNB1',
  'CTNNBIP1',
  'CTNNBL1',
  'CTNND1',
  'CTNND2',
  'CTNS',
  'CTPS1',
  'CTPS2',
  'CTR9',
  'CTRC',
  'CTRL',
  'CTSA',
  'CTSB',
  'CTSC',
  'CTSD',
  'CTSG',
  'CTSH',
  'CTSK',
  'CTSL',
  'CTSO',
  'CTSS',
  'CTSV',
  'CTSW',
  'CTSZ',
  'CTTN',
  'CTTNBP2',
  'CTTNBP2NL',
  'CTU2',
  'CTXN2',
  'CTXN3',
  'CUEDC1',
  'CUEDC2',
  'CUL1',
  'CUL2',
  'CUL3',
  'CUL4A',
  'CUL4B',
  'CUL5',
  'CUL7',
  'CUL9',
  'CUTA',
  'CUTALP',
  'CUTC',
  'CUX1',
  'CWC15',
  'CWC22',
  'CWC27',
  'CWF19L1',
  'CWF19L2',
  'CX3CL1',
  'CX3CR1',
  'CXADR',
  'CXCL2',
  'CXCL5',
  'CXCL6',
  'CXCL9',
  'CXCL10',
  'CXCL11',
  'CXCL12',
  'CXCL14',
  'CXCL16',
  'CXCR3',
  'CXCR4',
  'CXCR5',
  'CXCR6',
  'CXorf21',
  'CXorf38',
  'CXorf40A',
  'CXorf40B',
  'CXorf51A',
  'CXorf56',
  'CXorf58',
  'CXXC1P1',
  'CXXC4',
  'CXXC5',
  'CYB5A',
  'CYB5B',
  'CYB5D1',
  'CYB5D2',
  'CYB5R1',
  'CYB5R3',
  'CYB5R4',
  'CYB561',
  'CYB561A3',
  'CYBA',
  'CYBC1',
  'CYBRD1',
  'CYC1',
  'CYCS',
  'CYFIP1',
  'CYFIP2',
  'CYGB',
  'CYLC1',
  'CYLD',
  'CYMP',
  'CYP1B1-AS1',
  'CYP1B1',
  'CYP2B6',
  'CYP2B7P',
  'CYP2C9',
  'CYP2E1',
  'CYP2J2',
  'CYP2R1',
  'CYP2S1',
  'CYP2U1',
  'CYP3A4',
  'CYP3A7',
  'CYP3A43',
  'CYP4B1',
  'CYP4F2',
  'CYP4F8',
  'CYP4F11',
  'CYP4F12',
  'CYP4F22',
  'CYP4V2',
  'CYP4X1',
  'CYP4Z1',
  'CYP7B1',
  'CYP11B1',
  'CYP11B2',
  'CYP17A1',
  'CYP20A1',
  'CYP27A1',
  'CYP46A1',
  'CYP51A1',
  'CYREN',
  'CYSLTR1',
  'CYSLTR2',
  'CYSTM1',
  'CYTH1',
  'CYTH2',
  'CYTH3',
  'CYTH4',
  'CYTL1',
  'CYTOR',
  'CYYR1',
  'CZIB',
  'D2HGDH',
  'DAAM1',
  'DAAM2',
  'DAB1',
  'DAB2',
  'DAB2IP',
  'DACH1',
  'DACT1',
  'DACT2',
  'DACT3',
  'DAD1',
  'DAG1',
  'DAGLB',
  'DALRD3',
  'DANCR',
  'DAND5',
  'DAO',
  'DAOA-AS1',
  'DAP',
  'DAP3',
  'DAPK1',
  'DAPK2',
  'DAPK3',
  'DAPL1',
  'DAPP1',
  'DARS1',
  'DARS2',
  'DAZAP1',
  'DAZAP2',
  'DBF4B',
  'DBH',
  'DBI',
  'DBIL5P2',
  'DBN1',
  'DBNDD1',
  'DBNDD2',
  'DBP',
  'DBR1',
  'DBT',
  'DCAF1',
  'DCAF4',
  'DCAF4L1',
  'DCAF4L2',
  'DCAF5',
  'DCAF6',
  'DCAF7',
  'DCAF8',
  'DCAF8L2',
  'DCAF10',
  'DCAF11',
  'DCAF12',
  'DCAF12L1',
  'DCAF13',
  'DCAF15',
  'DCAF16',
  'DCAF17',
  'DCAKD',
  'DCANP1',
  'DCBLD1',
  'DCC',
  'DCD',
  'DCDC1',
  'DCDC2',
  'DCHS1',
  'DCK',
  'DCLK1',
  'DCLK2',
  'DCLK3',
  'DCLRE1B',
  'DCLRE1C',
  'DCN',
  'DCP1A',
  'DCP1B',
  'DCP2',
  'DCPS',
  'DCST1',
  'DCST2',
  'DCT',
  'DCTD',
  'DCTN1-AS1',
  'DCTN1',
  'DCTN3',
  'DCTN4',
  'DCTN5',
  'DCTPP1',
  'DCUN1D1',
  'DCUN1D2',
  'DCUN1D3',
  'DCUN1D4',
  'DCUN1D5',
  'DCX',
  'DCXR',
  'DDAH1',
  'DDAH2',
  'DDB2',
  'DDC-AS1',
  'DDHD2',
  'DDI2',
  'DDIAS',
  'DDIT3',
  'DDIT4',
  'DDIT4L',
  'DDN',
  'DDOST',
  'DDR1',
  'DDR2',
  'DDRGK1',
  'DDT',
  'DDTL',
  'DDX3X',
  'DDX5',
  'DDX6',
  'DDX10',
  'DDX11',
  'DDX17',
  'DDX19A',
  'DDX19B',
  'DDX20',
  'DDX21',
  'DDX23',
  'DDX24',
  'DDX25',
  'DDX27',
  'DDX28',
  'DDX31',
  'DDX39A',
  'DDX41',
  'DDX42',
  'DDX46',
  'DDX47',
  'DDX49',
  'DDX51',
  'DDX52',
  'DDX53',
  'DDX54',
  'DDX55',
  'DDX56',
  'DDX58',
  'DDX59',
  'DDX60',
  'DDX60L',
  'DEAF1',
  'DECR1',
  'DECR2',
  'DEDD',
  'DEDD2',
  'DEF8',
  'DEFA6',
  'DEFB1',
  'DEFB4A',
  'DEFB104B',
  'DEFB105B',
  'DEFB106A',
  'DEFB119',
  'DEFB124',
  'DEFB126',
  'DEFB132',
  'DEGS1',
  'DEGS2',
  'DEK',
  'DELE1',
  'DENND1A',
  'DENND1B',
  'DENND1C',
  'DENND2A',
  'DENND2B',
  'DENND2C',
  'DENND3',
  'DENND4A',
  'DENND4B',
  'DENND4C',
  'DENND5A',
  'DENND6A',
  'DENND6B',
  'DENND10',
  'DENND11',
  'DENR',
  'DEPDC1',
  'DEPDC4',
  'DEPDC5',
  'DEPDC7',
  'DEPP1',
  'DEPTOR',
  'DERA',
  'DERL1',
  'DERL2',
  'DERL3',
  'DES',
  'DESI1',
  'DESI2',
  'DEUP1',
  'DEXI',
  'DFFA',
  'DGAT1',
  'DGAT2',
  'DGCR2',
  'DGCR6',
  'DGCR6L',
  'DGCR8',
  'DGCR11',
  'DGKA',
  'DGKD',
  'DGKE',
  'DGKG',
  'DGKH',
  'DGKQ',
  'DGKZ',
  'DGLUCY',
  'DGUOK',
  'DHCR7',
  'DHCR24',
  'DHDDS',
  'DHDH',
  'DHFR',
  'DHODH',
  'DHPS',
  'DHRS1',
  'DHRS3',
  'DHRS4-AS1',
  'DHRS4',
  'DHRS4L2',
  'DHRS7',
  'DHRS7B',
  'DHRS7C',
  'DHRS11',
  'DHRS12',
  'DHTKD1',
  'DHX9',
  'DHX15',
  'DHX16',
  'DHX29',
  'DHX32',
  'DHX33',
  'DHX35',
  'DHX36',
  'DHX37',
  'DHX38',
  'DIABLO',
  'DIAPH1',
  'DIAPH2',
  'DIAPH3',
  'DICER1-AS1',
  'DICER1',
  'DIDO1',
  'DIO2',
  'DIO3',
  'DIP2A',
  'DIP2B',
  'DIP2C',
  'DIPK1A',
  'DIPK1B',
  'DIPK2A',
  'DIPK2B',
  'DIRAS1',
  'DIRAS3',
  'DIS3L',
  'DIS3L2',
  'DISC1',
  'DISP1',
  'DISP3',
  'DIXDC1',
  'DKC1',
  'DKK1',
  'DKK2',
  'DKK3',
  'DLAT',
  'DLC1',
  'DLD',
  'DLEC1',
  'DLEU1',
  'DLEU2',
  'DLG3',
  'DLG5-AS1',
  'DLG5',
  'DLGAP1-AS1',
  'DLGAP1',
  'DLGAP3',
  'DLGAP4',
  'DLGAP5',
  'DLK1',
  'DLK2',
  'DLL1',
  'DLL4',
  'DLST',
  'DLX1',
  'DLX2',
  'DLX4',
  'DMAC1',
  'DMAC2',
  'DMAC2L',
  'DMAP1',
  'DMBT1',
  'DMC1',
  'DMD',
  'DMGDH',
  'DMKN',
  'DMPK',
  'DMRT2',
  'DMRT3',
  'DMRTA1',
  'DMRTB1',
  'DMRTC2',
  'DMTF1',
  'DMTN',
  'DMWD',
  'DMXL1',
  'DMXL2',
  'DNA2',
  'DNAAF1',
  'DNAAF3',
  'DNAAF5',
  'DNAH1',
  'DNAH2',
  'DNAH3',
  'DNAH11',
  'DNAH14',
  'DNAJA1',
  'DNAJA2',
  'DNAJA3',
  'DNAJA4',
  'DNAJB1',
  'DNAJB2',
  'DNAJB4',
  'DNAJB5',
  'DNAJB6',
  'DNAJB8',
  'DNAJB9',
  'DNAJB11',
  'DNAJB12',
  'DNAJC1',
  'DNAJC3',
  'DNAJC4',
  'DNAJC5',
  'DNAJC7',
  'DNAJC8',
  'DNAJC9',
  'DNAJC10',
  'DNAJC11',
  'DNAJC12',
  'DNAJC13',
  'DNAJC15',
  'DNAJC16',
  'DNAJC17',
  'DNAJC18',
  'DNAJC19',
  'DNAJC21',
  'DNAJC24',
  'DNAJC25-GNG10',
  'DNAJC25',
  'DNAJC27',
  'DNAJC30',
  'DNAL4',
  'DNALI1',
  'DNASE1',
  'DNASE1L1',
  'DNASE1L3',
  'DNASE2',
  'DNASE2B',
  'DND1',
  'DNHD1',
  'DNLZ',
  'DNM1L',
  'DNM1P46',
  'DNM2',
  'DNM3',
  'DNMBP',
  'DNMT1',
  'DNMT3A',
  'DNMT3B',
  'DNPEP',
  'DNPH1',
  'DNTT',
  'DNTTIP1',
  'DNTTIP2',
  'DOC2A',
  'DOC2B',
  'DOCK1',
  'DOCK2',
  'DOCK4',
  'DOCK5',
  'DOCK6',
  'DOCK8',
  'DOCK9',
  'DOCK10',
  'DOHH',
  'DOK1',
  'DOK3',
  'DOK4',
  'DOK5',
  'DOK6',
  'DOK7',
  'DOLK',
  'DOLPP1',
  'DONSON',
  'DOP1A',
  'DOP1B',
  'DOT1L',
  'DPAGT1',
  'DPCD',
  'DPEP1',
  'DPEP2',
  'DPF1',
  'DPF2',
  'DPF3',
  'DPH1',
  'DPH2',
  'DPH3',
  'DPH5',
  'DPH6',
  'DPH7',
  'DPM1',
  'DPM3',
  'DPP3',
  'DPP4',
  'DPP7',
  'DPP8',
  'DPP9',
  'DPPA4',
  'DPT',
  'DPY19L1',
  'DPY19L2',
  'DPY19L2P2',
  'DPY19L2P4',
  'DPY19L3',
  'DPY19L4',
  'DPY30',
  'DPYD',
  'DPYS',
  'DPYSL2',
  'DPYSL3',
  'DPYSL4',
  'DQX1',
  'DR1',
  'DRAM1',
  'DRAM2',
  'DRAP1',
  'DRD2',
  'DRD4',
  'DRG1',
  'DRG2',
  'DROSHA',
  'DSC2',
  'DSCAML1',
  'DSCR10',
  'DSE',
  'DSEL',
  'DSG2',
  'DSG3',
  'DSN1',
  'DSPP',
  'DST',
  'DSTNP2',
  'DSTYK',
  'DTD1',
  'DTD2',
  'DTHD1',
  'DTL',
  'DTNA',
  'DTNBP1',
  'DTWD1',
  'DTWD2',
  'DTX1',
  'DTX2',
  'DTX2P1-UPK3BP1-PMS2P11',
  'DTX3',
  'DTX3L',
  'DTX4',
  'DTYMK',
  'DUOXA1',
  'DUOXA2',
  'DUS1L',
  'DUS2',
  'DUS3L',
  'DUSP1',
  'DUSP2',
  'DUSP3',
  'DUSP4',
  'DUSP5',
  'DUSP6',
  'DUSP8',
  'DUSP9',
  'DUSP10',
  'DUSP11',
  'DUSP12',
  'DUSP14',
  'DUSP16',
  'DUSP18',
  'DUSP19',
  'DUSP21',
  'DUSP22',
  'DUSP23',
  'DUSP26',
  'DUSP27',
  'DUSP28',
  'DUT',
  'DVL1',
  'DVL2',
  'DVL3',
  'DXO',
  'DYDC1',
  'DYDC2',
  'DYM',
  'DYNC1H1',
  'DYNC1I1',
  'DYNC1I2',
  'DYNC1LI1',
  'DYNC1LI2',
  'DYNC2LI1',
  'DYNLL1',
  'DYNLL2',
  'DYNLRB1',
  'DYNLT1',
  'DYNLT3',
  'DYRK1A',
  'DYRK1B',
  'DYRK2',
  'DYRK3',
  'DYRK4',
  'DYSF',
  'DZANK1',
  'DZIP1',
  'DZIP1L',
  'DZIP3',
  'E2F1',
  'E2F3',
  'E2F4',
  'E2F5',
  'E2F6',
  'E2F7',
  'E2F8',
  'E4F1',
  'EAF1',
  'EAPP',
  'EARS2',
  'EBAG9',
  'EBF1',
  'EBF2',
  'EBF3',
  'EBF4',
  'EBI3',
  'EBNA1BP2',
  'EBP',
  'EBPL',
  'ECD',
  'ECE1',
  'ECE2',
  'ECEL1',
  'ECH1',
  'ECHDC1',
  'ECHDC2',
  'ECHDC3',
  'ECHS1',
  'ECI1',
  'ECI2',
  'ECM1',
  'ECM2',
  'ECPAS',
  'ECRG4',
  'ECSCR',
  'ECSIT',
  'ECT2',
  'EDA',
  'EDA2R',
  'EDAR',
  'EDARADD',
  'EDC3',
  'EDC4',
  'EDDM3B',
  'EDEM3',
  'EDF1',
  'EDNRA',
  'EDNRB',
  'EDRF1',
  'EEA1',
  'EED',
  'EEF1A1',
  'EEF1A2',
  'EEF1AKMT1',
  'EEF1AKMT2',
  'EEF1AKMT3',
  'EEF1AKNMT',
  'EEF1B2',
  'EEF1D',
  'EEF1E1',
  'EEF1G',
  'EEF2',
  'EEF2K',
  'EEF2KMT',
  'EEFSEC',
  'EEPD1',
  'EFCAB2',
  'EFCAB14',
  'EFCC1',
  'EFEMP1',
  'EFEMP2',
  'EFHC1',
  'EFHC2',
  'EFHD1',
  'EFHD2',
  'EFNA1',
  'EFNA5',
  'EFNB1',
  'EFNB2',
  'EFNB3',
  'EFR3A',
  'EFR3B',
  'EFTUD2',
  'EGF',
  'EGFL7',
  'EGFLAM',
  'EGFR',
  'EGLN1',
  'EGLN3',
  'EGR1',
  'EGR2',
  'EGR3',
  'EHBP1',
  'EHBP1L1',
  'EHD1',
  'EHD2',
  'EHD3',
  'EHD4',
  'EHF',
  'EHHADH',
  'EHMT1',
  'EHMT2',
  'EI24',
  'EID1',
  'EID2',
  'EID2B',
  'EID3',
  'EIF1',
  'EIF1AD',
  'EIF1AX',
  'EIF1B',
  'EIF2A',
  'EIF2AK1',
  'EIF2AK2',
  'EIF2AK3',
  'EIF2AK4',
  'EIF2B1',
  'EIF2B2',
  'EIF2B3',
  'EIF2B4',
  'EIF2B5',
  'EIF2D',
  'EIF2S1',
  'EIF2S2',
  'EIF2S3',
  'EIF3A',
  'EIF3B',
  'EIF3D',
  'EIF3E',
  'EIF3F',
  'EIF3G',
  'EIF3H',
  'EIF3I',
  'EIF3J-DT',
  'EIF3J',
  'EIF3K',
  'EIF3L',
  'EIF3M',
  'EIF4A1',
  'EIF4A2',
  'EIF4A3',
  'EIF4B',
  'EIF4E',
  'EIF4E2',
  'EIF4E3',
  'EIF4EBP1',
  'EIF4EBP2',
  'EIF4EBP3',
  'EIF4ENIF1',
  'EIF4G1',
  'EIF4G2',
  'EIF4G3',
  'EIF4H',
  'EIF5',
  'EIF5A',
  'EIF5B',
  'EIF6',
  'EIPR1',
  'ELAC1',
  'ELAVL1',
  'ELF1',
  'ELF2',
  'ELF4',
  'ELFN1',
  'ELFN2',
  'ELK1',
  'ELK3',
  'ELK4',
  'ELL',
  'ELL2',
  'ELL3',
  'ELMO1',
  'ELMO2',
  'ELMOD1',
  'ELMOD2',
  'ELMOD3',
  'ELMSAN1',
  'ELN',
  'ELOA',
  'ELOB',
  'ELOF1',
  'ELOVL1',
  'ELOVL2',
  'ELOVL5',
  'ELP1',
  'ELP2',
  'ELP3',
  'ELP4',
  'ELP5',
  'ELSPBP1',
  'EMB',
  'EMC1',
  'EMC2',
  'EMC4',
  'EMC6',
  'EMC7',
  'EMC8',
  'EMC9',
  'EMC10',
  'EMCN',
  'EMD',
  'EME2',
  'EMG1',
  'EMID1',
  'EMILIN1',
  'EMILIN2',
  'EMILIN3',
  'EML1',
  'EML2',
  'EML3',
  'EML4',
  'EML5',
  'EMP1',
  'EMP2',
  'EMP3',
  'EMSY',
  'EMX2OS',
  'EN1',
  'ENAH',
  'ENAM',
  'ENC1',
  'ENDOD1',
  'ENDOG',
  'ENDOV',
  'ENG',
  'ENGASE',
  'ENHO',
  'ENKUR',
  'ENO1',
  'ENO2',
  'ENO3',
  'ENOPH1',
  'ENOSF1',
  'ENOX1',
  'ENOX2',
  'ENPEP',
  'ENPP1',
  'ENPP2',
  'ENPP3',
  'ENPP4',
  'ENPP5',
  'ENSA',
  'ENTPD1',
  'ENTPD2',
  'ENTPD3',
  'ENTPD4',
  'ENTPD6',
  'ENTPD7',
  'ENTR1',
  'ENY2',
  'EOMES',
  'EP300',
  'EP400',
  'EP400P1',
  'EPAS1',
  'EPB41',
  'EPB41L1',
  'EPB41L2',
  'EPB41L3',
  'EPB41L4A-AS1',
  'EPB41L4A-DT',
  'EPB41L4A',
  'EPB41L4B',
  'EPB41L5',
  'EPB42',
  'EPC1',
  'EPC2',
  'EPCAM',
  'EPDR1',
  'EPG5',
  'EPHA1',
  'EPHA2',
  'EPHA3',
  'EPHA4',
  'EPHA8',
  'EPHA10',
  'EPHB1',
  'EPHB2',
  'EPHB3',
  'EPHB4',
  'EPHB6',
  'EPHX1',
  'EPHX2',
  'EPHX3',
  'EPHX4',
  'EPM2A',
  'EPM2AIP1',
  'EPN1',
  'EPN2',
  'EPN3',
  'EPO',
  'EPOP',
  'EPOR',
  'EPRS1',
  'EPS8',
  'EPS8L1',
  'EPS8L2',
  'EPS8L3',
  'EPS15',
  'EPS15L1',
  'EPSTI1',
  'ERAL1',
  'ERAP1',
  'ERAP2',
  'ERBB2',
  'ERBB3',
  'ERBB4',
  'ERBIN',
  'ERC1',
  'ERCC1',
  'ERCC2',
  'ERCC3',
  'ERCC4',
  'ERCC6',
  'ERCC6L',
  'ERCC6L2',
  'ERCC8',
  'EREG',
  'ERF',
  'ERFE',
  'ERG',
  'ERG28',
  'ERGIC1',
  'ERGIC2',
  'ERGIC3',
  'ERH',
  'ERI2',
  'ERI3',
  'ERICH1',
  'ERICH3',
  'ERICH5',
  'ERICH6',
  'ERLEC1',
  'ERLIN1',
  'ERLIN2',
  'ERMARD',
  'ERMN',
  'ERMP1',
  'ERN1',
  'ERN2',
  'ERO1A',
  'ERP27',
  'ERP29',
  'ERP44',
  'ERRFI1',
  'ERVFRD-1',
  'ERVW-1',
  'ESAM',
  'ESCO1',
  'ESF1',
  'ESM1',
  'ESPL1',
  'ESPN',
  'ESPNL',
  'ESPNP',
  'ESR1',
  'ESR2',
  'ESRP1',
  'ESRP2',
  'ESRRA',
  'ESRRB',
  'ESRRG',
  'ESS2',
  'ESYT1',
  'ESYT2',
  'ESYT3',
  'ETAA1',
  'ETF1',
  'ETFA',
  'ETFB',
  'ETFBKMT',
  'ETFDH',
  'ETNK1',
  'ETNPPL',
  'ETS1',
  'ETS2',
  'ETV1',
  'ETV4',
  'ETV5',
  'ETV6',
  'EVA1A',
  'EVA1B',
  'EVA1C',
  'EVC',
  'EVI2A',
  'EVI2B',
  'EVI5',
  'EVI5L',
  'EVL',
  'EVX1',
  'EWSR1',
  'EXD1',
  'EXD2',
  'EXD3',
  'EXO1',
  'EXO5',
  'EXOC1',
  'EXOC2',
  'EXOC3-AS1',
  'EXOC3',
  'EXOC3L1',
  'EXOC3L2',
  'EXOC4',
  'EXOC5',
  'EXOC6',
  'EXOC7',
  'EXOC8',
  'EXOG',
  'EXOSC1',
  'EXOSC2',
  'EXOSC3',
  'EXOSC5',
  'EXOSC6',
  'EXOSC7',
  'EXOSC8',
  'EXOSC9',
  'EXOSC10',
  'EXPH5',
  'EXT1',
  'EXT2',
  'EXTL1',
  'EXTL2',
  'EYA1',
  'EYA2',
  'EYA4',
  'EZH1',
  'EZH2',
  'EZR',
  'F2R',
  'F2RL1',
  'F2RL3',
  'F3',
  'F5',
  'F7',
  'F8',
  'F8A1',
  'F8A2',
  'F8A3',
  'F10',
  'F11',
  'F11R',
  'F12',
  'F13A1',
  'FA2H',
  'FAAH',
  'FAAH2',
  'FAAP20',
  'FAAP24',
  'FAAP100',
  'FABP1',
  'FABP3',
  'FABP4',
  'FABP5',
  'FABP7',
  'FADD',
  'FADS2',
  'FADS3',
  'FAF1',
  'FAF2',
  'FAH',
  'FAHD1',
  'FAHD2A',
  'FAHD2B',
  'FAIM',
  'FAIM2',
  'FAM3A',
  'FAM3C',
  'FAM3D',
  'FAM8A1',
  'FAM9A',
  'FAM9B',
  'FAM9C',
  'FAM13A-AS1',
  'FAM13A',
  'FAM13B',
  'FAM13C',
  'FAM20A',
  'FAM20B',
  'FAM24B',
  'FAM27C',
  'FAM32A',
  'FAM43A',
  'FAM47A',
  'FAM47E',
  'FAM49A',
  'FAM49B',
  'FAM50A',
  'FAM50B',
  'FAM53B',
  'FAM53C',
  'FAM71A',
  'FAM71D',
  'FAM71E1',
  'FAM72A',
  'FAM72B',
  'FAM72D',
  'FAM76A',
  'FAM76B',
  'FAM78A',
  'FAM78B',
  'FAM81A',
  'FAM83B',
  'FAM83D',
  'FAM83F',
  'FAM83H',
  'FAM86B1',
  'FAM86B2',
  'FAM86C1',
  'FAM86DP',
  'FAM87A',
  'FAM89B',
  'FAM92A',
  'FAM95B1',
  'FAM98A',
  'FAM98C',
  'FAM102A',
  'FAM102B',
  'FAM104A',
  'FAM106A',
  'FAM107A',
  'FAM107B',
  'FAM110A',
  'FAM110B',
  'FAM110C',
  'FAM110D',
  'FAM111A',
  'FAM111B',
  'FAM114A1',
  'FAM114A2',
  'FAM117A',
  'FAM117B',
  'FAM118A',
  'FAM118B',
  'FAM120A',
  'FAM120C',
  'FAM122B',
  'FAM122C',
  'FAM124A',
  'FAM124B',
  'FAM126A',
  'FAM126B',
  'FAM131A',
  'FAM131B',
  'FAM131C',
  'FAM133A',
  'FAM133B',
  'FAM135A',
  'FAM135B',
  'FAM136A',
  'FAM149B1',
  'FAM151A',
  'FAM155B',
  'FAM156A',
  'FAM156B',
  'FAM160A1',
  'FAM160A2',
  'FAM160B1',
  'FAM160B2',
  'FAM161A',
  'FAM161B',
  'FAM162A',
  'FAM162B',
  'FAM163A',
  'FAM166A',
  'FAM166C',
  'FAM167A',
  'FAM167B',
  'FAM168A',
  'FAM168B',
  'FAM170A',
  'FAM170B',
  'FAM171A1',
  'FAM171A2',
  'FAM171B',
  'FAM172A',
  'FAM174A',
  'FAM174B',
  'FAM181A',
  'FAM181B',
  'FAM182A',
  'FAM182B',
  'FAM184A',
  'FAM184B',
  'FAM185A',
  'FAM186B',
  'FAM187B',
  'FAM189A2',
  'FAM193A',
  'FAM193B',
  'FAM199X',
  'FAM200A',
  'FAM200B',
  'FAM204A',
  'FAM207A',
  'FAM209A',
  'FAM210A',
  'FAM210B',
  'FAM214A',
  'FAM214B',
  'FAM215A',
  'FAM216A',
  'FAM216B',
  'FAM217B',
  'FAM218A',
  'FAM219A',
  'FAM219B',
  'FAM220A',
  'FAM221A',
  'FAM222A-AS1',
  'FAM222B',
  'FAM227B',
  'FAM229B',
  'FAM241A',
  'FAM241B',
  'FAN1',
  'FANCB',
  'FANCD2OS',
  'FANCE',
  'FANCF',
  'FANCG',
  'FANCI',
  'FANCM',
  'FAP',
  'FAR2',
  'FAR2P1',
  'FARP1',
  'FARP2',
  'FARS2',
  'FARSA',
  'FAS',
  'FASLG',
  'FASN',
  'FASTK',
  'FASTKD1',
  'FASTKD2',
  'FASTKD3',
  'FASTKD5',
  'FAT1',
  'FAT2',
  'FAT4',
  'FATE1',
  'FAU',
  'FAXC',
  'FAXDC2',
  'FBF1',
  'FBH1',
  'FBL',
  'FBLIM1',
  'FBLN1',
  'FBLN2',
  'FBLN5',
  'FBLN7',
  'FBN1',
  'FBN2',
  'FBN3',
  'FBP1',
  'FBP2',
  'FBRS',
  'FBRSL1',
  'FBXL2',
  'FBXL3',
  'FBXL4',
  'FBXL6',
  'FBXL7',
  'FBXL8',
  'FBXL12',
  'FBXL15',
  'FBXL16',
  'FBXL17',
  'FBXL18',
  'FBXL19-AS1',
  'FBXL19',
  'FBXL20',
  'FBXL22',
  'FBXO2',
  'FBXO3',
  'FBXO4',
  'FBXO5',
  'FBXO6',
  'FBXO8',
  'FBXO9',
  'FBXO10',
  'FBXO11',
  'FBXO15',
  'FBXO16',
  'FBXO21',
  'FBXO25',
  'FBXO28',
  'FBXO30',
  'FBXO31',
  'FBXO32',
  'FBXO33',
  'FBXO34',
  'FBXO39',
  'FBXO40',
  'FBXO42',
  'FBXO45',
  'FBXO46',
  'FBXW2',
  'FBXW4',
  'FBXW4P1',
  'FBXW5',
  'FBXW7',
  'FBXW8',
  'FBXW9',
  'FBXW10',
  'FBXW12',
  'FCAR',
  'FCER1A',
  'FCER1G',
  'FCF1',
  'FCGR1A',
  'FCGR1B',
  'FCGR1CP',
  'FCGR2A',
  'FCGR2B',
  'FCGR2C',
  'FCGR3A',
  'FCGR3B',
  'FCGRT',
  'FCHO2',
  'FCHSD2',
  'FCMR',
  'FCN1',
  'FCN3',
  'FCRL3',
  'FCRL4',
  'FCRLA',
  'FCRLB',
  'FCSK',
  'FDFT1',
  'FDPS',
  'FDPSP2',
  'FDX1',
  'FDX2',
  'FDXACB1',
  'FECH',
  'FEM1A',
  'FEM1B',
  'FEM1C',
  'FEN1',
  'FER',
  'FER1L5',
  'FERMT2',
  'FERMT3',
  'FES',
  'FETUB',
  'FEZ1',
  'FEZ2',
  'FEZF2',
  'FFAR4',
  'FGA',
  'FGD2',
  'FGD4',
  'FGD5',
  'FGD6',
  'FGF1',
  'FGF5',
  'FGF6',
  'FGF7P6',
  'FGF9',
  'FGF10',
  'FGF11',
  'FGF12',
  'FGF13',
  'FGF17',
  'FGF18',
  'FGF19',
  'FGF21',
  'FGF22',
  'FGF23',
  'FGFBP1',
  'FGFBP2',
  'FGFBP3',
  'FGFR1',
  'FGFR1OP',
  'FGFR1OP2',
  'FGFR3',
  'FGFR4',
  'FGFRL1',
  'FGL2',
  'FH',
  'FHDC1',
  'FHIT',
  'FHL1',
  'FHL2',
  'FHL3',
  'FHOD1',
  'FIBCD1',
  'FIBIN',
  'FIBP',
  'FICD',
  'FIG4',
  'FIGN',
  'FIGNL1',
  'FILIP1',
  'FILIP1L',
  'FIS1',
  'FITM1',
  'FITM2',
  'FIZ1',
  'FKBP1A',
  'FKBP1B',
  'FKBP2',
  'FKBP3',
  'FKBP4',
  'FKBP5',
  'FKBP6',
  'FKBP7',
  'FKBP8',
  'FKBP9',
  'FKBP10',
  'FKBP11',
  'FKBP14',
  'FKBP15',
  'FKRP',
  'FKTN',
  'FLAD1',
  'FLCN',
  'FLI1',
  'FLJ20021',
  'FLJ25758',
  'FLJ34503',
  'FLJ37201',
  'FLJ37453',
  'FLJ38576',
  'FLNA',
  'FLNB',
  'FLNC',
  'FLOT1',
  'FLRT2',
  'FLRT3',
  'FLT1',
  'FLT3',
  'FLT3LG',
  'FLT4',
  'FLVCR1',
  'FLVCR2',
  'FLYWCH1',
  'FLYWCH2',
  'FMC1',
  'FMNL2',
  'FMNL3',
  'FMO1',
  'FMO2',
  'FMO3',
  'FMO6P',
  'FMOD',
  'FMR1',
  'FN1',
  'FN3K',
  'FN3KRP',
  'FNBP1',
  'FNBP1L',
  'FNBP4',
  'FNDC1',
  'FNDC3A',
  'FNDC3B',
  'FNDC4',
  'FNDC5',
  'FNDC8',
  'FNDC9',
  'FNDC10',
  'FNIP1',
  'FNIP2',
  'FNTA',
  'FNTB',
  'FOCAD',
  'FOLH1',
  'FOLH1B',
  'FOLR1',
  'FOLR2',
  'FOPNL',
  'FOS',
  'FOSB',
  'FOSL1',
  'FOXA2',
  'FOXA3',
  'FOXC1',
  'FOXC2',
  'FOXD2',
  'FOXD4',
  'FOXD4L1',
  'FOXF2',
  'FOXJ1',
  'FOXJ2',
  'FOXJ3',
  'FOXK1',
  'FOXK2',
  'FOXM1',
  'FOXN2',
  'FOXN3-AS1',
  'FOXN3-AS2',
  'FOXN3',
  'FOXN4',
  'FOXO1',
  'FOXO3',
  'FOXO4',
  'FOXP1',
  'FOXP2',
  'FOXP3',
  'FOXP4',
  'FOXR2',
  'FOXRED1',
  'FOXRED2',
  'FOXS1',
  'FPGS',
  'FPR1',
  'FPR2',
  'FPR3',
  'FRA10AC1',
  'FRAS1',
  'FRAT1',
  'FRAT2',
  'FREM2',
  'FRK',
  'FRMD1',
  'FRMD3',
  'FRMD4A',
  'FRMD4B',
  'FRMD5',
  'FRMD6',
  'FRMD7',
  'FRMD8',
  'FRMPD1',
  'FRMPD2',
  'FRMPD3',
  'FRMPD4',
  'FRRS1',
  'FRRS1L',
  'FRS2',
  'FRS3',
  'FRY',
  'FRYL',
  'FRZB',
  'FSCN1',
  'FSCN2',
  'FSD1',
  'FSD1L',
  'FSD2',
  'FSIP1',
  'FSIP2',
  'FST',
  'FSTL1',
  'FSTL5',
  'FTH1',
  'FTL',
  'FTO',
  'FTSJ3',
  'FUBP1',
  'FUCA1',
  'FUCA2',
  'FUNDC1',
  'FUNDC2',
  'FUOM',
  'FURIN',
  'FUS',
  'FUT1',
  'FUT3',
  'FUT4',
  'FUT6',
  'FUT8',
  'FUT10',
  'FUT11',
  'FUZ',
  'FXN',
  'FXR1',
  'FXR2',
  'FXYD1',
  'FXYD3',
  'FXYD5',
  'FXYD6',
  'FXYD7',
  'FYB2',
  'FYCO1',
  'FYN',
  'FYTTD1',
  'FZD1',
  'FZD2',
  'FZD4',
  'FZD5',
  'FZD6',
  'FZD7',
  'FZD8',
  'FZD9',
  'FZR1',
  'G0S2',
  'G3BP1',
  'G3BP2',
  'G6PC',
  'G6PC3',
  'G6PD',
  'GAA',
  'GAB1',
  'GAB2',
  'GAB3',
  'GABARAP',
  'GABARAPL1',
  'GABARAPL2',
  'GABBR1',
  'GABBR2',
  'GABPA',
  'GABPB1',
  'GABPB2',
  'GABRA3',
  'GABRA4',
  'GABRB1',
  'GABRB2',
  'GABRD',
  'GABRE',
  'GABRG1',
  'GABRP',
  'GABRQ',
  'GABRR2',
  'GABRR3',
  'GAD1',
  'GAD2',
  'GADD45A',
  'GADD45B',
  'GADD45G',
  'GADD45GIP1',
  'GADL1',
  'GAGE2E',
  'GAGE4',
  'GAGE8',
  'GAGE12H',
  'GAGE12J',
  'GAK',
  'GAL3ST1',
  'GAL3ST2',
  'GAL3ST3',
  'GAL3ST4',
  'GALC',
  'GALE',
  'GALK1',
  'GALK2',
  'GALNS',
  'GALNT1',
  'GALNT2',
  'GALNT3',
  'GALNT4',
  'GALNT5',
  'GALNT6',
  'GALNT7',
  'GALNT9',
  'GALNT10',
  'GALNT11',
  'GALNT12',
  'GALNT15',
  'GALNT16',
  'GALNT17',
  'GALNT18',
  'GALR2',
  'GALR3',
  'GALT',
  'GAMT',
  'GAN',
  'GANAB',
  'GANC',
  'GAPT',
  'GAPVD1',
  'GAR1',
  'GAREM1',
  'GAREM2',
  'GARS1',
  'GAS1',
  'GAS2',
  'GAS2L1',
  'GAS2L2',
  'GAS2L3',
  'GAS5',
  'GAS6',
  'GAS7',
  'GAS8',
  'GASK1A',
  'GASK1B',
  'GAST',
  'GATA1',
  'GATA2',
  'GATA3-AS1',
  'GATA3',
  'GATA5',
  'GATA6',
  'GATAD1',
  'GATAD2A',
  'GATAD2B',
  'GATB',
  'GATC',
  'GATD1',
  'GATD3A',
  'GATM',
  'GBA2',
  'GBA3',
  'GBE1',
  'GBF1',
  'GBGT1',
  'GBP1',
  'GBP3',
  'GBP4',
  'GBP5',
  'GBX1',
  'GCA',
  'GCAT',
  'GCC2',
  'GCDH',
  'GCFC2',
  'GCH1',
  'GCHFR',
  'GCKR',
  'GCLM',
  'GCM1',
  'GCM2',
  'GCN1',
  'GCNA',
  'GCNT2',
  'GCNT3',
  'GCNT7',
  'GCOM1',
  'GCSAML',
  'GCSH',
  'GDA',
  'GDAP1',
  'GDAP1L1',
  'GDAP2',
  'GDE1',
  'GDF1',
  'GDF2',
  'GDF9',
  'GDF10',
  'GDF11',
  'GDI1',
  'GDI2',
  'GDNF',
  'GDPD1',
  'GDPD2',
  'GDPD3',
  'GDPD5',
  'GDPGP1',
  'GEM',
  'GEMIN2',
  'GEMIN4',
  'GEMIN5',
  'GEMIN7',
  'GEN1',
  'GET1',
  'GET3',
  'GET4',
  'GFER',
  'GFI1',
  'GFI1B',
  'GFM1',
  'GFM2',
  'GFOD1',
  'GFOD2',
  'GFPT1',
  'GFPT2',
  'GFRA1',
  'GFRA2',
  'GFRA3',
  'GFRA4',
  'GGA1',
  'GGA2',
  'GGA3',
  'GGACT',
  'GGCT',
  'GGCX',
  'GGH',
  'GGN',
  'GGNBP2',
  'GGPS1',
  'GGT1',
  'GGT3P',
  'GGT5',
  'GGT6',
  'GGT7',
  'GGTA1P',
  'GGTLC1',
  'GGTLC2',
  'GHITM',
  'GHR',
  'GHRHR',
  'GHRLOS',
  'GID4',
  'GID8',
  'GIGYF1',
  'GIGYF2',
  'GIMAP1',
  'GIMAP2',
  'GIMAP4',
  'GIMAP5',
  'GIMAP6',
  'GIMAP7',
  'GIMAP8',
  'GIN1',
  'GINM1',
  'GINS2',
  'GINS3',
  'GIP',
  'GIPC1',
  'GIPC2',
  'GIPC3',
  'GIPR',
  'GIT1',
  'GIT2',
  'GJA1',
  'GJA4',
  'GJA5',
  'GJA10',
  'GJB4',
  'GJB5',
  'GJC1',
  'GJD2',
  'GJD3',
  'GJD4',
  'GK',
  'GK3P',
  'GKAP1',
  'GLA',
  'GLB1',
  'GLB1L2',
  'GLCCI1',
  'GLCE',
  'GLDC',
  'GLE1',
  'GLG1',
  'GLI2',
  'GLI3',
  'GLIDR',
  'GLIPR1',
  'GLIPR1L1',
  'GLIPR2',
  'GLIS1',
  'GLIS2',
  'GLIS3-AS1',
  'GLIS3',
  'GLMN',
  'GLMP',
  'GLO1',
  'GLOD4',
  'GLP1R',
  'GLRB',
  'GLRX',
  'GLRX2',
  'GLRX3',
  'GLRX5',
  'GLS',
  'GLT8D1',
  'GLT8D2',
  'GLTP',
  'GLTPD2',
  'GLUD1',
  'GLUD2',
  'GLUL',
  'GLYATL2',
  'GLYCTK',
  'GLYR1',
  'GM2A',
  'GMCL1',
  'GMDS',
  'GMEB1',
  'GMEB2',
  'GMFB',
  'GMFG',
  'GMIP',
  'GMNN',
  'GMPPA',
  'GMPR',
  'GMPR2',
  'GNA11',
  'GNA12',
  'GNA13',
  'GNA14',
  'GNA15',
  'GNAI1',
  'GNAI2',
  'GNAL',
  'GNAO1',
  'GNAQ',
  'GNAS-AS1',
  'GNAS',
  'GNAT1',
  'GNAZ',
  'GNB1',
  'GNB1L',
  'GNB2',
  'GNB3',
  'GNB4',
  'GNE',
  'GNG2',
  'GNG3',
  'GNG4',
  'GNG5',
  'GNG7',
  'GNG10',
  'GNG11',
  'GNG12',
  'GNGT2',
  'GNL2',
  'GNL3',
  'GNL3L',
  'GNLY',
  'GNMT',
  'GNPAT',
  'GNPDA1',
  'GNPDA2',
  'GNPNAT1',
  'GNPTAB',
  'GNPTG',
  'GNRH1',
  'GNRHR2',
  'GNS',
  'GOLGA1',
  'GOLGA2',
  'GOLGA2P5',
  'GOLGA2P7',
  'GOLGA3',
  'GOLGA4',
  'GOLGA6A',
  'GOLGA6L2',
  'GOLGA6L9',
  'GOLGA7',
  'GOLGA7B',
  'GOLGA8A',
  'GOLGA8B',
  'GOLGA8IP',
  'GOLGA8N',
  'GOLGB1',
  'GOLIM4',
  'GOLM1',
  'GOLPH3L',
  'GOLT1B',
  'GON4L',
  'GON7',
  'GOPC',
  'GORAB',
  'GORASP1',
  'GORASP2',
  'GOSR1',
  'GOSR2',
  'GOT1',
  'GOT2',
  'GP1BA',
  'GP5',
  'GP9',
  'GPA33',
  'GPAA1',
  'GPALPP1',
  'GPAM',
  'GPANK1',
  'GPAT2',
  'GPAT3',
  'GPAT4',
  'GPATCH1',
  'GPATCH2',
  'GPATCH2L',
  'GPATCH3',
  'GPATCH4',
  'GPATCH8',
  'GPATCH11',
  'GPBP1',
  'GPBP1L1',
  'GPC1',
  'GPC2',
  'GPC3',
  'GPC4',
  'GPCPD1',
  'GPD1',
  'GPD1L',
  'GPD2',
  'GPER1',
  'GPHA2',
  'GPHN',
  'GPI',
  'GPIHBP1',
  'GPKOW',
  'GPLD1',
  'GPM6B',
  'GPN1',
  'GPN2',
  'GPN3',
  'GPNMB',
  'GPR4',
  'GPR17',
  'GPR20',
  'GPR21',
  'GPR27',
  'GPR31',
  'GPR34',
  'GPR35',
  'GPR37',
  'GPR37L1',
  'GPR39',
  'GPR45',
  'GPR55',
  'GPR61',
  'GPR62',
  'GPR65',
  'GPR68',
  'GPR78',
  'GPR84',
  'GPR85',
  'GPR88',
  'GPR89A',
  'GPR89B',
  'GPR107',
  'GPR108',
  'GPR132',
  'GPR135',
  'GPR137',
  'GPR137B',
  'GPR137C',
  'GPR146',
  'GPR153',
  'GPR155',
  'GPR156',
  'GPR157',
  'GPR158',
  'GPR160',
  'GPR161',
  'GPR162',
  'GPR173',
  'GPR176',
  'GPR180',
  'GPR182',
  'GPR183',
  'GPRASP1',
  'GPRASP2',
  'GPRC5B',
  'GPRC5C',
  'GPRC5D',
  'GPRIN1',
  'GPRIN2',
  'GPRIN3',
  'GPS1',
  'GPS2',
  'GPSM1',
  'GPSM2',
  'GPSM3',
  'GPT',
  'GPT2',
  'GPX1',
  'GPX3',
  'GPX4',
  'GPX5',
  'GPX7',
  'GPX8',
  'GRAMD1B',
  'GRAMD2B',
  'GRAMD4',
  'GRAP',
  'GRAPL',
  'GRASP',
  'GRB2',
  'GRB7',
  'GRB10',
  'GRB14',
  'GREB1',
  'GREB1L',
  'GREM1',
  'GREM2',
  'GRHL1',
  'GRHL3',
  'GRHPR',
  'GRIA1',
  'GRIA2',
  'GRIA3',
  'GRIK2',
  'GRIK3',
  'GRIN1',
  'GRIN2A',
  'GRIN2C',
  'GRIN2D',
  'GRIN3B',
  'GRINA',
  'GRIP1',
  'GRIP2',
  'GRIPAP1',
  'GRK1',
  'GRK2',
  'GRK3',
  'GRK4',
  'GRK5',
  'GRM2',
  'GRM4',
  'GRM5',
  'GRM6',
  'GRN',
  'GRPEL1',
  'GRPEL2',
  'GRPR',
  'GRSF1',
  'GRTP1',
  'GRWD1',
  'GSC2',
  'GSDMB',
  'GSDMC',
  'GSDMD',
  'GSDME',
  'GSE1',
  'GSK3A',
  'GSK3B',
  'GSKIP',
  'GSN',
  'GSPT1',
  'GSPT2',
  'GSR',
  'GSS',
  'GSTA1',
  'GSTA4',
  'GSTCD',
  'GSTK1',
  'GSTM2',
  'GSTM3',
  'GSTM4',
  'GSTM5',
  'GSTO1',
  'GSTO2',
  'GSTP1',
  'GSTT1',
  'GSTT2',
  'GSTZ1',
  'GSX2',
  'GTDC1',
  'GTF2A1',
  'GTF2A1L',
  'GTF2A2',
  'GTF2B',
  'GTF2E2',
  'GTF2F1',
  'GTF2H1',
  'GTF2H3',
  'GTF2H4',
  'GTF2H5',
  'GTF2IRD1',
  'GTF2IRD2',
  'GTF2IRD2B',
  'GTF3A',
  'GTF3C1',
  'GTF3C2',
  'GTF3C3',
  'GTF3C5',
  'GTF3C6',
  'GTPBP1',
  'GTPBP2',
  'GTPBP3',
  'GTPBP4',
  'GTPBP6',
  'GTPBP8',
  'GTPBP10',
  'GTSE1',
  'GTSF1',
  'GTSF1L',
  'GUCA1A',
  'GUCA1B',
  'GUCA1C',
  'GUCA2B',
  'GUCD1',
  'GUCY1A1',
  'GUCY1A2',
  'GUCY1B1',
  'GUCY2D',
  'GUCY2F',
  'GULP1',
  'GUSB',
  'GUSBP1',
  'GUSBP3',
  'GUSBP5',
  'GUSBP11',
  'GVINP1',
  'GXYLT1',
  'GXYLT2',
  'GYG2',
  'GYPA',
  'GYPC',
  'GYPE',
  'GYS1',
  'GYS2',
  'GZF1',
  'GZMA',
  'GZMB',
  'GZMH',
  'GZMK',
  'GZMM',
  'H1-0',
  'H1-1',
  'H1-2',
  'H1-5',
  'H1-7',
  'H1-10',
  'H2AC6',
  'H2AC7',
  'H2AC11',
  'H2AC12',
  'H2AC13',
  'H2AC14',
  'H2AC15',
  'H2AC17',
  'H2AC18',
  'H2AC19',
  'H2AJ',
  'H2AW',
  'H2AX',
  'H2AZ1',
  'H2AZ2',
  'H2BC4',
  'H2BC5',
  'H2BC6',
  'H2BC9',
  'H2BC10',
  'H2BC12',
  'H2BC21',
  'H3-3A',
  'H3-3B',
  'H3C2',
  'H3C4',
  'H3C6',
  'H3C8',
  'H3C11',
  'H4-16',
  'H4C1',
  'H4C2',
  'H4C3',
  'H4C4',
  'H4C5',
  'H4C6',
  'H4C7',
  'H4C8',
  'H4C9',
  'H4C11',
  'H4C12',
  'H4C13',
  'H4C14',
  'H6PD',
  'H19',
  'HAAO',
  'HABP2',
  'HABP4',
  'HACD1',
  'HACD3',
  'HACD4',
  'HACE1',
  'HACL1',
  'HADH',
  'HADHA',
  'HADHB',
  'HAGH',
  'HAGHL',
  'HAND2-AS1',
  'HAPLN2',
  'HAPLN3',
  'HARBI1',
  'HARS2',
  'HAS1',
  'HAS2',
  'HAS3',
  'HAT1',
  'HAUS1',
  'HAUS2',
  'HAUS3',
  'HAUS4',
  'HAUS5',
  'HAUS6',
  'HAUS7',
  'HAVCR1P1',
  'HAX1',
  'HBA1',
  'HBA2',
  'HBB',
  'HBD',
  'HBEGF',
  'HBM',
  'HBP1',
  'HBS1L',
  'HBZ',
  'HCAR1',
  'HCAR3',
  'HCCAT5',
  'HCCS',
  'HCFC1R1',
  'HCFC2',
  'HCG4',
  'HCK',
  'HCLS1',
  'HCN1',
  'HCN3',
  'HCP5',
  'HCRTR1',
  'HCST',
  'HDAC1',
  'HDAC2',
  'HDAC3',
  'HDAC5',
  'HDAC6',
  'HDAC7',
  'HDAC9',
  'HDAC11',
  'HDC',
  'HDDC2',
  'HDDC3',
  'HDGF',
  'HDGFL1',
  'HDGFL2',
  'HDGFL3',
  'HDHD2',
  'HDHD3',
  'HDHD5-AS1',
  'HDHD5',
  'HDLBP',
  'HEATR1',
  'HEATR3',
  'HEATR4',
  'HEATR5A',
  'HEATR5B',
  'HEATR6',
  'HEBP1',
  'HECA',
  'HECTD2',
  'HECTD3',
  'HECTD4',
  'HECW1',
  'HECW2',
  'HEG1',
  'HELB',
  'HELLS',
  'HELQ',
  'HELZ',
  'HELZ2',
  'HEMGN',
  'HEMK1',
  'HEPACAM',
  'HEPACAM2',
  'HEPH',
  'HERC1',
  'HERC2P2',
  'HERC2P3',
  'HERC2P9',
  'HERC3',
  'HERC4',
  'HERC5',
  'HERC6',
  'HERPUD1',
  'HERPUD2',
  'HES1',
  'HES4',
  'HES6',
  'HESX1',
  'HEXA',
  'HEXB',
  'HEXD',
  'HEXIM1',
  'HEXIM2',
  'HEY1',
  'HEY2',
  'HEYL',
  'HFM1',
  'HGC6.3',
  'HGF',
  'HGFAC',
  'HGH1',
  'HGS',
  'HGSNAT',
  'HHAT',
  'HHATL',
  'HHEX',
  'HHIP',
  'HHIPL2',
  'HHLA1',
  'HHLA2',
  'HHLA3',
  'HIBADH',
  'HIBCH',
  'HIC1',
  'HIC2',
  'HID1',
  'HIF1A',
  'HIF3A',
  'HIGD1A',
  'HIGD1B',
  'HIGD2A',
  'HIKESHI',
  'HILPDA',
  'HINFP',
  'HINT2',
  'HINT3',
  'HIP1',
  'HIP1R',
  'HIPK1',
  'HIPK2',
  'HIPK3',
  'HIRA',
  'HIRIP3',
  'HIVEP2',
  'HIVEP3',
  'HJURP',
  'HJV',
  'HK1',
  'HK2',
  'HK3',
  'HLA-A',
  'HLA-B',
  'HLA-C',
  'HLA-DMA',
  'HLA-DMB',
  'HLA-DOA',
  'HLA-DOB',
  'HLA-DPA1',
  'HLA-DPB1',
  'HLA-DQA1',
  'HLA-DQA2',
  'HLA-DQB1',
  'HLA-DRA',
  'HLA-DRB1',
  'HLA-DRB5',
  'HLA-DRB6',
  'HLA-E',
  'HLA-F-AS1',
  'HLA-F',
  'HLA-G',
  'HLCS',
  'HLF',
  'HLTF',
  'HLX',
  'HM13',
  'HMBOX1',
  'HMBS',
  'HMCES',
  'HMCN1',
  'HMG20A',
  'HMG20B',
  'HMGA1',
  'HMGA2',
  'HMGB1',
  'HMGB2',
  'HMGB3',
  'HMGB3P1',
  'HMGCL',
  'HMGCLL1',
  'HMGCS2',
  'HMGN1',
  'HMGN2',
  'HMGN3',
  'HMGN4',
  'HMGN5',
  'HMGXB3',
  'HMGXB4',
  'HMHB1',
  'HMMR',
  'HMOX1',
  'HMOX2',
  'HMX1',
  'HNMT',
  'HNRNPA0',
  'HNRNPA1',
  'HNRNPA1L2',
  'HNRNPA1P10',
  'HNRNPA1P33',
  'HNRNPA2B1',
  'HNRNPA3',
  'HNRNPA3P1',
  'HNRNPAB',
  'HNRNPC',
  'HNRNPCL1',
  'HNRNPCL2',
  'HNRNPCL3',
  'HNRNPD',
  'HNRNPDL',
  'HNRNPF',
  'HNRNPH1',
  'HNRNPH2',
  'HNRNPH3',
  'HNRNPK',
  'HNRNPL',
  'HNRNPLL',
  'HNRNPM',
  'HNRNPR',
  'HNRNPU',
  'HNRNPUL1',
  'HNRNPUL2',
  'HOGA1',
  'HOMER1',
  'HOMER2',
  'HOMEZ',
  'HOOK2',
  'HOOK3',
  'HOPX',
  'HORMAD1',
  'HOXA1',
  'HOXA2',
  'HOXA3',
  'HOXA5',
  'HOXA6',
  'HOXA9',
  'HOXA10',
  'HOXA11',
  'HOXA13',
  'HOXB-AS3',
  'HOXB1',
  'HOXB2',
  'HOXB3',
  'HOXB6',
  'HOXB7',
  'HOXC4',
  'HOXC5',
  'HOXC6',
  'HOXC8',
  'HOXC9',
  'HOXC10',
  'HOXC11',
  'HOXC13',
  'HOXD1',
  'HOXD8',
  'HOXD11',
  'HOXD13',
  'HP',
  'HP1BP3',
  'HPCA',
  'HPCAL1',
  'HPCAL4',
  'HPD',
  'HPDL',
  'HPF1',
  'HPN',
  'HPR',
  'HPRT1',
  'HPS1',
  'HPS3',
  'HPS4',
  'HPS6',
  'HR',
  'HRAS',
  'HRC',
  'HRG',
  'HRH1',
  'HRK',
  'HROB',
  'HS2ST1',
  'HS3ST2',
  'HS3ST3A1',
  'HS3ST3B1',
  'HS3ST5',
  'HS6ST1',
  'HS6ST2',
  'HS6ST3',
  'HSBP1',
  'HSBP1L1',
  'HSCB',
  'HSD3B1',
  'HSD3B7',
  'HSD11B1',
  'HSD11B2',
  'HSD17B1',
  'HSD17B2',
  'HSD17B4',
  'HSD17B7',
  'HSD17B8',
  'HSD17B10',
  'HSD17B11',
  'HSD17B12',
  'HSD17B14',
  'HSDL1',
  'HSDL2',
  'HSF1',
  'HSF2',
  'HSF2BP',
  'HSF4',
  'HSFX1',
  'HSFX2',
  'HSFY1',
  'HSH2D',
  'HSP90AB1',
  'HSP90B1',
  'HSPA1A',
  'HSPA1B',
  'HSPA1L',
  'HSPA2',
  'HSPA4',
  'HSPA4L',
  'HSPA5',
  'HSPA6',
  'HSPA8',
  'HSPA9',
  'HSPA12A',
  'HSPA12B',
  'HSPA13',
  'HSPA14',
  'HSPB1',
  'HSPB2-C11orf52',
  'HSPB2',
  'HSPB3',
  'HSPB6',
  'HSPB7',
  'HSPB8',
  'HSPB11',
  'HSPBAP1',
  'HSPBP1',
  'HSPD1',
  'HSPE1',
  'HSPG2',
  'HSPH1',
  'HTATIP2',
  'HTATSF1',
  'HTN1',
  'HTR1D',
  'HTR1E',
  'HTR1F',
  'HTR2A',
  'HTR3A',
  'HTR4',
  'HTR7',
  'HTR7P1',
  'HTRA1',
  'HTRA3',
  'HTRA4',
  'HTT',
  'HUNK',
  'HUS1',
  'HUWE1',
  'HVCN1',
  'HYAL1',
  'HYAL2',
  'HYAL3',
  'HYAL4',
  'HYDIN',
  'HYI',
  'HYKK',
  'HYLS1',
  'HYMAI',
  'HYOU1',
  'HYPK',
  'IAH1',
  'IARS1',
  'IARS2',
  'IBA57',
  'ICA1',
  'ICA1L',
  'ICAM1',
  'ICAM2',
  'ICAM3',
  'ICAM5',
  'ICE1',
  'ICE2',
  'ICK',
  'ICMT',
  'ICOSLG',
  'ID1',
  'ID2',
  'ID3',
  'IDE',
  'IDH1',
  'IDH2',
  'IDH3A',
  'IDH3B',
  'IDH3G',
  'IDI1',
  'IDI2-AS1',
  'IDI2',
  'IDO1',
  'IDO2',
  'IDS',
  'IER2',
  'IER3',
  'IER3IP1',
  'IER5',
  'IER5L',
  'IFFO1',
  'IFI6',
  'IFI16',
  'IFI27',
  'IFI27L1',
  'IFI27L2',
  'IFI35',
  'IFI44',
  'IFI44L',
  'IFIH1',
  'IFIT1',
  'IFIT2',
  'IFIT3',
  'IFIT5',
  'IFITM1',
  'IFITM2',
  'IFITM3',
  'IFNA13',
  'IFNA21',
  'IFNAR1',
  'IFNAR2',
  'IFNGR1',
  'IFNGR2',
  'IFNLR1',
  'IFRD1',
  'IFRD2',
  'IFT20',
  'IFT22',
  'IFT27',
  'IFT43',
  'IFT52',
  'IFT57',
  'IFT74',
  'IFT80',
  'IFT81',
  'IFT88',
  'IFT122',
  'IFT140',
  'IFT172',
  'IGBP1',
  'IGDCC3',
  'IGDCC4',
  'IGF1',
  'IGF1R',
  'IGF2',
  'IGF2BP1',
  'IGF2BP2-AS1',
  'IGF2BP3',
  'IGF2R',
  'IGFBP2',
  'IGFBP3',
  'IGFBP4',
  'IGFBP5',
  'IGFBP6',
  'IGFBP7-AS1',
  'IGFBP7',
  'IGFL1',
  'IGFL2',
  'IGFLR1',
  'IGFN1',
  'IGHMBP2',
  'IGIP',
  'IGLON5',
  'IGSF3',
  'IGSF5',
  'IGSF6',
  'IGSF9B',
  'IGSF10',
  'IGSF11',
  'IGSF21',
  'IK',
  'IKBIP',
  'IKBKB',
  'IKBKE',
  'IKZF2',
  'IKZF3',
  'IKZF4',
  'IKZF5',
  'IL1B',
  'IL1R1',
  'IL1R2',
  'IL1RAP',
  'IL1RAPL1',
  'IL1RL2',
  'IL1RN',
  'IL2RA',
  'IL2RB',
  'IL3RA',
  'IL4I1',
  'IL4R',
  'IL5RA',
  'IL6R',
  'IL6ST',
  'IL9',
  'IL10RA',
  'IL10RB',
  'IL11',
  'IL11RA',
  'IL12B',
  'IL12RB1',
  'IL12RB2',
  'IL13RA1',
  'IL13RA2',
  'IL15',
  'IL15RA',
  'IL17C',
  'IL17D',
  'IL17F',
  'IL17RA',
  'IL17RB',
  'IL17RC',
  'IL17RD',
  'IL17RE',
  'IL18',
  'IL18BP',
  'IL18R1',
  'IL18RAP',
  'IL20',
  'IL20RA',
  'IL20RB',
  'IL21R',
  'IL22',
  'IL23A',
  'IL25',
  'IL26',
  'IL27',
  'IL27RA',
  'IL31RA',
  'IL32',
  'IL33',
  'IL34',
  'IL36A',
  'IL36B',
  'IL36G',
  'ILDR2',
  'ILF2',
  'ILF3-DT',
  'ILF3',
  'ILK',
  'ILKAP',
  'ILRUN',
  'ILVBL',
  'IMMP1L',
  'IMMP2L',
  'IMMT',
  'IMP3',
  'IMP4',
  'IMPA1',
  'IMPA2',
  'IMPACT',
  'IMPDH1',
  'IMPDH2',
  'IMPG1',
  'INAVA',
  'INCENP',
  'INE1',
  'INF2',
  'ING1',
  'ING2',
  'ING3',
  'ING4',
  'ING5',
  'INGX',
  'INHBA',
  'INHBB',
  'INHBC',
  'INHBE',
  'INIP',
  'INKA1',
  'INKA2',
  'INMT',
  'INO80',
  'INO80B',
  'INO80C',
  'INO80D',
  'INPP1',
  'INPP4A',
  'INPP4B',
  'INPP5A',
  'INPP5B',
  'INPP5D',
  'INPP5F',
  'INPP5J',
  'INPP5K',
  'INPPL1',
  'INS-IGF2',
  'INS',
  'INSIG1',
  'INSIG2',
  'INSL4',
  'INSL6',
  'INSR',
  'INSRR',
  'INSYN1',
  'INTS1',
  'INTS2',
  'INTS4',
  'INTS5',
  'INTS6',
  'INTS6L',
  'INTS7',
  'INTS8',
  'INTS10',
  'INTS11',
  'INTS12',
  'INTS13',
  'INTS14',
  'IP6K1',
  'IP6K2',
  'IP6K3',
  'IPCEF1',
  'IPMK',
  'IPO4',
  'IPO5',
  'IPO7',
  'IPO9',
  'IPO11',
  'IPO13',
  'IPP',
  'IPPK',
  'IQCA1',
  'IQCB1',
  'IQCC',
  'IQCF2',
  'IQCF5',
  'IQCK',
  'IQCN',
  'IQGAP1',
  'IQGAP2',
  'IQGAP3',
  'IQSEC1',
  'IQSEC2',
  'IQUB',
  'IRAK1',
  'IRAK1BP1',
  'IRAK2',
  'IRAK3',
  'IRAK4',
  'IREB2',
  'IRF1-AS1',
  'IRF1',
  'IRF2',
  'IRF2BP1',
  'IRF2BP2',
  'IRF2BPL',
  'IRF4',
  'IRF5',
  'IRF6',
  'IRF7',
  'IRF8',
  'IRF9',
  'IRS1',
  'IRS2',
  'IRX3',
  'IRX5',
  'ISCA1',
  'ISCA2',
  'ISCU',
  'ISG15',
  'ISG20',
  'ISG20L2',
  'ISL1',
  'ISL2',
  'ISLR',
  'ISLR2',
  'ISM1',
  'ISM2',
  'ISOC1',
  'ISOC2',
  'IST1',
  'ISY1',
  'ITCH',
  'ITFG1',
  'ITFG2',
  'ITGA1',
  'ITGA2',
  'ITGA3',
  'ITGA5',
  'ITGA6',
  'ITGA7',
  'ITGA8',
  'ITGA9',
  'ITGA10',
  'ITGA11',
  'ITGAD',
  'ITGAE',
  'ITGAL',
  'ITGAM',
  'ITGAV',
  'ITGAX',
  'ITGB1',
  'ITGB1BP1',
  'ITGB1BP2',
  'ITGB2',
  'ITGB3BP',
  'ITGB5',
  'ITGB6',
  'ITGBL1',
  'ITIH1',
  'ITIH2',
  'ITIH4',
  'ITIH5',
  'ITIH6',
  'ITK',
  'ITM2A',
  'ITM2B',
  'ITM2C',
  'ITPA',
  'ITPKA',
  'ITPKB',
  'ITPKC',
  'ITPR1',
  'ITPR2',
  'ITPR3',
  'ITPRID2',
  'ITPRIP',
  'ITPRIPL1',
  'ITPRIPL2',
  'ITSN1',
  'ITSN2',
  'IVD',
  'IVNS1ABP',
  'IYD',
  'IZUMO1',
  'IZUMO4',
  'JADE1',
  'JADE2',
  'JADE3',
  'JAG1',
  'JAG2',
  'JAGN1',
  'JAK1',
  'JAK2',
  'JAKMIP1',
  'JAKMIP2',
  'JAKMIP3',
  'JAM2',
  'JAM3',
  'JAML',
  'JARID2',
  'JAZF1',
  'JCAD',
  'JDP2',
  'JHY',
  'JKAMP',
  'JMJD1C',
  'JMJD6',
  'JMJD7-PLA2G4B',
  'JMJD7',
  'JMJD8',
  'JMY',
  'JOSD1',
  'JPH1',
  'JPH2',
  'JPH3',
  'JPT1',
  'JPT2',
  'JPX',
  'JRK',
  'JSRP1',
  'JTB',
  'JUN',
  'JUNB',
  'JUND',
  'JUP',
  'KAAG1',
  'KALRN',
  'KANK1',
  'KANK2',
  'KANK3',
  'KANK4',
  'KANSL1',
  'KANSL1L',
  'KANSL2',
  'KANSL3',
  'KARS1',
  'KAT2A',
  'KAT2B',
  'KAT5',
  'KAT6A',
  'KAT6B',
  'KAT7',
  'KAT8',
  'KAT14',
  'KATNA1',
  'KATNAL1',
  'KATNB1',
  'KATNBL1',
  'KAZALD1',
  'KAZN',
  'KBTBD2',
  'KBTBD3',
  'KBTBD4',
  'KBTBD6',
  'KBTBD7',
  'KBTBD8',
  'KBTBD11',
  'KBTBD12',
  'KCMF1',
  'KCNA1',
  'KCNA5',
  'KCNA7',
  'KCNA10',
  'KCNAB1',
  'KCNB1',
  'KCNC1',
  'KCNC4',
  'KCND1',
  'KCND2',
  'KCND3',
  'KCNE2',
  'KCNE5',
  'KCNF1',
  'KCNG2',
  'KCNH4',
  'KCNH7',
  'KCNH8',
  'KCNIP1',
  'KCNIP2',
  'KCNIP3',
  'KCNIP4',
  'KCNJ2',
  'KCNJ3',
  'KCNJ4',
  'KCNJ5',
  'KCNJ6',
  'KCNJ8',
  'KCNJ10',
  'KCNJ11',
  'KCNJ12',
  'KCNJ13',
  'KCNJ14',
  'KCNJ16',
  'KCNJ18',
  'KCNK1',
  'KCNK2',
  'KCNK5',
  'KCNK9',
  'KCNK15',
  'KCNK17',
  'KCNMA1',
  'KCNMB1',
  'KCNMB2',
  'KCNMB3',
  'KCNMB4',
  'KCNN1',
  'KCNN2',
  'KCNN3',
  'KCNN4',
  'KCNQ1',
  'KCNQ3',
  'KCNQ4',
  'KCNQ5',
  'KCNS3',
  'KCNT1',
  'KCP',
  'KCTD2',
  'KCTD3',
  'KCTD4',
  'KCTD5',
  'KCTD6',
  'KCTD7',
  'KCTD9',
  'KCTD10',
  'KCTD12',
  'KCTD13',
  'KCTD15',
  'KCTD17',
  'KCTD18',
  'KCTD20',
  'KDELR1',
  'KDELR2',
  'KDELR3',
  'KDF1',
  'KDM1A',
  'KDM1B',
  'KDM2A',
  'KDM2B',
  'KDM3A',
  'KDM3B',
  'KDM4A',
  'KDM4B',
  'KDM4C',
  'KDM4D',
  'KDM5A',
  'KDM5B',
  'KDM5C',
  'KDM5D',
  'KDM6A',
  'KDM6B',
  'KDM7A',
  'KDM8',
  'KDR',
  'KDSR',
  'KEAP1',
  'KHDC1',
  'KHDC4',
  'KHDRBS1',
  'KHDRBS2',
  'KHDRBS3',
  'KHNYN',
  'KHSRP',
  'KIAA0040',
  'KIAA0100',
  'KIAA0232',
  'KIAA0319',
  'KIAA0319L',
  'KIAA0355',
  'KIAA0408',
  'KIAA0513',
  'KIAA0556',
  'KIAA0754',
  'KIAA0825',
  'KIAA0895L',
  'KIAA0930',
  'KIAA1107',
  'KIAA1109',
  'KIAA1143',
  'KIAA1191',
  'KIAA1211L',
  'KIAA1217',
  'KIAA1257',
  'KIAA1324L',
  'KIAA1328',
  'KIAA1549',
  'KIAA1549L',
  'KIAA1586',
  'KIAA1671',
  'KIAA1755',
  'KIAA1958',
  'KIAA2013',
  'KIAA2026',
  'KIF1B',
  'KIF1C',
  'KIF2B',
  'KIF3A',
  'KIF3B',
  'KIF3C',
  'KIF4A',
  'KIF5B',
  'KIF5C',
  'KIF6',
  'KIF7',
  'KIF9',
  'KIF13A',
  'KIF13B',
  'KIF14',
  'KIF15',
  'KIF16B',
  'KIF18A',
  'KIF19',
  'KIF20A',
  'KIF20B',
  'KIF21A',
  'KIF21B',
  'KIF22',
  'KIF23',
  'KIF24',
  'KIF25',
  'KIF26A',
  'KIF27',
  'KIFAP3',
  'KIFBP',
  'KIFC1',
  'KIFC2',
  'KIFC3',
  'KIN',
  'KIR2DL1',
  'KIRREL1',
  'KIRREL3-AS2',
  'KIRREL3',
  'KIT',
  'KITLG',
  'KL',
  'KLC1',
  'KLC2',
  'KLC4',
  'KLF2',
  'KLF3-AS1',
  'KLF3',
  'KLF4',
  'KLF5',
  'KLF6',
  'KLF9',
  'KLF10',
  'KLF11',
  'KLF12',
  'KLF13',
  'KLF15',
  'KLF16',
  'KLHDC1',
  'KLHDC2',
  'KLHDC3',
  'KLHDC4',
  'KLHDC7A',
  'KLHDC10',
  'KLHL2',
  'KLHL4',
  'KLHL5',
  'KLHL6',
  'KLHL7',
  'KLHL8',
  'KLHL9',
  'KLHL10',
  'KLHL12',
  'KLHL13',
  'KLHL15',
  'KLHL17',
  'KLHL18',
  'KLHL20',
  'KLHL21',
  'KLHL22',
  'KLHL23',
  'KLHL24',
  'KLHL25',
  'KLHL26',
  'KLHL29',
  'KLHL31',
  'KLHL32',
  'KLHL34',
  'KLHL35',
  'KLHL36',
  'KLHL40',
  'KLHL41',
  'KLHL42',
  'KLK1',
  'KLK2',
  'KLK4',
  'KLK10',
  'KLK11',
  'KLK12',
  'KLK15',
  'KLKB1',
  'KLRB1',
  'KLRF1',
  'KLRK1',
  'KMO',
  'KMT2A',
  'KMT2B',
  'KMT2C',
  'KMT2D',
  'KMT2E',
  'KMT5A',
  'KMT5B',
  'KMT5C',
  'KNCN',
  'KNDC1',
  'KNG1',
  'KNL1',
  'KNOP1',
  'KNSTRN',
  'KNTC1',
  'KPNA1',
  'KPNA2',
  'KPNA3',
  'KPNA4',
  'KPNA5',
  'KPNA6',
  'KPNB1',
  'KPTN',
  'KRAS',
  'KRBA1',
  'KRBA2',
  'KRBOX4',
  'KRCC1',
  'KREMEN1',
  'KREMEN2',
  'KRI1',
  'KRIT1',
  'KRR1',
  'KRT4',
  'KRT6A',
  'KRT7',
  'KRT10',
  'KRT13',
  'KRT15',
  'KRT18',
  'KRT19',
  'KRT20',
  'KRT23',
  'KRT31',
  'KRT32',
  'KRT35',
  'KRT36',
  'KRT71',
  'KRT73',
  'KRT74',
  'KRT76',
  'KRT80',
  'KRT81',
  'KRT82',
  'KRT85',
  'KRT86',
  'KRTAP2-2',
  'KRTAP4-1',
  'KRTAP4-2',
  'KRTAP4-7',
  'KRTAP4-8',
  'KRTAP5-8',
  'KRTAP5-9',
  'KRTAP5-AS1',
  'KRTAP10-7',
  'KRTAP10-12',
  'KRTAP13-1',
  'KRTAP19-1',
  'KRTCAP2',
  'KRTCAP3',
  'KSR1',
  'KSR2',
  'KTI12',
  'KTN1',
  'KXD1',
  'KY',
  'KYAT1',
  'KYNU',
  'L1CAM',
  'L1TD1',
  'L2HGDH',
  'L3MBTL1',
  'L3MBTL2',
  'L3MBTL3',
  'L3MBTL4',
  'LACC1',
  'LACRT',
  'LACTB',
  'LACTB2',
  'LAD1',
  'LAGE3',
  'LAIR1',
  'LAIR2',
  'LALBA',
  'LAMA2',
  'LAMA3',
  'LAMA4',
  'LAMA5',
  'LAMB1',
  'LAMB2',
  'LAMB3',
  'LAMB4',
  'LAMC1',
  'LAMC2',
  'LAMC3',
  'LAMP1',
  'LAMP2',
  'LAMP3',
  'LAMTOR1',
  'LAMTOR2',
  'LAMTOR3',
  'LAMTOR4',
  'LAMTOR5',
  'LANCL1',
  'LANCL2',
  'LANCL3',
  'LAP3',
  'LAPTM4A',
  'LAPTM4B',
  'LAPTM5',
  'LARGE1',
  'LARP1',
  'LARP1B',
  'LARP4',
  'LARP4B',
  'LARP6',
  'LARP7',
  'LARS1',
  'LARS2',
  'LAS1L',
  'LASP1',
  'LATS1',
  'LATS2',
  'LAYN',
  'LBH',
  'LBHD1',
  'LBP',
  'LBR',
  'LBX1',
  'LBX2-AS1',
  'LCA5',
  'LCA5L',
  'LCAT',
  'LCE1E',
  'LCE3D',
  'LCK',
  'LCMT1',
  'LCMT2',
  'LCN1',
  'LCN2',
  'LCN6',
  'LCN8',
  'LCN10',
  'LCN15',
  'LCNL1',
  'LCOR',
  'LCORL',
  'LCP1',
  'LCP2',
  'LDAH',
  'LDB1',
  'LDB2',
  'LDB3',
  'LDHA',
  'LDHB',
  'LDHD',
  'LDLR',
  'LDLRAD3',
  'LDLRAD4',
  'LDLRAP1',
  'LDOC1',
  'LEAP2',
  'LECT2',
  'LEF1-AS1',
  'LEFTY1',
  'LEFTY2',
  'LELP1',
  'LEMD2',
  'LEMD3',
  'LENG1',
  'LENG8',
  'LEO1',
  'LEPROTL1',
  'LETM1',
  'LETM2',
  'LETMD1',
  'LEXM',
  'LFNG',
  'LGALS1',
  'LGALS2',
  'LGALS3',
  'LGALS3BP',
  'LGALS4',
  'LGALS8-AS1',
  'LGALS8',
  'LGALS9',
  'LGALS12',
  'LGALSL',
  'LGI1',
  'LGI3',
  'LGI4',
  'LGMN',
  'LGR4',
  'LGR5',
  'LGSN',
  'LHB',
  'LHCGR',
  'LHFPL1',
  'LHFPL2',
  'LHFPL6',
  'LHPP',
  'LHX2',
  'LHX6',
  'LHX9',
  'LIAS',
  'LIF',
  'LIFR',
  'LIG1',
  'LIG3',
  'LIG4',
  'LILRA1',
  'LILRA2',
  'LILRA4',
  'LILRA6',
  'LILRB1',
  'LILRB2',
  'LILRB3',
  'LILRB5',
  'LILRP2',
  'LIM2',
  'LIMA1',
  'LIMCH1',
  'LIMD1',
  'LIMD2',
  'LIME1',
  'LIMK1',
  'LIMK2',
  'LIMS1',
  'LIMS2',
  'LIMS3',
  'LIN7B',
  'LIN7C',
  'LIN9',
  'LIN28B',
  'LIN37',
  'LIN52',
  'LIN54',
  'LINC-PINT',
  'LINC00167',
  'LINC00173',
  'LINC00174',
  'LINC00208',
  'LINC00242',
  'LINC00304',
  'LINC00310',
  'LINC00312',
  'LINC00314',
  'LINC00319',
  'LINC00324',
  'LINC00339',
  'LINC00461',
  'LINC00471',
  'LINC00476',
  'LINC00525',
  'LINC00544',
  'LINC00622',
  'LINC00632',
  'LINC00638',
  'LINC00656',
  'LINC00661',
  'LINC00689',
  'LINC00710',
  'LINC00839',
  'LINC00852',
  'LINC00856',
  'LINC00863',
  'LINC00865',
  'LINC00896',
  'LINC00909',
  'LINC00910',
  'LINC00919',
  'LINC00930',
  'LINC00937',
  'LINC00954',
  'LINC00955',
  'LINC01003',
  'LINC01018',
  'LINC01089',
  'LINC01101',
  'LINC01114',
  'LINC01116',
  'LINC01118',
  'LINC01119',
  'LINC01128',
  'LINC01137',
  'LINC01138',
  'LINC01140',
  'LINC01142',
  'LINC01148',
  'LINC01270',
  'LINC01278',
  'LINC01341',
  'LINC01405',
  'LINC01465',
  'LINC01512',
  'LINC01551',
  'LINC01555',
  'LINC01558',
  'LINC01565',
  'LINC01686',
  'LINC01720',
  'LINC01854',
  'LINC01913',
  'LINC01963',
  'LINC02026',
  'LINC02035',
  'LINC02210',
  'LINC02226',
  'LINC02372',
  'LINC02381',
  'LINC02591',
  'LINC02603',
  'LINC02693',
  'LINC02716',
  'LINGO1',
  'LINGO3',
  'LINS1',
  'LIPA',
  'LIPE',
  'LIPF',
  'LIPG',
  'LIPH',
  'LIPJ',
  'LIPT1',
  'LITAF',
  'LIX1L',
  'LKAAEAR1',
  'LLPH',
  'LMAN1',
  'LMAN1L',
  'LMAN2',
  'LMAN2L',
  'LMBR1',
  'LMBR1L',
  'LMBRD1',
  'LMBRD2',
  'LMCD1',
  'LMF1',
  'LMF2',
  'LMLN',
  'LMNA',
  'LMNB1',
  'LMNB2',
  'LMO1',
  'LMO2',
  'LMO3',
  'LMO4',
  'LMO7',
  'LMOD1',
  'LMOD2',
  'LMOD3',
  'LMTK2',
  'LMTK3',
  'LMX1A',
  'LMX1B',
  'LNP1',
  'LNPK',
  'LNX1',
  'LNX2',
  'LOC93463',
  'LOC93622',
  'LOC145783',
  'LOC148709',
  'LOC150776',
  'LOC153684',
  'LOC155060',
  'LOC171391',
  'LOC202181',
  'LOC220729',
  'LOC283922',
  'LOC284379',
  'LOC339803',
  'LOC375196',
  'LOC389641',
  'LOC399900',
  'LOC400499',
  'LOC440896',
  'LOC441666',
  'LOC442028',
  'LOC642361',
  'LOC643406',
  'LOC645513',
  'LOC646214',
  'LOC648987',
  'LOC652276',
  'LOC653513',
  'LOC654780',
  'LOC728554',
  'LOC728613',
  'LOC731157',
  'LOC100128164',
  'LOC100128288',
  'LOC100129503',
  'LOC100130449',
  'LOC100130950',
  'LOC100132356',
  'LOC100132686',
  'LOC100134368',
  'LOC100505715',
  'LONP1',
  'LONP2',
  'LONRF1',
  'LONRF2',
  'LONRF3',
  'LOX',
  'LOXHD1',
  'LOXL1',
  'LOXL2',
  'LOXL3',
  'LOXL4',
  'LPAL2',
  'LPAR1',
  'LPAR3',
  'LPAR4',
  'LPAR6',
  'LPCAT1',
  'LPCAT2',
  'LPCAT3',
  'LPCAT4',
  'LPIN1',
  'LPIN2',
  'LPL',
  'LPO',
  'LPP',
  'LPXN',
  'LRAT',
  'LRATD1',
  'LRATD2',
  'LRBA',
  'LRCH1',
  'LRCH2',
  'LRCH3',
  'LRCH4',
  'LRFN1',
  'LRFN3',
  'LRFN4',
  'LRG1',
  'LRIF1',
  'LRIG1',
  'LRIG2',
  'LRIG3',
  'LRIT1',
  'LRMDA',
  'LRMP',
  'LRP1',
  'LRP1B',
  'LRP2BP',
  'LRP3',
  'LRP4',
  'LRP5',
  'LRP5L',
  'LRP6',
  'LRP10',
  'LRP11',
  'LRP12',
  'LRPAP1',
  'LRPPRC',
  'LRR1',
  'LRRC2',
  'LRRC3',
  'LRRC3B',
  'LRRC4B',
  'LRRC4C',
  'LRRC7',
  'LRRC8A',
  'LRRC8B',
  'LRRC8C',
  'LRRC14',
  'LRRC15',
  'LRRC17',
  'LRRC19',
  'LRRC20',
  'LRRC28',
  'LRRC29',
  'LRRC32',
  'LRRC34',
  'LRRC36',
  'LRRC37A',
  'LRRC37A2',
  'LRRC37A3',
  'LRRC37B',
  'LRRC38',
  'LRRC39',
  'LRRC41',
  'LRRC47',
  'LRRC49',
  'LRRC56',
  'LRRC57',
  'LRRC58',
  'LRRC59',
  'LRRC61',
  'LRRC66',
  'LRRC69',
  'LRRC70',
  'LRRC71',
  'LRRC73',
  'LRRC74A',
  'LRRC77P',
  'LRRD1',
  'LRRFIP1',
  'LRRFIP2',
  'LRRIQ1',
  'LRRIQ3',
  'LRRK1',
  'LRRK2',
  'LRRN1',
  'LRRN2',
  'LRRN4CL',
  'LRRTM1',
  'LRRTM2',
  'LRRTM4',
  'LRSAM1',
  'LRTOMT',
  'LSAMP',
  'LSG1',
  'LSM1',
  'LSM3',
  'LSM4',
  'LSM5',
  'LSM6',
  'LSM7',
  'LSM8',
  'LSM11',
  'LSM12',
  'LSM14A',
  'LSM14B',
  'LSMEM1',
  'LSMEM2',
  'LSP1',
  'LSP1P4',
  'LSP1P5',
  'LSR',
  'LSS',
  'LST1',
  'LTA',
  'LTA4H',
  'LTB4R',
  'LTB4R2',
  'LTBP1',
  'LTBP2',
  'LTBP3',
  'LTBP4',
  'LTF',
  'LTK',
  'LTN1',
  'LUC7L',
  'LUC7L2',
  'LUC7L3',
  'LUM',
  'LURAP1',
  'LURAP1L',
  'LUZP1',
  'LUZP2',
  'LUZP6',
  'LXN',
  'LY6D',
  'LY6E',
  'LY6G5B',
  'LY6G6F',
  'LY6H',
  'LY9',
  'LY75',
  'LY86-AS1',
  'LY86',
  'LY96',
  'LYAR',
  'LYG1',
  'LYL1',
  'LYN',
  'LYNX1',
  'LYPD4',
  'LYPD5',
  'LYPLA1',
  'LYPLA2',
  'LYPLAL1',
  'LYRM1',
  'LYRM2',
  'LYRM9',
  'LYSMD1',
  'LYSMD2',
  'LYSMD3',
  'LYSMD4',
  'LYST',
  'LYVE1',
  'LYZ',
  'LYZL4',
  'LZIC',
  'LZTFL1',
  'LZTR1',
  'LZTS1',
  'LZTS2',
  'LZTS3',
  'M6PR',
  'MAB21L1',
  'MAB21L2',
  'MACF1',
  'MACO1',
  'MACROD1',
  'MACROD2',
  'MACROH2A1',
  'MACROH2A2',
  'MAD1L1',
  'MAD2L1',
  'MAD2L1BP',
  'MAD2L2',
  'MADCAM1',
  'MADD',
  'MAEA',
  'MAEL',
  'MAF',
  'MAF1',
  'MAFB',
  'MAFF',
  'MAFG',
  'MAFK',
  'MAGEA4',
  'MAGEA8',
  'MAGEA9',
  'MAGEA9B',
  'MAGEA11',
  'MAGEB2',
  'MAGEB6',
  'MAGEB18',
  'MAGEC3',
  'MAGED1',
  'MAGED2',
  'MAGED4',
  'MAGED4B',
  'MAGEF1',
  'MAGEL2',
  'MAGI1',
  'MAGI2',
  'MAGI3',
  'MAGT1',
  'MAIP1',
  'MAK16',
  'MAL',
  'MAL2',
  'MALL',
  'MALSU1',
  'MALT1',
  'MAMDC4',
  'MAML1',
  'MAML2',
  'MAML3',
  'MAMLD1',
  'MAMSTR',
  'MAN1A1',
  'MAN1B1',
  'MAN1C1',
  'MAN2A1',
  'MAN2A2',
  'MAN2B1',
  'MAN2C1',
  'MANBA',
  'MANBAL',
  'MANEAL',
  'MANF',
  'MANSC1',
  'MAOA',
  'MAOB',
  'MAP1A',
  'MAP1B',
  'MAP1LC3B',
  'MAP1LC3B2',
  'MAP1S',
  'MAP2',
  'MAP2K1',
  'MAP2K2',
  'MAP2K3',
  'MAP2K4',
  'MAP2K5',
  'MAP2K6',
  'MAP2K7',
  'MAP3K1',
  'MAP3K2',
  'MAP3K3',
  'MAP3K4',
  'MAP3K5',
  'MAP3K6',
  'MAP3K7CL',
  'MAP3K8',
  'MAP3K9',
  'MAP3K10',
  'MAP3K11',
  'MAP3K12',
  'MAP3K13',
  'MAP3K14-AS1',
  'MAP3K14',
  'MAP3K15',
  'MAP3K19',
  'MAP3K20',
  'MAP3K21',
  'MAP4',
  'MAP4K1',
  'MAP4K2',
  'MAP4K3',
  'MAP4K4',
  'MAP6',
  'MAP6D1',
  'MAP7',
  'MAP7D1',
  'MAP7D3',
  'MAP10',
  'MAP11',
  'MAPK1',
  'MAPK1IP1L',
  'MAPK3',
  'MAPK6',
  'MAPK7',
  'MAPK8',
  'MAPK8IP1',
  'MAPK8IP3',
  'MAPK9',
  'MAPK11',
  'MAPK12',
  'MAPK14',
  'MAPK15',
  'MAPKAP1',
  'MAPKAPK2',
  'MAPKAPK3',
  'MAPKAPK5-AS1',
  'MAPKAPK5',
  'MAPRE1',
  'MAPRE2',
  'MAPRE3',
  'MAPT',
  'MARC1',
  'MARCHF1',
  'MARCHF3',
  'MARCHF4',
  'MARCHF5',
  'MARCHF6',
  'MARCHF7',
  'MARCHF8',
  'MARCKS',
  'MARCKSL1',
  'MARCO',
  'MARF1',
  'MARK1',
  'MARK3',
  'MARK4',
  'MARS1',
  'MARS2',
  'MARVELD1',
  'MARVELD3',
  'MASP1',
  'MASP2',
  'MAST2',
  'MAST3',
  'MAST4',
  'MASTL',
  'MAT1A',
  'MAT2A',
  'MAT2B',
  'MATK',
  'MATN2',
  'MATN3',
  'MATR3',
  'MAU2',
  'MAVS',
  'MAX',
  'MAZ',
  'MB21D2',
  'MBD1',
  'MBD2',
  'MBD3',
  'MBD3L2',
  'MBD4',
  'MBD5',
  'MBD6',
  'MBIP',
  'MBLAC1',
  'MBLAC2',
  'MBNL1',
  'MBNL2',
  'MBNL3',
  'MBOAT2',
  'MBP',
  'MBTD1',
  'MBTPS1',
  'MBTPS2',
  'MC5R',
  'MCAM',
  'MCAT',
  'MCC',
  'MCCC1',
  'MCCC2',
  'MCEE',
  'MCEMP1',
  'MCF2',
  'MCF2L',
  'MCF2L2',
  'MCFD2',
  'MCL1',
  'MCM2',
  'MCM3',
  'MCM3AP-AS1',
  'MCM3AP',
  'MCM4',
  'MCM5',
  'MCM6',
  'MCM7',
  'MCM8',
  'MCMBP',
  'MCMDC2',
  'MCOLN1',
  'MCOLN3',
  'MCPH1',
  'MCRIP1',
  'MCRIP2',
  'MCRS1',
  'MCTP1',
  'MCTS1',
  'MCU',
  'MCUB',
  'MCUR1',
  'MDC1',
  'MDFI',
  'MDFIC',
  'MDGA1',
  'MDH1',
  'MDH1B',
  'MDH2',
  'MDK',
  'MDM1',
  'MDM2',
  'MDM4',
  'MDN1',
  'ME1',
  'ME2',
  'ME3',
  'MEA1',
  'MEAF6',
  'MECOM',
  'MECP2',
  'MECR',
  'MED1',
  'MED4',
  'MED6',
  'MED7',
  'MED8',
  'MED9',
  'MED10',
  'MED11',
  'MED12',
  'MED13',
  'MED13L',
  'MED14',
  'MED15',
  'MED16',
  'MED17',
  'MED18',
  'MED19',
  'MED21',
  'MED22',
  'MED23',
  'MED24',
  'MED25',
  'MED26',
  'MED27',
  'MED28',
  'MED29',
  'MED30',
  'MED31',
  'MEDAG',
  'MEF2A',
  'MEF2B',
  'MEF2C',
  'MEF2D',
  'MEFV',
  'MEG3',
  'MEG9',
  'MEGF6',
  'MEGF8',
  'MEGF9',
  'MEGF10',
  'MEI1',
  'MEIG1',
  'MEIOC',
  'MEIS1',
  'MEIS2',
  'MEIS3',
  'MEIS3P1',
  'MELK',
  'MELTF',
  'MEMO1',
  'MEN1',
  'MEOX1',
  'MEOX2',
  'MEP1A',
  'MEPCE',
  'MEPE',
  'MERTK',
  'MESD',
  'MESP1',
  'MESP2',
  'MEST',
  'MET',
  'METAP1',
  'METAP1D',
  'METAP2',
  'METRN',
  'METRNL',
  'METTL1',
  'METTL3',
  'METTL4',
  'METTL5',
  'METTL6',
  'METTL7A',
  'METTL7B',
  'METTL8',
  'METTL9',
  'METTL14',
  'METTL16',
  'METTL17',
  'METTL18',
  'METTL21A',
  'METTL21C',
  'METTL21EP',
  'METTL22',
  'METTL23',
  'METTL25',
  'METTL26',
  'MEX3A',
  'MEX3B',
  'MEX3D',
  'MFAP1',
  'MFAP2',
  'MFAP3',
  'MFAP3L',
  'MFAP4',
  'MFAP5',
  'MFF',
  'MFGE8',
  'MFHAS1',
  'MFN1',
  'MFN2',
  'MFNG',
  'MFRP',
  'MFSD1',
  'MFSD2A',
  'MFSD2B',
  'MFSD3',
  'MFSD4A',
  'MFSD4B',
  'MFSD5',
  'MFSD6',
  'MFSD6L',
  'MFSD8',
  'MFSD9',
  'MFSD10',
  'MFSD11',
  'MFSD12',
  'MFSD13A',
  'MFSD14A',
  'MGA',
  'MGAM',
  'MGARP',
  'MGAT1',
  'MGAT2',
  'MGAT3',
  'MGAT4A',
  'MGAT4B',
  'MGAT4C',
  'MGAT4D',
  'MGAT4EP',
  'MGAT5',
  'MGC16025',
  'MGLL',
  'MGME1',
  'MGMT',
  'MGP',
  'MGRN1',
  'MGST1',
  'MGST2',
  'MGST3',
  'MIA2',
  'MIA3',
  'MIB2',
  'MICA',
  'MICAL1',
  'MICAL2',
  'MICAL3',
  'MICALL1',
  'MICALL2',
  'MICOS10',
  'MICOS13',
  'MICU1',
  'MICU3',
  'MID1',
  'MID1IP1',
  'MID2',
  'MIDN',
  'MIEF1',
  'MIEF2',
  'MIEN1',
  'MIER1',
  'MIER2',
  'MIER3',
  'MIF',
  'MIF4GD',
  'MIGA1',
  'MIGA2',
  'MIIP',
  'MINAR1',
  'MINDY1',
  'MINDY2',
  'MIOS',
  'MIP',
  'MIPEP',
  'MIPOL1',
  'MIR7-3HG',
  'MIR15A',
  'MIR17HG',
  'MIR24-2',
  'MIR100HG',
  'MIR101-1',
  'MIR302B',
  'MIR503HG',
  'MIR600HG',
  'MIR646HG',
  'MIR663AHG',
  'MIR924HG',
  'MIR1915HG',
  'MIR3976HG',
  'MIR4435-2HG',
  'MIR4453HG',
  'MIRLET7BHG',
  'MIS12',
  'MIS18BP1',
  'MISP3',
  'MITF',
  'MKI67',
  'MKKS',
  'MKLN1',
  'MKNK1',
  'MKNK2',
  'MKRN1',
  'MKRN2',
  'MKS1',
  'MKX',
  'MLC1',
  'MLEC',
  'MLF1',
  'MLF2',
  'MLH3',
  'MLIP',
  'MLKL',
  'MLLT1',
  'MLLT3',
  'MLLT6',
  'MLLT10',
  'MLLT11',
  'MLPH',
  'MLST8',
  'MLX',
  'MLXIP',
  'MLXIPL',
  'MLYCD',
  'MMAA',
  'MMAB',
  'MMACHC',
  'MMD',
  'MMD2',
  'MME',
  'MMGT1',
  'MMP1',
  'MMP2',
  'MMP3',
  'MMP7',
  'MMP8',
  'MMP11',
  'MMP13',
  'MMP14',
  'MMP15',
  'MMP16',
  'MMP19',
  'MMP24',
  'MMP25',
  'MMP26',
  'MMP28',
  'MMRN2',
  'MMS19',
  'MMUT',
  'MN1',
  'MNAT1',
  'MND1',
  'MNS1',
  'MNX1-AS1',
  'MNX1',
  'MOAP1',
  'MOB1A',
  'MOB1B',
  'MOB2',
  'MOB3A',
  'MOB3B',
  'MOB3C',
  'MOB4',
  'MOCOS',
  'MOCS1',
  'MOCS2',
  'MOCS3',
  'MOGAT1',
  'MOGAT3',
  'MOGS',
  'MOK',
  'MON1A',
  'MON2',
  'MORC2-AS1',
  'MORC2',
  'MORC3',
  'MORC4',
  'MORF4L1',
  'MORF4L2',
  'MORN1',
  'MORN2',
  'MORN3',
  'MORN5',
  'MOSMO',
  'MOSPD1',
  'MOV10',
  'MOXD1',
  'MPC1',
  'MPC2',
  'MPDU1',
  'MPDZ',
  'MPEG1',
  'MPG',
  'MPHOSPH6',
  'MPHOSPH8',
  'MPHOSPH9',
  'MPHOSPH10',
  'MPI',
  'MPL',
  'MPLKIP',
  'MPP1',
  'MPP2',
  'MPP3',
  'MPP5',
  'MPP6',
  'MPP7',
  'MPPE1',
  'MPPED2',
  'MPRIP',
  'MPST',
  'MPV17',
  'MPV17L',
  'MPV17L2',
  'MPZL1',
  'MPZL2',
  'MPZL3',
  'MR1',
  'MRAP',
  'MRC1',
  'MRC2',
  'MRE11',
  'MREG',
  'MRFAP1',
  'MRFAP1L1',
  'MRGBP',
  'MRGPRF',
  'MRGPRX1',
  'MRI1',
  'MRM1',
  'MRM2',
  'MRM3',
  'MROH2B',
  'MROH6',
  'MROH7',
  'MROH8',
  'MROH9',
  'MRPL1',
  'MRPL2',
  'MRPL4',
  'MRPL9',
  'MRPL10',
  'MRPL11',
  'MRPL12',
  'MRPL13',
  'MRPL14',
  'MRPL15',
  'MRPL16',
  'MRPL17',
  'MRPL19',
  'MRPL20',
  'MRPL21',
  'MRPL22',
  'MRPL23',
  'MRPL27',
  'MRPL28',
  'MRPL30',
  'MRPL32',
  'MRPL33',
  'MRPL34',
  'MRPL35',
  'MRPL36',
  'MRPL37',
  'MRPL38',
  'MRPL39',
  'MRPL40',
  'MRPL41',
  'MRPL42',
  'MRPL43',
  'MRPL44',
  'MRPL45',
  'MRPL46',
  'MRPL47',
  'MRPL48',
  'MRPL49',
  'MRPL50',
  'MRPL51',
  'MRPL52',
  'MRPL53',
  'MRPL54',
  'MRPL55',
  'MRPL57',
  'MRPL58',
  'MRPS2',
  'MRPS5',
  'MRPS6',
  'MRPS7',
  'MRPS9',
  'MRPS10',
  'MRPS11',
  'MRPS12',
  'MRPS14',
  'MRPS15',
  'MRPS16',
  'MRPS17',
  'MRPS18A',
  'MRPS18B',
  'MRPS18C',
  'MRPS21',
  'MRPS22',
  'MRPS24',
  'MRPS25',
  'MRPS26',
  'MRPS27',
  'MRPS28',
  'MRPS30',
  'MRPS31',
  'MRPS31P5',
  'MRPS33',
  'MRPS34',
  'MRPS35',
  'MRPS36',
  'MRRF',
  'MRS2',
  'MRTFA',
  'MRTFB',
  'MRTO4',
  'MRVI1-AS1',
  'MRVI1',
  'MS4A2',
  'MS4A4A',
  'MS4A5',
  'MS4A6A',
  'MS4A7',
  'MS4A8',
  'MS4A14',
  'MS4A15',
  'MSANTD2',
  'MSANTD3',
  'MSANTD4',
  'MSC',
  'MSH2',
  'MSH4',
  'MSH6',
  'MSI2',
  'MSL1',
  'MSL2',
  'MSL3',
  'MSMO1',
  'MSN',
  'MSR1',
  'MSRA',
  'MSRB1',
  'MSRB3',
  'MSS51',
  'MST1',
  'MST1R',
  'MSTN',
  'MSTO1',
  'MSX1',
  'MSX2',
  'MT1E',
  'MT1F',
  'MT1G',
  'MT1H',
  'MT1HL1',
  'MT1M',
  'MT1X',
  'MT2A',
  'MT3',
  'MTA1',
  'MTA2',
  'MTBP',
  'MTCH1',
  'MTCH2',
  'MTCL1',
  'MTCP1',
  'MTDH',
  'MTERF2',
  'MTERF3',
  'MTERF4',
  'MTF1',
  'MTF2',
  'MTFP1',
  'MTFR1L',
  'MTFR2',
  'MTG1',
  'MTG2',
  'MTHFD1',
  'MTHFD1L',
  'MTHFD2',
  'MTHFD2L',
  'MTHFR',
  'MTHFS',
  'MTHFSD',
  'MTIF2',
  'MTIF3',
  'MTM1',
  'MTMR1',
  'MTMR2',
  'MTMR3',
  'MTMR4',
  'MTMR7',
  'MTMR8',
  'MTMR10',
  'MTMR11',
  'MTMR12',
  'MTMR14',
  'MTO1',
  'MTPAP',
  'MTPN',
  'MTR',
  'MTRES1',
  'MTREX',
  'MTRF1',
  'MTRF1L',
  'MTRR',
  'MTSS1',
  'MTSS2',
  'MTTP',
  'MTURN',
  'MTUS1',
  'MTX1',
  'MTX2',
  'MTX3',
  'MUC1',
  'MUC3A',
  'MUC6',
  'MUC7',
  'MUC13',
  'MUCL3',
  'MUL1',
  'MUS81',
  'MUSTN1',
  'MUTYH',
  'MVB12A',
  'MVB12B',
  'MVD',
  'MVK',
  'MVP',
  'MX1',
  'MX2',
  'MXD1',
  'MXD3',
  'MXD4',
  'MXI1',
  'MXRA5',
  'MXRA7',
  'MXRA8',
  'MYADM',
  'MYADML2',
  'MYBBP1A',
  'MYBL2',
  'MYBPC1',
  'MYBPC2',
  'MYBPH',
  'MYC',
  'MYCBP',
  'MYCBP2',
  'MYCBPAP',
  'MYCL',
  'MYCN',
  'MYCT1',
  'MYD88',
  'MYDGF',
  'MYEOV',
  'MYF6',
  'MYH1',
  'MYH2',
  'MYH3',
  'MYH4',
  'MYH6',
  'MYH7',
  'MYH7B',
  'MYH8',
  'MYH9',
  'MYH10',
  'MYH11',
  'MYH13',
  'MYH14',
  'MYH15',
  'MYL1',
  'MYL2',
  'MYL3',
  'MYL5',
  'MYL6',
  'MYL6B',
  'MYL9',
  'MYL10',
  'MYL12A',
  'MYL12B',
  'MYLIP',
  'MYLK',
  'MYLK2',
  'MYLK3',
  'MYLK4',
  'MYLPF',
  'MYNN',
  'MYO1B',
  'MYO1C',
  'MYO1D',
  'MYO1E',
  'MYO1F',
  'MYO1G',
  'MYO5A',
  'MYO5B',
  'MYO5C',
  'MYO6',
  'MYO7A',
  'MYO7B',
  'MYO9A',
  'MYO9B',
  'MYO10',
  'MYO15B',
  'MYO18A',
  'MYO18B',
  'MYO19',
  'MYOC',
  'MYOCD',
  'MYOD1',
  'MYOF',
  'MYOG',
  'MYOM1',
  'MYOM2',
  'MYOM3',
  'MYORG',
  'MYOT',
  'MYOZ1',
  'MYOZ2',
  'MYOZ3',
  'MYPN',
  'MYPOP',
  'MYRFL',
  'MYRIP',
  'MYSM1',
  'MYT1L',
  'MZB1',
  'MZF1',
  'MZT1',
  'MZT2A',
  'N4BP1',
  'N4BP2',
  'N4BP2L1',
  'N4BP2L2',
  'N4BP3',
  'N6AMT1',
  'NAA10',
  'NAA11',
  'NAA15',
  'NAA16',
  'NAA20',
  'NAA25',
  'NAA30',
  'NAA35',
  'NAA38',
  'NAA40',
  'NAA50',
  'NAA60',
  'NAA80',
  'NAAA',
  'NAALAD2',
  'NAALADL1',
  'NAB1',
  'NAB2',
  'NABP1',
  'NABP2',
  'NACA',
  'NACAD',
  'NACC1',
  'NACC2',
  'NADK',
  'NADK2',
  'NAE1',
  'NAF1',
  'NAGA',
  'NAGK',
  'NAGLU',
  'NAGPA',
  'NAIF1',
  'NAIP',
  'NAMPT',
  'NANOG',
  'NANOS1',
  'NANOS2',
  'NANOS3',
  'NANP',
  'NANS',
  'NAP1L1',
  'NAP1L2',
  'NAP1L4',
  'NAP1L5',
  'NAPA',
  'NAPB',
  'NAPEPLD',
  'NAPRT',
  'NAPSB',
  'NARF',
  'NARS1',
  'NASP',
  'NAT1',
  'NAT8L',
  'NAT10',
  'NAT14',
  'NATD1',
  'NAV1',
  'NAV2',
  'NAV3',
  'NAXD',
  'NAXE',
  'NBAS',
  'NBDY',
  'NBEA',
  'NBEAL1',
  'NBEAL2',
  'NBL1',
  'NBPF1',
  'NBPF3',
  'NBPF8',
  'NBPF9',
  'NBPF10',
  'NBPF11',
  'NBPF12',
  'NBPF15',
  'NBPF20',
  'NBR1',
  'NCALD',
  'NCAM2',
  'NCAPD2',
  'NCAPD3',
  'NCAPG',
  'NCAPG2',
  'NCAPH',
  'NCAPH2',
  'NCBP1',
  'NCBP2',
  'NCBP2AS2',
  'NCBP3',
  'NCDN',
  'NCEH1',
  'NCF4',
  'NCK2',
  'NCKAP1',
  'NCKAP1L',
  'NCKAP5',
  'NCKAP5L',
  'NCL',
  'NCLN',
  'NCOA1',
  'NCOA2',
  'NCOA3',
  'NCOA4',
  'NCOA5',
  'NCOA6',
  'NCOA7',
  'NCOR1',
  'NCOR2',
  'NCR2',
  'NCR3',
  'NCS1',
  'NCSTN',
  'NDC1',
  'NDC80',
  'NDE1',
  'NDEL1',
  'NDFIP2',
  'NDN',
  'NDNF',
  'NDOR1',
  'NDP',
  'NDRG1',
  'NDRG2',
  'NDRG4',
  'NDST1',
  'NDST2',
  'NDST4',
  'NDUFA1',
  'NDUFA2',
  'NDUFA3',
  'NDUFA4',
  'NDUFA4L2',
  'NDUFA5',
  'NDUFA6',
  'NDUFA7',
  'NDUFA8',
  'NDUFA9',
  'NDUFA10',
  'NDUFA11',
  'NDUFA12',
  'NDUFA13',
  'NDUFAB1',
  'NDUFAF1',
  'NDUFAF2',
  'NDUFAF3',
  'NDUFAF4',
  'NDUFAF5',
  'NDUFAF6',
  'NDUFAF7',
  'NDUFB1',
  'NDUFB2-AS1',
  'NDUFB2',
  'NDUFB3',
  'NDUFB4',
  'NDUFB5',
  'NDUFB6',
  'NDUFB7',
  'NDUFB8',
  'NDUFB9',
  'NDUFB10',
  'NDUFB11',
  'NDUFC1',
  'NDUFS1',
  'NDUFS2',
  'NDUFS3',
  'NDUFS4',
  'NDUFS5',
  'NDUFS6',
  'NDUFS7',
  'NDUFS8',
  'NDUFV1',
  'NDUFV2',
  'NDUFV3',
  'NEB',
  'NEBL',
  'NECAB3',
  'NECAP1',
  'NECAP2',
  'NECTIN1',
  'NECTIN2',
  'NECTIN3',
  'NEDD1',
  'NEDD4',
  'NEDD4L',
  'NEDD9',
  'NEFH',
  'NEIL2',
  'NEK1',
  'NEK2',
  'NEK3',
  'NEK4',
  'NEK6',
  'NEK7',
  'NEK10',
  'NEK11',
  'NELFA',
  'NELFB',
  'NELFCD',
  'NELFE',
  'NEMF',
  'NEMP1',
  'NEMP2',
  'NENF',
  'NEO1',
  'NEPRO',
  'NES',
  'NET1',
  'NETO1',
  'NETO2',
  'NEU1',
  'NEU2',
  'NEU3',
  'NEU4',
  'NEURL1',
  'NEURL1B',
  'NEURL2',
  'NEURL4',
  'NEUROD6',
  'NEUROG2',
  'NEXMIF',
  'NEXN-AS1',
  'NEXN',
  'NF2',
  'NFASC',
  'NFAT5',
  'NFATC1',
  'NFATC2',
  'NFATC2IP',
  'NFATC3',
  'NFATC4',
  'NFE2L1',
  'NFE2L2',
  'NFIA',
  'NFIB',
  'NFIC',
  'NFIL3',
  'NFIX',
  'NFKB1',
  'NFKB2',
  'NFKBIA',
  'NFKBIB',
  'NFKBIZ',
  'NFRKB',
  'NFS1',
  'NFU1',
  'NFX1',
  'NFXL1',
  'NFYA',
  'NFYB',
  'NFYC-AS1',
  'NFYC',
  'NGDN',
  'NGEF',
  'NGFR',
  'NGLY1',
  'NGRN',
  'NHEJ1',
  'NHLH1',
  'NHLH2',
  'NHLRC2',
  'NHP2',
  'NHS',
  'NHSL1',
  'NHSL2',
  'NIBAN1',
  'NIBAN2',
  'NICN1',
  'NID1',
  'NID2',
  'NIF3L1',
  'NIFK',
  'NIM1K',
  'NIN',
  'NINJ1',
  'NINJ2-AS1',
  'NINJ2',
  'NINL',
  'NIP7',
  'NIPA2',
  'NIPAL1',
  'NIPAL2',
  'NIPAL3',
  'NIPBL',
  'NIPSNAP1',
  'NIPSNAP2',
  'NIPSNAP3A',
  'NIPSNAP3B',
  'NISCH',
  'NIT2',
  'NKAIN1',
  'NKAIN2',
  'NKAIN3',
  'NKAIN4',
  'NKAP',
  'NKAPD1',
  'NKAPL',
  'NKAPP1',
  'NKD2',
  'NKG7',
  'NKIRAS1',
  'NKIRAS2',
  'NKRF',
  'NKTR',
  'NKX2-8',
  'NKX3-1',
  'NKX3-2',
  'NKX6-1',
  'NKX6-2',
  'NKX6-3',
  'NLE1',
  'NLGN1',
  'NLGN4Y',
  'NLK',
  'NLN',
  'NLRC3',
  'NLRC4',
  'NLRC5',
  'NLRP1',
  'NLRP3',
  'NLRP5',
  'NLRP9',
  'NLRP11',
  'NLRP14',
  'NLRX1',
  'NMB',
  'NMD3',
  'NME1-NME2',
  'NME1',
  'NME2',
  'NME3',
  'NME4',
  'NME5',
  'NME6',
  'NME7',
  'NMNAT1',
  'NMNAT2',
  'NMNAT3',
  'NMRAL1',
  'NMRK1',
  'NMRK2',
  'NMT1',
  'NMT2',
  'NMU',
  'NMUR1',
  'NNAT',
  'NNMT',
  'NNT',
  'NOA1',
  'NOBOX',
  'NOC2L',
  'NOC3L',
  'NOC4L',
  'NOCT',
  'NOD1',
  'NODAL',
  'NOL3',
  'NOL4',
  'NOL4L',
  'NOL6',
  'NOL7',
  'NOL8',
  'NOL9',
  'NOL10',
  'NOL11',
  'NOLC1',
  'NOMO1',
  'NOMO2',
  'NOMO3',
  'NONO',
  'NOP2',
  'NOP9',
  'NOP10',
  'NOP14',
  'NOP16',
  'NOP53',
  'NOP56',
  'NOP58',
  'NORAD',
  'NOS1',
  'NOS2',
  'NOS3',
  'NOSIP',
  'NOSTRIN',
  'NOTCH1',
  'NOTCH2',
  'NOTCH2NLA',
  'NOTCH3',
  'NOTCH4',
  'NOTUM',
  'NOVA1',
  'NOVA2',
  'NOX1',
  'NOX3',
  'NOX4',
  'NOX5',
  'NOXA1',
  'NOXO1',
  'NOXRED1',
  'NPAP1',
  'NPAS1',
  'NPAS2',
  'NPAS3',
  'NPAT',
  'NPC1',
  'NPC1L1',
  'NPC2',
  'NPDC1',
  'NPEPL1',
  'NPEPPS',
  'NPEPPSP1',
  'NPHP1',
  'NPHP3',
  'NPIPA1',
  'NPIPB3',
  'NPIPB4',
  'NPIPB5',
  'NPIPB6',
  'NPIPB13',
  'NPL',
  'NPLOC4',
  'NPM1',
  'NPM2',
  'NPNT',
  'NPPA',
  'NPPC',
  'NPR1',
  'NPR2',
  'NPR3',
  'NPRL2',
  'NPRL3',
  'NPSR1-AS1',
  'NPTN',
  'NPTX1',
  'NPTX2',
  'NPVF',
  'NPW',
  'NPY',
  'NPY1R',
  'NPY2R',
  'NPY4R',
  'NQO1',
  'NQO2',
  'NR0B2',
  'NR1D1',
  'NR1D2',
  'NR1H2',
  'NR1H3',
  'NR1I2',
  'NR1I3',
  'NR2C1',
  'NR2C2',
  'NR2C2AP',
  'NR2E1',
  'NR2E3',
  'NR2F2',
  'NR2F6',
  'NR3C1',
  'NR3C2',
  'NR4A1',
  'NR4A2',
  'NR4A3',
  'NR5A2',
  'NRAP',
  'NRARP',
  'NRAV',
  'NRBF2',
  'NRBP1',
  'NRBP2',
  'NRDC',
  'NRDE2',
  'NREP',
  'NRF1',
  'NRG2',
  'NRG4',
  'NRIP1',
  'NRIP2',
  'NRIP3',
  'NRK',
  'NRL',
  'NRM',
  'NRN1',
  'NRP1',
  'NRP2',
  'NRROS',
  'NRSN1',
  'NRSN2',
  'NRXN1',
  'NRXN3',
  'NSA2',
  'NSD1',
  'NSD2',
  'NSDHL',
  'NSF',
  'NSFL1C',
  'NSG2',
  'NSL1',
  'NSMAF',
  'NSMCE1',
  'NSMCE2',
  'NSMCE3',
  'NSMCE4A',
  'NSUN2',
  'NSUN3',
  'NSUN4',
  'NSUN5',
  'NSUN5P1',
  'NSUN5P2',
  'NSUN6',
  'NSUN7',
  'NT5C',
  'NT5C1A',
  'NT5C2',
  'NT5C3A',
  'NT5C3B',
  'NT5DC1',
  'NT5DC2',
  'NT5DC3',
  'NT5E',
  'NT5M',
  'NTAN1',
  'NTF3',
  'NTF4',
  'NTHL1',
  'NTM',
  'NTMT1',
  'NTN1',
  'NTN4',
  'NTNG1',
  'NTPCR',
  'NTRK1',
  'NTRK3',
  'NTSR2',
  'NUAK1',
  'NUAK2',
  'NUB1',
  'NUBP1',
  'NUBP2',
  'NUBPL',
  'NUCB1',
  'NUCB2',
  'NUCKS1',
  'NUDC',
  'NUDCD2',
  'NUDCD3',
  'NUDT1',
  'NUDT2',
  'NUDT3',
  'NUDT4',
  'NUDT5',
  'NUDT6',
  'NUDT7',
  'NUDT9',
  'NUDT9P1',
  'NUDT10',
  'NUDT11',
  'NUDT12',
  'NUDT13',
  'NUDT15',
  'NUDT16',
  'NUDT16L1',
  'NUDT16P1',
  'NUDT17',
  'NUDT19',
  'NUDT21',
  'NUDT22',
  'NUF2',
  'NUFIP1',
  'NUFIP2',
  'NUMA1',
  'NUMB',
  'NUP35',
  'NUP37',
  'NUP42',
  'NUP43',
  'NUP50',
  'NUP54',
  'NUP58',
  'NUP62',
  'NUP85',
  'NUP88',
  'NUP93',
  'NUP98',
  'NUP107',
  'NUP133',
  'NUP153',
  'NUP155',
  'NUP160',
  'NUP188',
  'NUP205',
  'NUP210',
  'NUP214',
  'NUPR1',
  'NUS1',
  'NUSAP1',
  'NUTF2',
  'NUTM1',
  'NUTM2A-AS1',
  'NUTM2G',
  'NVL',
  'NXF1',
  'NXF3',
  'NXN',
  'NXPE3',
  'NXPH2',
  'NXPH4',
  'NXT1',
  'NYAP1',
  'NYAP2',
  'NYNRIN',
  'OAF',
  'OARD1',
  'OAS1',
  'OAS2',
  'OAS3',
  'OAT',
  'OAZ1',
  'OAZ2',
  'OAZ3',
  'OBI1',
  'OBSCN',
  'OBSL1',
  'OCA2',
  'OCEL1',
  'OCIAD1',
  'OCIAD2',
  'OCLM',
  'OCLN',
  'OCRL',
  'ODC1',
  'ODF2',
  'ODF2L',
  'ODF3B',
  'ODF3L2',
  'ODR4',
  'OFCC1',
  'OFD1',
  'OGDH',
  'OGDHL',
  'OGFOD1',
  'OGFOD3',
  'OGFRL1',
  'OGG1',
  'OGN',
  'OGT',
  'OIP5',
  'OIT3',
  'OLA1',
  'OLFM1',
  'OLFM2',
  'OLFM4',
  'OLFML1',
  'OLFML2A',
  'OLFML2B',
  'OLFML3',
  'OLIG1',
  'OMA1',
  'OMD',
  'OMG',
  'OMP',
  'ONECUT2',
  'OPA1',
  'OPA3',
  'OPHN1',
  'OPLAH',
  'OPN1SW',
  'OPN3',
  'OPN4',
  'OPRD1',
  'OPRM1',
  'OPTN',
  'OR1G1',
  'OR2A20P',
  'OR2H1',
  'OR2H2',
  'OR2K2',
  'OR2L13',
  'OR3A1',
  'OR4D2',
  'OR5P2',
  'OR5V1',
  'OR6A2',
  'OR7C1',
  'OR7C2',
  'OR7D2',
  'OR8B8',
  'OR10A3',
  'OR10A4',
  'OR10A5',
  'OR10H2',
  'OR10J1',
  'OR51E1',
  'OR51E2',
  'ORAI1',
  'ORAI2',
  'ORAI3',
  'ORC2',
  'ORC3',
  'ORC4',
  'ORC5',
  'ORM1',
  'ORMDL1',
  'ORMDL2',
  'ORMDL3',
  'OS9',
  'OSBP',
  'OSBP2',
  'OSBPL1A',
  'OSBPL2',
  'OSBPL3',
  'OSBPL5',
  'OSBPL6',
  'OSBPL7',
  'OSBPL8',
  'OSBPL9',
  'OSBPL10',
  'OSBPL11',
  'OSCAR',
  'OSCP1',
  'OSER1-DT',
  'OSER1',
  'OSGEP',
  'OSGEPL1',
  'OSGIN1',
  'OSGIN2',
  'OSMR',
  'OSR2',
  'OST4',
  'OSTC',
  'OSTF1',
  'OSTM1',
  'OTOF',
  'OTOGL',
  'OTOR',
  'OTUB1',
  'OTUB2',
  'OTUD1',
  'OTUD3',
  'OTUD4',
  'OTUD5',
  'OTUD6B',
  'OTUD7A',
  'OTUD7B',
  'OTULIN',
  'OTULINL',
  'OTX1',
  'OVCA2',
  'OVOL2',
  'OVOS',
  'OVOS2',
  'OXA1L',
  'OXCT1',
  'OXER1',
  'OXNAD1',
  'OXR1',
  'OXSM',
  'OXSR1',
  'OXTR',
  'P2RX1',
  'P2RX2',
  'P2RX3',
  'P2RX4',
  'P2RX5',
  'P2RX6',
  'P2RX7',
  'P2RY1',
  'P2RY2',
  'P2RY6',
  'P2RY8',
  'P2RY11',
  'P2RY12',
  'P2RY13',
  'P2RY14',
  'P3H1',
  'P3H2',
  'P3H3',
  'P3H4',
  'P4HA1',
  'P4HA2',
  'P4HB',
  'P4HTM',
  'PA2G4',
  'PAAF1',
  'PABPC1',
  'PABPC1L',
  'PABPC1L2A',
  'PABPC1L2B',
  'PABPC3',
  'PABPC4',
  'PABPN1',
  'PACRG',
  'PACS1',
  'PACS2',
  'PACSIN1',
  'PACSIN2',
  'PACSIN3',
  'PADI1',
  'PADI2',
  'PAF1',
  'PAFAH1B1',
  'PAFAH1B2',
  'PAFAH1B3',
  'PAFAH2',
  'PAG1',
  'PAGE1',
  'PAH',
  'PAICS',
  'PAIP1',
  'PAIP2',
  'PAIP2B',
  'PAK1',
  'PAK1IP1',
  'PAK2',
  'PAK4',
  'PAK5',
  'PALB2',
  'PALD1',
  'PALLD',
  'PALM',
  'PALM2AKAP2',
  'PALM3',
  'PALMD',
  'PAM',
  'PAM16',
  'PAMR1',
  'PAN2',
  'PANK1',
  'PANK2',
  'PANK3',
  'PANX1',
  'PAOX',
  'PAPOLA',
  'PAPOLG',
  'PAPPA',
  'PAPSS1',
  'PAPSS2',
  'PAQR3',
  'PAQR4',
  'PAQR5',
  'PAQR7',
  'PAQR8',
  'PAQR9',
  'PARD3',
  'PARD3B',
  'PARD6A',
  'PARD6B',
  'PARD6G',
  'PARG',
  'PARK7',
  'PARL',
  'PARM1',
  'PARN',
  'PARP2',
  'PARP3',
  'PARP4',
  'PARP6',
  'PARP8',
  'PARP9',
  'PARP11',
  'PARP12',
  'PARP14',
  'PARP15',
  'PARS2',
  'PARVA',
  'PARVB',
  'PARVG',
  'PASK',
  'PATE1',
  'PATJ',
  'PATL1',
  'PATZ1',
  'PAWR',
  'PAX1',
  'PAX3',
  'PAX4',
  'PAX6',
  'PAX7',
  'PAX8',
  'PAX9',
  'PAXIP1-AS1',
  'PAXIP1-AS2',
  'PAXIP1',
  'PAXX',
  'PBDC1',
  'PBK',
  'PBLD',
  'PBX1',
  'PBX2',
  'PBX3',
  'PBXIP1',
  'PC',
  'PCAT6',
  'PCBD1',
  'PCBD2',
  'PCBP1',
  'PCBP2',
  'PCBP4',
  'PCCA',
  'PCCB',
  'PCDH1',
  'PCDH8',
  'PCDH9',
  'PCDH11X',
  'PCDH12',
  'PCDH17',
  'PCDH18',
  'PCDH19',
  'PCDHA1',
  'PCDHA2',
  'PCDHA3',
  'PCDHA4',
  'PCDHA5',
  'PCDHA6',
  'PCDHA7',
  'PCDHA8',
  'PCDHA9',
  'PCDHA10',
  'PCDHA11',
  'PCDHA12',
  'PCDHA13',
  'PCDHAC1',
  'PCDHAC2',
  'PCDHB1',
  'PCDHB2',
  'PCDHB3',
  'PCDHB4',
  'PCDHB5',
  'PCDHB6',
  'PCDHB7',
  'PCDHB8',
  'PCDHB11',
  'PCDHB12',
  'PCDHB13',
  'PCDHB14',
  'PCDHB15',
  'PCDHB17P',
  'PCDHB18P',
  'PCDHGA1',
  'PCDHGA2',
  'PCDHGA3',
  'PCDHGA4',
  'PCDHGA5',
  'PCDHGA6',
  'PCDHGA7',
  'PCDHGA8',
  'PCDHGA9',
  'PCDHGA10',
  'PCDHGA11',
  'PCDHGA12',
  'PCDHGB1',
  'PCDHGB2',
  'PCDHGB3',
  'PCDHGB4',
  'PCDHGB5',
  'PCDHGB6',
  'PCDHGB7',
  'PCDHGC3',
  'PCDHGC4',
  'PCDHGC5',
  'PCED1A',
  'PCED1B',
  'PCF11',
  'PCGF1',
  'PCGF2',
  'PCGF3',
  'PCGF5',
  'PCGF6',
  'PCID2',
  'PCIF1',
  'PCK1',
  'PCLAF',
  'PCM1',
  'PCMT1',
  'PCMTD1',
  'PCMTD2',
  'PCNA',
  'PCNP',
  'PCNT',
  'PCNX1',
  'PCNX2',
  'PCNX4',
  'PCOLCE',
  'PCOTH',
  'PCP4L1',
  'PCSK1N',
  'PCSK2',
  'PCSK4',
  'PCSK5',
  'PCSK6',
  'PCSK7',
  'PCTP',
  'PCYOX1',
  'PCYOX1L',
  'PCYT1A',
  'PCYT1B',
  'PCYT2',
  'PDAP1',
  'PDC',
  'PDCD1',
  'PDCD1LG2',
  'PDCD2L',
  'PDCD4',
  'PDCD5',
  'PDCD6',
  'PDCD6IP',
  'PDCD7',
  'PDCD10',
  'PDCD11',
  'PDCL2',
  'PDCL3',
  'PDE1A',
  'PDE1B',
  'PDE1C',
  'PDE2A',
  'PDE3A',
  'PDE4A',
  'PDE4B',
  'PDE4C',
  'PDE4D',
  'PDE4DIP',
  'PDE6A',
  'PDE6D',
  'PDE6G',
  'PDE7A',
  'PDE7B',
  'PDE8A',
  'PDE8B',
  'PDE9A',
  'PDE10A',
  'PDE11A',
  'PDE12',
  'PDF',
  'PDGFA',
  'PDGFB',
  'PDGFC',
  'PDGFD',
  'PDGFRA',
  'PDGFRB',
  'PDGFRL',
  'PDHA1',
  'PDHB',
  'PDHX',
  'PDIA3',
  'PDIA4',
  'PDIA5',
  'PDIA6',
  'PDIK1L',
  'PDK1',
  'PDK2',
  'PDK3',
  'PDK4',
  'PDLIM1',
  'PDLIM2',
  'PDLIM3',
  'PDLIM4',
  'PDLIM5',
  'PDLIM7',
  'PDP1',
  'PDP2',
  'PDPK1',
  'PDPN',
  'PDPR',
  'PDRG1',
  'PDS5B',
  'PDSS1',
  'PDSS2',
  'PDX1',
  'PDXDC1',
  'PDXDC2P-NPIPB14P',
  'PDXK',
  'PDXP',
  'PDZD2',
  'PDZD4',
  'PDZD8',
  'PDZD11',
  'PDZK1IP1',
  'PDZRN3',
  'PDZRN4',
  'PEA15',
  'PEAK1',
  'PEAR1',
  'PEBP1',
  'PEBP4',
  'PECAM1',
  'PECR',
  'PEF1',
  'PEG3',
  'PEG10',
  'PELI1',
  'PELI2',
  'PELI3',
  'PELO',
  'PELP1',
  'PEMT',
  'PENK',
  'PEPD',
  'PER1',
  'PER2',
  'PER3',
  'PERM1',
  'PERP',
  'PET100',
  'PEX1',
  'PEX2',
  'PEX3',
  'PEX5',
  'PEX5L',
  'PEX6',
  'PEX7',
  'PEX11A',
  'PEX11B',
  'PEX11G',
  'PEX12',
  'PEX13',
  'PEX14',
  'PEX16',
  'PEX19',
  'PEX26',
  'PFAS',
  'PFDN1',
  'PFDN2',
  'PFDN4',
  'PFDN5',
  'PFDN6',
  'PFKFB1',
  'PFKFB2',
  'PFKFB3',
  'PFKFB4',
  'PFKL',
  'PFKM',
  'PFKP',
  'PFN1',
  'PFN2',
  'PGA3',
  'PGA4',
  'PGA5',
  'PGAM1',
  'PGAM2',
  'PGAM5',
  'PGAP1',
  'PGAP2',
  'PGAP3',
  'PGAP4',
  'PGAP6',
  'PGBD1',
  'PGBD2',
  'PGBD4',
  'PGBD5',
  'PGD',
  'PGF',
  'PGGT1B',
  'PGK1',
  'PGK2',
  'PGLS',
  'PGLYRP1',
  'PGLYRP3',
  'PGLYRP4',
  'PGM1',
  'PGM2',
  'PGM2L1',
  'PGM3',
  'PGM5',
  'PGP',
  'PGPEP1',
  'PGRMC1',
  'PGRMC2',
  'PGS1',
  'PHACTR1',
  'PHACTR2',
  'PHACTR4',
  'PHAX',
  'PHB',
  'PHB2',
  'PHC1',
  'PHC3',
  'PHETA2',
  'PHF1',
  'PHF2',
  'PHF3',
  'PHF5A',
  'PHF8',
  'PHF10',
  'PHF11',
  'PHF12',
  'PHF13',
  'PHF14',
  'PHF19',
  'PHF20',
  'PHF21A',
  'PHF23',
  'PHF24',
  'PHGDH',
  'PHIP',
  'PHKA1',
  'PHKA2',
  'PHKB',
  'PHKG1',
  'PHKG2',
  'PHLDA1',
  'PHLDA2',
  'PHLDA3',
  'PHLDB1',
  'PHLDB2',
  'PHLDB3',
  'PHLPP1',
  'PHLPP2',
  'PHOSPHO1',
  'PHOSPHO2',
  'PHPT1',
  'PHRF1',
  'PHTF1',
  'PHTF2',
  'PHYH',
  'PHYHD1',
  'PHYKPL',
  'PI4K2A',
  'PI4KA',
  'PI4KAP1',
  'PI4KAP2',
  'PI4KB',
  'PI15',
  'PIANP',
  'PIAS1',
  'PIAS2',
  'PIAS3',
  'PIBF1',
  'PICK1',
  'PID1',
  'PIDD1',
  'PIEZO1',
  'PIEZO2',
  'PIF1',
  'PIGA',
  'PIGB',
  'PIGC',
  'PIGF',
  'PIGH',
  'PIGK',
  'PIGM',
  'PIGN',
  'PIGO',
  'PIGQ',
  'PIGS',
  'PIGT',
  'PIGU',
  'PIGV',
  'PIGW',
  'PIGX',
  'PIGZ',
  'PIH1D1',
  'PIH1D2',
  'PIH1D3',
  'PIK3AP1',
  'PIK3C2A',
  'PIK3C2B',
  'PIK3C3',
  'PIK3CA',
  'PIK3CB',
  'PIK3CD-AS1',
  'PIK3CD',
  'PIK3CG',
  'PIK3IP1',
  'PIK3R1',
  'PIK3R2',
  'PIK3R3',
  'PIK3R6',
  'PIKFYVE',
  'PILRB',
  'PIM1',
  'PIM2',
  'PIM3',
  'PIMREG',
  'PIN1',
  'PIN1P1',
  'PIN4',
  'PINK1',
  'PINLYP',
  'PINX1',
  'PIP4K2A',
  'PIP4K2B',
  'PIP4K2C',
  'PIP4P1',
  'PIP4P2',
  'PIP5K1A',
  'PIP5K1C',
  'PIP5KL1',
  'PIPOX',
  'PIR',
  'PISD',
  'PITHD1',
  'PITPNA',
  'PITPNB',
  'PITPNC1',
  'PITPNM1',
  'PITPNM2',
  'PITRM1',
  'PITX2',
  'PITX3',
  'PIWIL2',
  'PJA1',
  'PJA2',
  'PKD1',
  'PKD1L1',
  'PKD1L2',
  'PKD2',
  'PKD2L2',
  'PKDCC',
  'PKDREJ',
  'PKHD1',
  'PKHD1L1',
  'PKIA',
  'PKIG',
  'PKM',
  'PKMYT1',
  'PKN1',
  'PKN2',
  'PKN3',
  'PKNOX1',
  'PKNOX2',
  'PKP1',
  'PKP2',
  'PKP4',
  'PLA1A',
  'PLA2G2A',
  'PLA2G2E',
  'PLA2G2F',
  'PLA2G4B',
  'PLA2G4C',
  'PLA2G4D',
  'PLA2G4F',
  'PLA2G5',
  'PLA2G6',
  'PLA2G7',
  'PLA2G12A',
  'PLA2R1',
  'PLAA',
  'PLAAT1',
  'PLAAT2',
  'PLAAT3',
  'PLAAT4',
  'PLAC1',
  'PLAC9',
  'PLAG1',
  'PLAGL1',
  'PLAGL2',
  'PLAT',
  'PLAU',
  'PLAUR',
  'PLB1',
  'PLBD1',
  'PLBD2',
  'PLCB1',
  'PLCB2',
  'PLCB3',
  'PLCB4',
  'PLCD1',
  'PLCD3',
  'PLCD4',
  'PLCE1',
  'PLCG1',
  'PLCG2',
  'PLCH1',
  'PLCL1',
  'PLCL2',
  'PLCXD1',
  'PLD1',
  'PLD2',
  'PLD3',
  'PLD4',
  'PLD5',
  'PLD6',
  'PLEC',
  'PLEK',
  'PLEK2',
  'PLEKHA1',
  'PLEKHA2',
  'PLEKHA4',
  'PLEKHA5',
  'PLEKHA6',
  'PLEKHA7',
  'PLEKHA8',
  'PLEKHA8P1',
  'PLEKHB1',
  'PLEKHB2',
  'PLEKHF1',
  'PLEKHF2',
  'PLEKHG1',
  'PLEKHG2',
  'PLEKHG3',
  'PLEKHG4',
  'PLEKHG4B',
  'PLEKHG5',
  'PLEKHG6',
  'PLEKHH1',
  'PLEKHH2',
  'PLEKHH3',
  'PLEKHJ1',
  'PLEKHM1',
  'PLEKHM2',
  'PLEKHO1',
  'PLEKHO2',
  'PLG',
  'PLGLB1',
  'PLGLB2',
  'PLGRKT',
  'PLIN1',
  'PLIN2',
  'PLIN3',
  'PLIN4',
  'PLIN5',
  'PLK1',
  'PLK2',
  'PLLP',
  'PLN',
  'PLOD1',
  'PLOD2',
  'PLOD3',
  'PLP1',
  'PLP2',
  'PLPBP',
  'PLPP1',
  'PLPP3',
  'PLPP5',
  'PLPP6',
  'PLPP7',
  'PLPPR1',
  'PLPPR2',
  'PLPPR4',
  'PLPPR5',
  'PLRG1',
  'PLS3',
  'PLSCR1',
  'PLSCR2',
  'PLSCR3',
  'PLSCR4',
  'PLTP',
  'PLVAP',
  'PLXDC1',
  'PLXDC2',
  'PLXNA1',
  'PLXNA2',
  'PLXNA4',
  'PLXNB1',
  'PLXNB2',
  'PLXNB3',
  'PLXND1',
  'PM20D1',
  'PM20D2',
  'PMAIP1',
  'PMCHL1',
  'PMCHL2',
  'PMEPA1',
  'PMF1',
  'PMFBP1',
  'PML',
  'PMM1',
  'PMM2',
  'PMP22',
  'PMPCA',
  'PMPCB',
  'PMS1',
  'PMS2',
  'PMS2CL',
  'PMS2P1',
  'PMS2P3',
  'PMS2P5',
  'PMVK',
  'PNCK',
  'PNISR',
  'PNKP',
  'PNLIPRP1',
  'PNMA1',
  'PNMA2',
  'PNMA6A',
  'PNMA8A',
  'PNMA8B',
  'PNMT',
  'PNN',
  'PNO1',
  'PNP',
  'PNPLA2',
  'PNPLA3',
  'PNPLA4',
  'PNPLA5',
  'PNPLA6',
  'PNPLA7',
  'PNPLA8',
  'PNPO',
  'PNPT1',
  'PNRC1',
  'PNRC2',
  'POC1B',
  'PODNL1',
  'PODXL',
  'PODXL2',
  'POF1B',
  'POFUT1',
  'POGK',
  'POGLUT1',
  'POGLUT2',
  'POGLUT3',
  'POGZ',
  'POLA1',
  'POLA2',
  'POLB',
  'POLD1',
  'POLD2',
  'POLD4',
  'POLDIP2',
  'POLDIP3',
  'POLE',
  'POLE2',
  'POLE3',
  'POLE4',
  'POLG',
  'POLH',
  'POLI',
  'POLL',
  'POLN',
  'POLQ',
  'POLR1A',
  'POLR1B',
  'POLR1C',
  'POLR1D',
  'POLR1E',
  'POLR2B',
  'POLR2C',
  'POLR2D',
  'POLR2E',
  'POLR2F',
  'POLR2G',
  'POLR2H',
  'POLR2I',
  'POLR2J',
  'POLR2J2',
  'POLR2J3',
  'POLR2J4',
  'POLR2L',
  'POLR2M',
  'POLR3A',
  'POLR3B',
  'POLR3C',
  'POLR3D',
  'POLR3E',
  'POLR3F',
  'POLR3GL',
  'POLR3H',
  'POLR3K',
  'POLRMT',
  'POM121',
  'POM121C',
  'POM121L4P',
  'POM121L9P',
  'POMC',
  'POMGNT2',
  'POMK',
  'POMP',
  'POMT1',
  'POMT2',
  'POMZP3',
  'PON1',
  'PON2',
  'PON3',
  'POP1',
  'POP4',
  'POP5',
  'POP7',
  'POPDC2',
  'POPDC3',
  'POR',
  'PORCN',
  'POSTN',
  'POTEM',
  'POU1F1',
  'POU2AF1',
  'POU2F2',
  'POU3F3',
  'POU4F3',
  'POU5F1P4',
  'POU6F1',
  'POU6F2',
  'PP2D1',
  'PP7080',
  'PPA1',
  'PPA2',
  'PPAN-P2RY11',
  'PPAN',
  'PPARA',
  'PPARD',
  'PPARG',
  'PPARGC1A',
  'PPARGC1B',
  'PPAT',
  'PPBP',
  'PPCDC',
  'PPCS',
  'PPDPF',
  'PPDPFL',
  'PPEF2',
  'PPFIA1',
  'PPFIA3',
  'PPFIA4',
  'PPFIBP1',
  'PPFIBP2',
  'PPHLN1',
  'PPIA',
  'PPIAL4A',
  'PPIB',
  'PPIC',
  'PPID',
  'PPIE',
  'PPIF',
  'PPIG',
  'PPIH',
  'PPIL1',
  'PPIL3',
  'PPIL4',
  'PPIL6',
  'PPIP5K1',
  'PPL',
  'PPM1A',
  'PPM1B',
  'PPM1D',
  'PPM1E',
  'PPM1F',
  'PPM1H',
  'PPM1J',
  'PPM1K',
  'PPM1L',
  'PPM1M',
  'PPM1N',
  'PPME1',
  'PPP1CA',
  'PPP1CB',
  'PPP1CC',
  'PPP1R1A',
  'PPP1R1B',
  'PPP1R1C',
  'PPP1R2',
  'PPP1R2C',
  'PPP1R3A',
  'PPP1R3B',
  'PPP1R3C',
  'PPP1R3D',
  'PPP1R3E',
  'PPP1R3F',
  'PPP1R7',
  'PPP1R8',
  'PPP1R9A',
  'PPP1R9B',
  'PPP1R10',
  'PPP1R11',
  'PPP1R12A',
  'PPP1R12B',
  'PPP1R12C',
  'PPP1R13B',
  'PPP1R13L',
  'PPP1R14A',
  'PPP1R14B',
  'PPP1R14C',
  'PPP1R15A',
  'PPP1R15B',
  'PPP1R16A',
  'PPP1R16B',
  'PPP1R17',
  'PPP1R18',
  'PPP1R21',
  'PPP1R26',
  'PPP1R27',
  'PPP1R32',
  'PPP1R35',
  'PPP1R36',
  'PPP1R37',
  'PPP2CA',
  'PPP2CB',
  'PPP2R1A',
  'PPP2R2A',
  'PPP2R2C',
  'PPP2R2D',
  'PPP2R3A',
  'PPP2R3B',
  'PPP2R3C',
  'PPP2R5A',
  'PPP2R5B',
  'PPP2R5C',
  'PPP2R5D',
  'PPP2R5E',
  'PPP3CA',
  'PPP3CB',
  'PPP3CC',
  'PPP3R1',
  'PPP4C',
  'PPP4R1',
  'PPP4R2',
  'PPP4R3A',
  'PPP4R3B',
  'PPP4R4',
  'PPP5C',
  'PPP6C',
  'PPP6R2',
  'PPP6R3',
  'PPRC1',
  'PPT1',
  'PPT2',
  'PPTC7',
  'PPWD1',
  'PPY',
  'PQBP1',
  'PRADC1',
  'PRAF2',
  'PRAG1',
  'PRAMEF1',
  'PRAMEF10',
  'PRAMEF12',
  'PRB1',
  'PRC1',
  'PRCC',
  'PRCP',
  'PRDM1',
  'PRDM2',
  'PRDM4',
  'PRDM5',
  'PRDM7',
  'PRDM8',
  'PRDM9',
  'PRDM10',
  'PRDM11',
  'PRDM12',
  'PRDM15',
  'PRDM16-DT',
  'PRDM16',
  'PRDX1',
  'PRDX2',
  'PRDX3',
  'PRDX4',
  'PRDX5',
  'PRDX6',
  'PREB',
  'PRELID1',
  'PRELID3B',
  'PRELP',
  'PREP',
  'PREPL',
  'PREX1',
  'PREX2',
  'PRF1',
  'PRG2',
  'PRG3',
  'PRG4',
  'PRH1',
  'PRICKLE1',
  'PRICKLE2',
  'PRICKLE3',
  'PRICKLE4',
  'PRIM2',
  'PRIMPOL',
  'PRKAA2',
  'PRKAB1',
  'PRKAB2',
  'PRKACA',
  'PRKACB',
  'PRKACG',
  'PRKAG1',
  'PRKAG2',
  'PRKAG3',
  'PRKAR1A',
  'PRKAR1B',
  'PRKAR2A',
  'PRKCA',
  'PRKCB',
  'PRKCD',
  'PRKCE',
  'PRKCG',
  'PRKCH',
  'PRKCI',
  'PRKCQ',
  'PRKCSH',
  'PRKCZ',
  'PRKD1',
  'PRKD2',
  'PRKD3',
  'PRKG1',
  'PRKN',
  'PRKRA',
  'PRKRIP1',
  'PRKX',
  'PRKY',
  'PRLH',
  'PRLR',
  'PRM3',
  'PRMT1',
  'PRMT2',
  'PRMT5',
  'PRMT6',
  'PRMT7',
  'PRMT8',
  'PRND',
  'PRNP',
  'PROCA1',
  'PROCR',
  'PRODH',
  'PROKR2',
  'PROP1',
  'PRORP',
  'PROS1',
  'PROSER1',
  'PROSER3',
  'PROX1',
  'PRPF3',
  'PRPF4',
  'PRPF4B',
  'PRPF6',
  'PRPF8',
  'PRPF18',
  'PRPF19',
  'PRPF31',
  'PRPF38A',
  'PRPF38B',
  'PRPF39',
  'PRPF40A',
  'PRPF40B',
  'PRPH',
  'PRPH2',
  'PRPS1',
  'PRPS2',
  'PRPSAP1',
  'PRPSAP2',
  'PRR4',
  'PRR5L',
  'PRR11',
  'PRR12',
  'PRR13',
  'PRR14',
  'PRR14L',
  'PRR15',
  'PRR16',
  'PRR20C',
  'PRR32',
  'PRR34',
  'PRR35',
  'PRRC1',
  'PRRC2A',
  'PRRC2B',
  'PRRC2C',
  'PRRG1',
  'PRRG2',
  'PRRG3',
  'PRRG4',
  'PRRT1',
  'PRRT2',
  'PRRX2',
  'PRSS8',
  'PRSS12',
  'PRSS16',
  'PRSS22',
  'PRSS23',
  'PRSS35',
  'PRSS37',
  'PRSS40B',
  'PRSS55',
  'PRTFDC1',
  'PRTG',
  'PRUNE1',
  'PRUNE2',
  'PRXL2A',
  'PRXL2B',
  'PRXL2C',
  'PSAP',
  'PSD',
  'PSD3',
  'PSEN1',
  'PSEN2',
  'PSENEN',
  'PSG4',
  'PSG5',
  'PSG7',
  'PSG9',
  'PSG11',
  'PSKH1',
  'PSMA1',
  'PSMA2',
  'PSMA3',
  'PSMA4',
  'PSMA5',
  'PSMA6',
  'PSMA7',
  'PSMA8',
  'PSMB1',
  'PSMB2',
  'PSMB3',
  'PSMB4',
  'PSMB5',
  'PSMB6',
  'PSMB8',
  'PSMB9',
  'PSMB10',
  'PSMC1',
  'PSMC2',
  'PSMC3',
  'PSMC3IP',
  'PSMC4',
  'PSMC5',
  'PSMC6',
  'PSMD1',
  'PSMD2',
  'PSMD3',
  'PSMD4',
  'PSMD7',
  'PSMD8',
  'PSMD9',
  'PSMD10',
  'PSMD11',
  'PSMD12',
  'PSMD13',
  'PSMD14',
  'PSME1',
  'PSME2',
  'PSME3',
  'PSME3IP1',
  'PSME4',
  'PSMF1',
  'PSMG1',
  'PSMG2',
  'PSMG3-AS1',
  'PSMG3',
  'PSMG4',
  'PSORS1C3',
  'PSPC1',
  'PSPH',
  'PSPN',
  'PSRC1',
  'PSTPIP2',
  'PTAR1',
  'PTBP1',
  'PTBP2',
  'PTBP3',
  'PTCD1',
  'PTCD2',
  'PTCD3',
  'PTCH1',
  'PTCH2',
  'PTCHD1',
  'PTCHD4',
  'PTCRA',
  'PTDSS1',
  'PTDSS2',
  'PTEN',
  'PTENP1',
  'PTER',
  'PTGDR',
  'PTGDR2',
  'PTGDS',
  'PTGER2',
  'PTGER3',
  'PTGER4',
  'PTGES',
  'PTGES2',
  'PTGES3',
  'PTGFR',
  'PTGFRN',
  'PTGIR',
  'PTGIS',
  'PTGR1',
  'PTGR2',
  'PTGS1',
  'PTGS2',
  'PTH',
  'PTH1R',
  'PTH2R',
  'PTHLH',
  'PTK2',
  'PTK6',
  'PTK7',
  'PTMA',
  'PTMS',
  'PTN',
  'PTOV1-AS1',
  'PTOV1',
  'PTP4A2',
  'PTP4A3',
  'PTPA',
  'PTPDC1',
  'PTPMT1',
  'PTPN1',
  'PTPN2',
  'PTPN3',
  'PTPN4',
  'PTPN5',
  'PTPN6',
  'PTPN7',
  'PTPN9',
  'PTPN11',
  'PTPN12',
  'PTPN13',
  'PTPN14',
  'PTPN18',
  'PTPN20',
  'PTPN21',
  'PTPN22',
  'PTPN23',
  'PTPRA',
  'PTPRB',
  'PTPRC',
  'PTPRD',
  'PTPRE',
  'PTPRG',
  'PTPRJ',
  'PTPRK',
  'PTPRM',
  'PTPRN2',
  'PTPRO',
  'PTPRR',
  'PTPRS',
  'PTPRU',
  'PTPRZ1',
  'PTRH1',
  'PTRH2',
  'PTRHD1',
  'PTS',
  'PTTG1IP',
  'PTTG3P',
  'PUDP',
  'PUF60',
  'PUM1',
  'PUM2',
  'PUM3',
  'PURA',
  'PURB',
  'PURG',
  'PURPL',
  'PUS1',
  'PUS3',
  'PUS7L',
  'PUS10',
  'PUSL1',
  'PVR',
  'PVRIG',
  'PWAR5',
  'PWP1',
  'PWWP2A',
  'PWWP2B',
  'PWWP3A',
  'PWWP3B',
  'PXDN',
  'PXDNL',
  'PXK',
  'PXMP4',
  'PXN',
  'PXT1',
  'PXYLP1',
  'PYCR1',
  'PYCR2',
  'PYCR3',
  'PYDC1',
  'PYGB',
  'PYGL',
  'PYGM',
  'PYGO1',
  'PYGO2',
  'PYHIN1',
  'PYM1',
  'PYROXD2',
  'PYY2',
  'PZP',
  'QARS1',
  'QDPR',
  'QKI',
  'QPCT',
  'QPRT',
  'QRFPR',
  'QRICH1',
  'QRICH2',
  'QRSL1',
  'QSER1',
  'QSOX1',
  'QSOX2',
  'QTRT1',
  'QTRT2',
  'R3HCC1',
  'R3HCC1L',
  'R3HDM1',
  'R3HDM2',
  'R3HDM4',
  'R3HDML',
  'RAB1A',
  'RAB1B',
  'RAB2A',
  'RAB2B',
  'RAB3A',
  'RAB3D',
  'RAB3GAP1',
  'RAB3GAP2',
  'RAB3IL1',
  'RAB3IP',
  'RAB4A',
  'RAB4B',
  'RAB5A',
  'RAB5B',
  'RAB5C',
  'RAB5IF',
  'RAB6A',
  'RAB6B',
  'RAB6C',
  'RAB6D',
  'RAB7A',
  'RAB8A',
  'RAB8B',
  'RAB9A',
  'RAB9B',
  'RAB10',
  'RAB11A',
  'RAB11B',
  'RAB11FIP1',
  'RAB11FIP2',
  'RAB11FIP3',
  'RAB11FIP4',
  'RAB11FIP5',
  'RAB12',
  'RAB13',
  'RAB14',
  'RAB15',
  'RAB17',
  'RAB18',
  'RAB20',
  'RAB21',
  'RAB23',
  'RAB24',
  'RAB25',
  'RAB26',
  'RAB27B',
  'RAB28',
  'RAB29',
  'RAB30',
  'RAB31',
  'RAB32',
  'RAB33B',
  'RAB34',
  'RAB40B',
  'RAB40C',
  'RAB42',
  'RABAC1',
  'RABEP1',
  'RABEP2',
  'RABEPK',
  'RABGAP1',
  'RABGAP1L',
  'RABGEF1',
  'RABGGTA',
  'RABGGTB',
  'RABIF',
  'RABL2A',
  'RABL2B',
  'RABL3',
  'RABL6',
  'RAC1',
  'RAC3',
  'RACGAP1',
  'RACK1',
  'RAD1',
  'RAD18',
  'RAD21',
  'RAD23A',
  'RAD23B',
  'RAD51',
  'RAD51C',
  'RAD51D',
  'RAD52',
  'RAD54B',
  'RAD54L',
  'RAD54L2',
  'RADIL',
  'RADX',
  'RAE1',
  'RAET1E',
  'RAF1',
  'RAG1',
  'RAG2',
  'RAI1',
  'RAI2',
  'RAI14',
  'RALA',
  'RALB',
  'RALBP1',
  'RALGAPA1',
  'RALGDS',
  'RALGPS1',
  'RALGPS2',
  'RALY',
  'RAMAC',
  'RAMP1',
  'RAMP2-AS1',
  'RAMP2',
  'RAMP3',
  'RAN',
  'RANBP1',
  'RANBP2',
  'RANBP3',
  'RANBP3L',
  'RANBP6',
  'RANBP9',
  'RANBP10',
  'RANBP17',
  'RANGRF',
  'RAP1A',
  'RAP1B',
  'RAP1GDS1',
  'RAP2A',
  'RAP2B',
  'RAP2C',
  'RAPGEF1',
  'RAPGEF2',
  'RAPGEF3',
  'RAPGEF4',
  'RAPGEF5',
  'RAPGEF6',
  'RAPGEFL1',
  'RAPH1',
  'RAPSN',
  'RARA',
  'RARG',
  'RARRES1',
  'RARRES2',
  'RARS1',
  'RARS2',
  'RASA1',
  'RASA2',
  'RASA3',
  'RASA4',
  'RASAL2',
  'RASAL3',
  'RASD1',
  'RASD2',
  'RASEF',
  'RASGEF1A',
  'RASGEF1B',
  'RASGRF2',
  'RASGRP1',
  'RASGRP2',
  'RASGRP3',
  'RASGRP4',
  'RASIP1',
  'RASL11A',
  'RASL12',
  'RASSF1',
  'RASSF2',
  'RASSF3',
  'RASSF4',
  'RASSF5',
  'RASSF6',
  'RASSF7',
  'RASSF8',
  'RASSF10',
  'RAVER1',
  'RAVER2',
  'RAX',
  'RAX2',
  'RB1-DT',
  'RB1',
  'RB1CC1',
  'RBAK',
  'RBAKDN',
  'RBBP4',
  'RBBP5',
  'RBBP6',
  'RBBP7',
  'RBBP8',
  'RBBP9',
  'RBCK1',
  'RBFA',
  'RBFOX1',
  'RBFOX2',
  'RBIS',
  'RBKS',
  'RBL1',
  'RBL2',
  'RBM3',
  'RBM4',
  'RBM4B',
  'RBM5',
  'RBM6',
  'RBM7',
  'RBM8A',
  'RBM10',
  'RBM11',
  'RBM12',
  'RBM12B',
  'RBM14',
  'RBM15',
  'RBM15B',
  'RBM17',
  'RBM18',
  'RBM19',
  'RBM20',
  'RBM22',
  'RBM23',
  'RBM24',
  'RBM25',
  'RBM26',
  'RBM27',
  'RBM28',
  'RBM33',
  'RBM34',
  'RBM38',
  'RBM39',
  'RBM41',
  'RBM42',
  'RBM43',
  'RBMS1',
  'RBMS2',
  'RBMS3',
  'RBMX',
  'RBMX2',
  'RBMY2FP',
  'RBP1',
  'RBP5',
  'RBP7',
  'RBPJ',
  'RBPMS',
  'RBPMS2',
  'RBSN',
  'RBX1',
  'RC3H1',
  'RCAN1',
  'RCAN2',
  'RCAN3',
  'RCBTB1',
  'RCBTB2',
  'RCC1L',
  'RCC2',
  'RCCD1',
  'RCE1',
  'RCHY1',
  'RCL1',
  'RCN2',
  'RCN3',
  'RCOR1',
  'RCOR2',
  'RCOR3',
  'RCSD1',
  'RD3',
  'RDH5',
  'RDH8',
  'RDH10',
  'RDH11',
  'RDH12',
  'RDH13',
  'RDH14',
  'RDH16',
  'RDM1',
  'RDX',
  'REC8',
  'RECK',
  'RECQL',
  'REEP1',
  'REEP2',
  'REEP3',
  'REEP4',
  'REEP5',
  'REEP6',
  'REG1A',
  'REG1B',
  'REG3G',
  'REL',
  'RELA',
  'RELB',
  'RELCH',
  'RELL1',
  'RELL2',
  'RELN',
  'RELT',
  'REM1',
  'REM2',
  'REN',
  'REPIN1',
  'REPS1',
  'REPS2',
  'RER1',
  'RERE',
  'RERG',
  'RESF1',
  'REST',
  'RET',
  'RETREG1',
  'RETREG2',
  'RETREG3',
  'RETSAT',
  'REX1BD',
  'REXO1',
  'REXO1L1P',
  'REXO2',
  'REXO4',
  'REXO5',
  'RFC2',
  'RFC4',
  'RFC5',
  'RFESD',
  'RFK',
  'RFLNA',
  'RFLNB',
  'RFNG',
  'RFPL1',
  'RFPL2',
  'RFPL3',
  'RFPL3S',
  'RFT1',
  'RFTN1',
  'RFTN2',
  'RFX1',
  'RFX2',
  'RFX3',
  'RFX4',
  'RFX5',
  'RFX7',
  'RFXANK',
  'RFXAP',
  'RGCC',
  'RGL1',
  'RGL2',
  'RGL3',
  'RGMA',
  'RGMB-AS1',
  'RGMB',
  'RGN',
  'RGP1',
  'RGPD1',
  'RGPD2',
  'RGPD3',
  'RGPD4-AS1',
  'RGPD4',
  'RGPD5',
  'RGPD6',
  'RGPD8',
  'RGS2',
  'RGS3',
  'RGS4',
  'RGS5',
  'RGS6',
  'RGS7',
  'RGS7BP',
  'RGS9BP',
  'RGS10',
  'RGS13',
  'RGS14',
  'RGS16',
  'RGS17',
  'RGS18',
  'RGS19',
  'RGS20',
  'RHBDD1',
  'RHBDD2',
  'RHBDD3',
  'RHBDF1',
  'RHBDF2',
  'RHBDL2',
  'RHBG',
  'RHCE',
  'RHCG',
  'RHD',
  'RHEB',
  'RHEBL1',
  'RHEX',
  'RHNO1',
  'RHO',
  'RHOA',
  'RHOB',
  'RHOBTB1',
  'RHOBTB2',
  'RHOBTB3',
  'RHOC',
  'RHOD',
  'RHOF',
  'RHOG',
  'RHOH',
  'RHOJ',
  'RHOQ',
  'RHOT1',
  'RHOT2',
  'RHOU',
  'RHOXF2',
  'RHOXF2B',
  'RHPN1',
  'RIBC2',
  'RIC1',
  'RIC3',
  'RIC8A',
  'RIC8B',
  'RICTOR',
  'RIDA',
  'RIF1',
  'RIIAD1',
  'RILPL1',
  'RILPL2',
  'RIMKLA',
  'RIMKLB',
  'RIMS1',
  'RIMS2',
  'RIMS3',
  'RIMS4',
  'RIN1',
  'RIN2',
  'RIN3',
  'RINT1',
  'RIOK1',
  'RIOK2',
  'RIOK3',
  'RIOX2',
  'RIPK1',
  'RIPK2',
  'RIPOR1',
  'RIPOR2',
  'RIPPLY1',
  'RIPPLY2',
  'RIT1',
  'RITA1',
  'RLF',
  'RLIM',
  'RLN3',
  'RMC1',
  'RMDN1',
  'RMDN2',
  'RMDN3',
  'RMI2',
  'RMND1',
  'RMND5A',
  'RMND5B',
  'RNASE1',
  'RNASE4',
  'RNASE6',
  'RNASEH1',
  'RNASEH2B',
  'RNASEH2C',
  'RNASEK',
  'RNASET2',
  'RND1',
  'RND2',
  'RND3',
  'RNF2',
  'RNF4',
  'RNF5',
  'RNF6',
  'RNF7',
  'RNF8',
  'RNF10',
  'RNF11',
  'RNF13',
  'RNF14',
  'RNF19A',
  'RNF19B',
  'RNF20',
  'RNF24',
  'RNF25',
  'RNF31',
  'RNF32',
  'RNF34',
  'RNF38',
  'RNF40',
  'RNF41',
  'RNF43',
  'RNF44',
  'RNF103',
  'RNF111',
  'RNF112',
  'RNF113A',
  'RNF114',
  'RNF115',
  'RNF121',
  'RNF122',
  'RNF123',
  'RNF125',
  'RNF126',
  'RNF126P1',
  'RNF128',
  'RNF130',
  'RNF135',
  'RNF138',
  'RNF139',
  'RNF141',
  'RNF144A',
  'RNF144B',
  'RNF145',
  'RNF146',
  'RNF149',
  'RNF150',
  'RNF151',
  'RNF152',
  'RNF157',
  'RNF165',
  'RNF166',
  'RNF167',
  'RNF168',
  'RNF169',
  'RNF170',
  'RNF180',
  'RNF181',
  'RNF182',
  'RNF185',
  'RNF186',
  'RNF187',
  'RNF207',
  'RNF208',
  'RNF213-AS1',
  'RNF213',
  'RNF214',
  'RNF216',
  'RNF217',
  'RNF220',
  'RNF227',
  'RNFT1',
  'RNFT2',
  'RNLS',
  'RNMT',
  'RNPC3',
  'RNPEP',
  'RNPS1',
  'ROBO1',
  'ROBO2',
  'ROBO4',
  'ROCK1',
  'ROCK2',
  'ROGDI',
  'ROM1',
  'ROMO1',
  'ROPN1',
  'ROPN1B',
  'ROR1',
  'RORA',
  'RORB',
  'RORC',
  'ROS1',
  'RP1',
  'RP9',
  'RP9P',
  'RPA1',
  'RPA2',
  'RPA3',
  'RPA4',
  'RPAIN',
  'RPAP1',
  'RPAP2',
  'RPAP3',
  'RPE',
  'RPF1',
  'RPF2',
  'RPGRIP1',
  'RPGRIP1L',
  'RPIA',
  'RPL3',
  'RPL3L',
  'RPL4',
  'RPL5',
  'RPL6',
  'RPL7',
  'RPL7A',
  'RPL8',
  'RPL9',
  'RPL10',
  'RPL10A',
  'RPL10L',
  'RPL11',
  'RPL12',
  'RPL13',
  'RPL13A',
  'RPL13AP5',
  'RPL13AP6',
  'RPL13P5',
  'RPL14',
  'RPL18',
  'RPL18A',
  'RPL19',
  'RPL21',
  'RPL21P28',
  'RPL22',
  'RPL23A',
  'RPL23AP32',
  'RPL24',
  'RPL27',
  'RPL27A',
  'RPL28',
  'RPL29',
  'RPL30',
  'RPL32',
  'RPL32P3',
  'RPL34',
  'RPL35',
  'RPL35A',
  'RPL36',
  'RPL36A',
  'RPL36AL',
  'RPL37',
  'RPL37A',
  'RPL38',
  'RPL39',
  'RPL39L',
  'RPL41',
  'RPLP0',
  'RPLP1',
  'RPLP2',
  'RPN1',
  'RPN2',
  'RPP14',
  'RPP21',
  'RPP25',
  'RPP25L',
  'RPP30',
  'RPP38',
  'RPP40',
  'RPPH1',
  'RPRD1A',
  'RPRD1B',
  'RPRD2',
  'RPS2',
  'RPS3',
  'RPS3A',
  'RPS4X',
  'RPS5',
  'RPS6',
  'RPS6KA1',
  'RPS6KA2',
  'RPS6KA3',
  'RPS6KA4',
  'RPS6KA5',
  'RPS6KB1',
  'RPS6KB2',
  'RPS6KC1',
  'RPS6KL1',
  'RPS7',
  'RPS10',
  'RPS11',
  'RPS12',
  'RPS13',
  'RPS14',
  'RPS15',
  'RPS15A',
  'RPS16',
  'RPS18',
  'RPS19',
  'RPS19BP1',
  'RPS20',
  'RPS21',
  'RPS25',
  'RPS26',
  'RPS26P11',
  'RPS27',
  'RPS27A',
  'RPS27L',
  'RPS28',
  'RPS29',
  'RPSA',
  'RPTN',
  'RPTOR',
  'RPUSD2',
  'RPUSD3',
  'RPUSD4',
  'RRAD',
  'RRAGA',
  'RRAGB',
  'RRAGC',
  'RRAGD',
  'RRAS',
  'RRBP1',
  'RREB1',
  'RRH',
  'RRM1',
  'RRM2',
  'RRM2B',
  'RRN3',
  'RRN3P2',
  'RRNAD1',
  'RRP1',
  'RRP1B',
  'RRP7A',
  'RRP9',
  'RRP12',
  'RRP15',
  'RRP36',
  'RRS1',
  'RSAD1',
  'RSAD2',
  'RSBN1',
  'RSBN1L',
  'RSC1A1',
  'RSF1',
  'RSKR',
  'RSL1D1',
  'RSL24D1',
  'RSPH3',
  'RSPH4A',
  'RSPH6A',
  'RSPH9',
  'RSPH10B2',
  'RSPH14',
  'RSPO1',
  'RSPO3',
  'RSPRY1',
  'RSRC1',
  'RSRC2',
  'RSRP1',
  'RSU1',
  'RTCA',
  'RTCB',
  'RTF1',
  'RTF2',
  'RTKN',
  'RTL3',
  'RTL5',
  'RTL6',
  'RTL8A',
  'RTL8C',
  'RTL9',
  'RTL10',
  'RTN1',
  'RTN2',
  'RTN3',
  'RTN4',
  'RTN4IP1',
  'RTN4RL1',
  'RTN4RL2',
  'RTP3',
  'RTP4',
  'RTRAF',
  'RTTN',
  'RUBCN',
  'RUBCNL',
  'RUFY1',
  'RUFY2',
  'RUFY3',
  'RUNDC1',
  'RUNDC3A',
  'RUNDC3B',
  'RUNX1',
  'RUNX1T1',
  'RUNX2',
  'RUNX3',
  'RUSC1-AS1',
  'RUSC1',
  'RUSC2',
  'RUVBL1',
  'RUVBL2',
  'RWDD1',
  'RWDD2A',
  'RWDD2B',
  'RWDD3',
  'RWDD4',
  'RXFP1',
  'RXFP2',
  'RXRA',
  'RXRG',
  'RXYLT1',
  'RYBP',
  'RYK',
  'RYR1',
  'RYR2',
  'RYR3',
  'S1PR1',
  'S1PR2',
  'S1PR3',
  'S100A1',
  'S100A2',
  'S100A4',
  'S100A6',
  'S100A7A',
  'S100A10',
  'S100A11',
  'S100A12',
  'S100A13',
  'S100A14',
  'S100A16',
  'S100G',
  'S100P',
  'S100PBP',
  'SAA4',
  'SAAL1',
  'SAC3D1',
  'SACS',
  'SAE1',
  'SAFB',
  'SAFB2',
  'SAG',
  'SAGE1',
  'SALL2',
  'SALL4',
  'SAMD1',
  'SAMD4A',
  'SAMD4B',
  'SAMD5',
  'SAMD8',
  'SAMD9',
  'SAMD9L',
  'SAMD10',
  'SAMD11',
  'SAMD13',
  'SAMD14',
  'SAMD15',
  'SAMHD1',
  'SAMM50',
  'SAMSN1',
  'SAP18',
  'SAP30',
  'SAP30BP',
  'SAP30L',
  'SAP130',
  'SAPCD1',
  'SAPCD2',
  'SAR1A',
  'SAR1B',
  'SARAF',
  'SARDH',
  'SARM1',
  'SARNP',
  'SARS2',
  'SART1',
  'SART3',
  'SASH1',
  'SASH3',
  'SASS6',
  'SAT1',
  'SAT2',
  'SATB1',
  'SATB2-AS1',
  'SAV1',
  'SAXO2',
  'SBDS',
  'SBDSP1',
  'SBF1',
  'SBF2',
  'SBK1',
  'SBNO1',
  'SBSN',
  'SC5D',
  'SCAF1',
  'SCAF4',
  'SCAF8',
  'SCAF11',
  'SCAI',
  'SCAMP1',
  'SCAMP2',
  'SCAMP3',
  'SCAMP4',
  'SCAMP5',
  'SCAND1',
  'SCAP',
  'SCAPER',
  'SCARA3',
  'SCARA5',
  'SCARB1',
  'SCARB2',
  'SCARF1',
  'SCARF2',
  'SCARNA15',
  'SCARNA16',
  'SCARNA17',
  'SCART1',
  'SCCPDH',
  'SCD',
  'SCD5',
  'SCEL',
  'SCFD1',
  'SCFD2',
  'SCG3',
  'SCG5',
  'SCGB1C1',
  'SCGB1D2',
  'SCGB2A2',
  'SCGB2B2',
  'SCGB3A1',
  'SCGN',
  'SCHIP1',
  'SCIN',
  'SCLY',
  'SCMH1',
  'SCML1',
  'SCML2',
  'SCN1A',
  'SCN1B',
  'SCN2A',
  'SCN2B',
  'SCN3A',
  'SCN3B',
  'SCN4A',
  'SCN4B',
  'SCN5A',
  'SCN7A',
  'SCN9A',
  'SCN11A',
  'SCNM1',
  'SCNN1A',
  'SCNN1D',
  'SCO1',
  'SCO2',
  'SCP2',
  'SCPEP1',
  'SCRIB',
  'SCRN2',
  'SCRN3',
  'SCT',
  'SCTR',
  'SCUBE1',
  'SCUBE2',
  'SCUBE3',
  'SCYL1',
  'SCYL2',
  'SCYL3',
  'SDAD1',
  'SDC3',
  'SDC4',
  'SDCBP',
  'SDCBP2',
  'SDCCAG8',
  'SDF2',
  'SDF2L1',
  'SDF4',
  'SDHA',
  'SDHAF1',
  'SDHAF2',
  'SDHAF3',
  'SDHAF4',
  'SDHAP1',
  'SDHAP2',
  'SDHB',
  'SDHC',
  'SDHD',
  'SDK2',
  'SDR9C7',
  'SDR39U1',
  'SDR42E1',
  'SDSL',
  'SEBOX',
  'SEC11A',
  'SEC11C',
  'SEC13',
  'SEC14L1',
  'SEC14L5',
  'SEC16A',
  'SEC16B',
  'SEC22A',
  'SEC22B',
  'SEC22C',
  'SEC23A',
  'SEC23B',
  'SEC24A',
  'SEC24B',
  'SEC24C',
  'SEC24D',
  'SEC31A',
  'SEC31B',
  'SEC61A1',
  'SEC61A2',
  'SEC61B',
  'SEC61G',
  'SEC62',
  'SEC63',
  'SECISBP2',
  'SECISBP2L',
  'SECTM1',
  'SEH1L',
  'SEL1L2',
  'SEL1L3',
  'SELE',
  'SELENBP1',
  'SELENOF',
  'SELENOH',
  'SELENOI',
  'SELENOK',
  'SELENOM',
  'SELENON',
  'SELENOO',
  'SELENOP',
  'SELENOS',
  'SELENOT',
  'SELL',
  'SEM1',
  'SEMA3A',
  'SEMA3E',
  'SEMA3F',
  'SEMA3G',
  'SEMA4A',
  'SEMA4B',
  'SEMA4C',
  'SEMA4D',
  'SEMA4F',
  'SEMA4G',
  'SEMA5A',
  'SEMA5B',
  'SEMA6A',
  'SEMA6B',
  'SEMA6C',
  'SEMA6D',
  'SENP1',
  'SENP2',
  'SENP3',
  'SENP5',
  'SENP6',
  'SENP7',
  'SENP8',
  'SEPHS1',
  'SEPHS2',
  'SEPTIN2',
  'SEPTIN3',
  'SEPTIN4',
  'SEPTIN5',
  'SEPTIN7',
  'SEPTIN7P2',
  'SEPTIN8',
  'SEPTIN9',
  'SEPTIN10',
  'SEPTIN11',
  'SEPTIN12',
  'SERAC1',
  'SERBP1',
  'SERF1A',
  'SERF1B',
  'SERF2',
  'SERHL',
  'SERINC1',
  'SERINC2',
  'SERINC3',
  'SERINC5',
  'SERP1',
  'SERPINA1',
  'SERPINA3',
  'SERPINA5',
  'SERPINA9',
  'SERPINB1',
  'SERPINB2',
  'SERPINB4',
  'SERPINB5',
  'SERPINB6',
  'SERPINB8',
  'SERPINB9',
  'SERPINB11',
  'SERPIND1',
  'SERPINE1',
  'SERPINE2',
  'SERPINF1',
  'SERPING1',
  'SERPINH1',
  'SERPINI1',
  'SERPINI2',
  'SERTAD1',
  'SERTAD2',
  'SERTAD3',
  'SERTAD4',
  'SESN1',
  'SESN2',
  'SESN3',
  'SESTD1',
  'SET',
  'SETBP1',
  'SETD1A',
  'SETD1B',
  'SETD2',
  'SETD3',
  'SETD4',
  'SETD5',
  'SETD6',
  'SETD7',
  'SETD9',
  'SETDB1',
  'SETDB2',
  'SETMAR',
  'SETX',
  'SEZ6L2',
  'SF1',
  'SF3A1',
  'SF3A2',
  'SF3A3',
  'SF3B1',
  'SF3B2',
  'SF3B3',
  'SF3B4',
  'SF3B5',
  'SFI1',
  'SFMBT1',
  'SFMBT2',
  'SFN',
  'SFPQ',
  'SFR1',
  'SFRP1',
  'SFRP2',
  'SFRP4',
  'SFSWAP',
  'SFT2D1',
  'SFT2D2',
  'SFT2D3',
  'SFTA3',
  'SFTPA1',
  'SFTPA2',
  'SFTPC',
  'SFXN1',
  'SFXN3',
  'SFXN4',
  'SFXN5',
  'SGCA',
  'SGCB',
  'SGCD',
  'SGCE',
  'SGCG',
  'SGF29',
  'SGIP1',
  'SGK1',
  'SGMS1',
  'SGMS2',
  'SGO2',
  'SGPL1',
  'SGPP1',
  'SGPP2',
  'SGSH',
  'SGSM1',
  'SGSM2',
  'SGSM3',
  'SGTA',
  'SGTB',
  'SH2B1',
  'SH2B2',
  'SH2B3',
  'SH2D1A',
  'SH2D1B',
  'SH2D3A',
  'SH2D3C',
  'SH2D4A',
  'SH2D4B',
  'SH2D5',
  'SH3BGR',
  'SH3BGRL',
  'SH3BGRL2',
  'SH3BGRL3',
  'SH3BP1',
  'SH3BP2',
  'SH3BP4',
  'SH3BP5',
  'SH3BP5L',
  'SH3D19',
  'SH3D21',
  'SH3GL1',
  'SH3GL2',
  'SH3GLB1',
  'SH3GLB2',
  'SH3KBP1',
  'SH3PXD2A',
  'SH3PXD2B',
  'SH3RF1',
  'SH3RF2',
  'SH3RF3',
  'SH3TC1',
  'SH3TC2',
  'SH3YL1',
  'SHANK1',
  'SHANK2',
  'SHANK3',
  'SHARPIN',
  'SHB',
  'SHBG',
  'SHC1',
  'SHC2',
  'SHC3',
  'SHCBP1L',
  'SHD',
  'SHE',
  'SHF',
  'SHFL',
  'SHH',
  'SHISA2',
  'SHISA3',
  'SHISA4',
  'SHISA5',
  'SHISA6',
  'SHISA7',
  'SHISA8',
  'SHISAL1',
  'SHKBP1',
  'SHLD2',
  'SHMT1',
  'SHMT2',
  'SHOC1',
  'SHOC2',
  'SHPK',
  'SHQ1',
  'SHROOM1',
  'SHROOM2',
  'SHROOM3',
  'SHROOM4',
  'SHTN1',
  'SIAE',
  'SIAH1',
  'SIAH2',
  'SIDT2',
  'SIGIRR',
  'SIGLEC1',
  'SIGLEC5',
  'SIGLEC6',
  'SIGLEC7',
  'SIGLEC10',
  'SIGLEC16',
  'SIGMAR1',
  'SIK1',
  'SIK2',
  'SIK3',
  'SIKE1',
  'SIL1',
  'SIM1',
  'SIM2',
  'SIMC1',
  'SIN3A',
  'SIN3B',
  'SINHCAF',
  'SIPA1',
  'SIPA1L1',
  'SIPA1L2',
  'SIRPA',
  'SIRPB1',
  'SIRPB2',
  'SIRPD',
  'SIRT1',
  'SIRT2',
  'SIRT3',
  'SIRT4',
  'SIRT5',
  'SIRT6',
  'SIRT7',
  'SIVA1',
  'SIX1',
  'SIX2',
  'SIX3',
  'SIX4',
  'SKA1',
  'SKA2',
  'SKA3',
  'SKAP1',
  'SKAP2',
  'SKI',
  'SKIDA1',
  'SKIL',
  'SKP1',
  'SLA',
  'SLA2',
  'SLAIN1',
  'SLAIN2',
  'SLAMF1',
  'SLAMF7',
  'SLBP',
  'SLC1A1',
  'SLC1A3',
  'SLC1A4',
  'SLC1A5',
  'SLC1A7',
  'SLC2A3',
  'SLC2A4',
  'SLC2A4RG',
  'SLC2A5',
  'SLC2A8',
  'SLC2A10',
  'SLC2A11',
  'SLC2A12',
  'SLC2A13',
  'SLC2A14',
  'SLC3A1',
  'SLC3A2',
  'SLC4A1',
  'SLC4A1AP',
  'SLC4A2',
  'SLC4A3',
  'SLC4A4',
  'SLC4A5',
  'SLC4A7',
  'SLC4A8',
  'SLC4A10',
  'SLC4A11',
  'SLC5A1',
  'SLC5A3',
  'SLC5A4',
  'SLC5A5',
  'SLC5A6',
  'SLC5A10',
  'SLC5A12',
  'SLC6A1',
  'SLC6A2',
  'SLC6A6',
  'SLC6A7',
  'SLC6A9',
  'SLC6A11',
  'SLC6A12',
  'SLC6A13',
  'SLC6A15',
  'SLC6A18',
  'SLC6A20',
  'SLC7A1',
  'SLC7A2',
  'SLC7A5',
  'SLC7A6',
  'SLC7A6OS',
  'SLC7A7',
  'SLC7A8',
  'SLC7A14',
  'SLC8A1',
  'SLC8A3',
  'SLC8B1',
  'SLC9A1',
  'SLC9A2',
  'SLC9A3R1',
  'SLC9A3R2',
  'SLC9A5',
  'SLC9A6',
  'SLC9A7',
  'SLC9A8',
  'SLC9B1',
  'SLC9B2',
  'SLC9C2',
  'SLC10A1',
  'SLC10A2',
  'SLC10A3',
  'SLC10A4',
  'SLC11A1',
  'SLC11A2',
  'SLC12A1',
  'SLC12A2',
  'SLC12A3',
  'SLC12A4',
  'SLC12A6',
  'SLC12A7',
  'SLC12A8',
  'SLC12A9',
  'SLC13A1',
  'SLC13A3',
  'SLC14A1',
  'SLC15A1',
  'SLC15A3',
  'SLC15A4',
  'SLC16A1',
  'SLC16A2',
  'SLC16A3',
  'SLC16A5',
  'SLC16A6',
  'SLC16A7',
  'SLC16A9',
  'SLC16A10',
  'SLC16A11',
  'SLC16A14',
  'SLC17A4',
  'SLC17A5',
  'SLC17A7',
  'SLC17A8',
  'SLC18B1',
  'SLC19A1',
  'SLC19A2',
  'SLC20A1',
  'SLC20A2',
  'SLC22A1',
  'SLC22A3',
  'SLC22A4',
  'SLC22A5',
  'SLC22A6',
  'SLC22A9',
  'SLC22A12',
  'SLC22A15',
  'SLC22A17',
  'SLC22A18',
  'SLC22A23',
  'SLC22A24',
  'SLC22A25',
  'SLC23A1',
  'SLC24A3',
  'SLC24A5',
  'SLC25A1',
  'SLC25A2',
  'SLC25A3',
  'SLC25A4',
  'SLC25A5-AS1',
  'SLC25A5',
  'SLC25A6',
  'SLC25A10',
  'SLC25A11',
  'SLC25A12',
  'SLC25A13',
  'SLC25A14',
  'SLC25A15',
  'SLC25A16',
  'SLC25A17',
  'SLC25A18',
  'SLC25A19',
  'SLC25A20',
  'SLC25A21-AS1',
  'SLC25A21',
  'SLC25A22',
  'SLC25A23',
  'SLC25A25',
  'SLC25A26',
  'SLC25A27',
  'SLC25A28',
  'SLC25A30',
  'SLC25A32',
  'SLC25A33',
  'SLC25A34',
  'SLC25A35',
  'SLC25A36',
  'SLC25A37',
  'SLC25A38',
  'SLC25A39',
  'SLC25A40',
  'SLC25A41',
  'SLC25A42',
  'SLC25A43',
  'SLC25A44',
  'SLC25A45',
  'SLC25A46',
  'SLC25A48',
  'SLC25A53',
  'SLC26A3',
  'SLC26A4',
  'SLC26A6',
  'SLC26A7',
  'SLC26A9',
  'SLC26A11',
  'SLC27A1',
  'SLC27A3',
  'SLC27A4',
  'SLC27A5',
  'SLC28A1',
  'SLC28A3',
  'SLC29A1',
  'SLC29A2',
  'SLC29A3',
  'SLC30A2',
  'SLC30A3',
  'SLC30A5',
  'SLC30A6',
  'SLC30A7',
  'SLC30A9',
  'SLC30A10',
  'SLC31A1',
  'SLC31A2',
  'SLC32A1',
  'SLC33A1',
  'SLC34A1',
  'SLC34A3',
  'SLC35A1',
  'SLC35A2',
  'SLC35A4',
  'SLC35A5',
  'SLC35B1',
  'SLC35B2',
  'SLC35B3',
  'SLC35B4',
  'SLC35C1',
  'SLC35D1',
  'SLC35D2',
  'SLC35E1',
  'SLC35E2A',
  'SLC35E2B',
  'SLC35E3',
  'SLC35E4',
  'SLC35F1',
  'SLC35F2',
  'SLC35F3',
  'SLC35F5',
  'SLC35F6',
  'SLC35G1',
  'SLC35G2',
  'SLC36A1',
  'SLC36A4',
  'SLC37A2',
  'SLC37A3',
  'SLC37A4',
  'SLC38A1',
  'SLC38A2',
  'SLC38A3',
  'SLC38A4',
  'SLC38A6',
  'SLC38A7',
  'SLC38A10',
  'SLC38A11',
  'SLC39A1',
  'SLC39A2',
  'SLC39A3',
  'SLC39A4',
  'SLC39A5',
  'SLC39A6',
  'SLC39A7',
  'SLC39A9',
  'SLC39A10',
  'SLC39A12',
  'SLC39A13',
  'SLC39A14',
  'SLC40A1',
  'SLC41A1',
  'SLC41A2',
  'SLC41A3',
  'SLC43A1',
  'SLC43A2',
  'SLC43A3',
  'SLC44A1',
  'SLC44A2',
  'SLC45A3',
  'SLC45A4',
  'SLC46A1',
  'SLC46A3',
  'SLC47A1',
  'SLC48A1',
  'SLC49A3',
  'SLC49A4',
  'SLC50A1',
  'SLC51A',
  'SLC52A2',
  'SLC52A3',
  'SLC66A1L',
  'SLC66A2',
  'SLC66A3',
  'SLCO1A2',
  'SLCO1C1',
  'SLCO2A1',
  'SLCO2B1',
  'SLCO3A1',
  'SLCO4A1',
  'SLCO5A1',
  'SLCO6A1',
  'SLF1',
  'SLF2',
  'SLFN5',
  'SLFN11',
  'SLFN12',
  'SLFN13',
  'SLIRP',
  'SLIT3',
  'SLITRK3',
  'SLITRK4',
  'SLK',
  'SLMAP',
  'SLN',
  'SLPI',
  'SLTM',
  'SLU7',
  'SLURP1',
  'SLX4',
  'SMAD1',
  'SMAD2',
  'SMAD3',
  'SMAD4',
  'SMAD5-AS1',
  'SMAD5',
  'SMAD6',
  'SMAD7',
  'SMAD9',
  'SMAGP',
  'SMAP1',
  'SMAP2',
  'SMARCA1',
  'SMARCA2',
  'SMARCA4',
  'SMARCA5',
  'SMARCAL1',
  'SMARCC1',
  'SMARCC2',
  'SMARCD1',
  'SMARCD2',
  'SMARCD3',
  'SMARCE1',
  'SMC2',
  'SMC3',
  'SMC4',
  'SMC5',
  'SMC6',
  'SMCO1',
  'SMCO3',
  'SMCP',
  'SMCR8',
  'SMG1',
  'SMG1P1',
  'SMG5',
  'SMG6',
  'SMG7-AS1',
  'SMG7',
  'SMG8',
  'SMG9',
  'SMIM1',
  'SMIM3',
  'SMIM4',
  'SMIM7',
  'SMIM8',
  'SMIM10',
  'SMIM10L2A',
  'SMIM10L2B',
  'SMIM11A',
  'SMIM12',
  'SMIM19',
  'SMIM20',
  'SMIM24',
  'SMIM26',
  'SMIM27',
  'SMIM29',
  'SMIM30',
  'SMNDC1',
  'SMO',
  'SMOC2',
  'SMOX',
  'SMPD1',
  'SMPD2',
  'SMPD3',
  'SMPD4',
  'SMPDL3A',
  'SMPDL3B',
  'SMPX',
  'SMR3A',
  'SMR3B',
  'SMS',
  'SMTN',
  'SMTNL1',
  'SMTNL2',
  'SMU1',
  'SMUG1',
  'SMURF1',
  'SMYD1',
  'SMYD2',
  'SMYD3',
  'SMYD4',
  'SMYD5',
  'SNAI2',
  'SNAI3-AS1',
  'SNAI3',
  'SNAP23',
  'SNAP47',
  'SNAPC1',
  'SNAPC2',
  'SNAPC4',
  'SNAPC5',
  'SNAPIN',
  'SNCA',
  'SNCAIP',
  'SNCB',
  'SNCG',
  'SND1-IT1',
  'SND1',
  'SNED1',
  'SNF8',
  'SNHG1',
  'SNHG3',
  'SNHG8',
  'SNHG11',
  'SNHG12',
  'SNHG15',
  'SNHG17',
  'SNHG29',
  'SNHG32',
  'SNIP1',
  'SNN',
  'SNORA1',
  'SNORA3A',
  'SNORA4',
  'SNORA5B',
  'SNORA8',
  'SNORA16A',
  'SNORA18',
  'SNORA21',
  'SNORA24',
  'SNORA27',
  'SNORA28',
  'SNORA37',
  'SNORA41',
  'SNORA48',
  'SNORA52',
  'SNORA56',
  'SNORA57',
  'SNORA61',
  'SNORA65',
  'SNORA67',
  'SNORA70',
  'SNORA71A',
  'SNORA71B',
  'SNORA74A',
  'SNORC',
  'SNORD1C',
  'SNORD3A',
  'SNORD3D',
  'SNORD5',
  'SNORD8',
  'SNORD14C',
  'SNORD22',
  'SNORD24',
  'SNORD25',
  'SNORD26',
  'SNORD27',
  'SNORD28',
  'SNORD30',
  'SNORD32A',
  'SNORD33',
  'SNORD34',
  'SNORD35A',
  'SNORD36A',
  'SNORD36B',
  'SNORD38B',
  'SNORD45A',
  'SNORD45B',
  'SNORD45C',
  'SNORD50B',
  'SNORD54',
  'SNORD55',
  'SNORD58B',
  'SNORD61',
  'SNORD73A',
  'SNORD83B',
  'SNORD95',
  'SNORD96A',
  'SNORD102',
  'SNORD104',
  'SNORD114-3',
  'SNORD116-4',
  'SNPH',
  'SNRK',
  'SNRNP25',
  'SNRNP27',
  'SNRNP35',
  'SNRNP40',
  'SNRNP48',
  'SNRNP70',
  'SNRNP200',
  'SNRPA',
  'SNRPA1',
  'SNRPB',
  'SNRPC',
  'SNRPD1',
  'SNRPD2',
  'SNRPD3',
  'SNRPE',
  'SNRPF',
  'SNRPG',
  'SNRPN',
  'SNTA1',
  'SNTB1',
  'SNTB2',
  'SNTG1',
  'SNTG2',
  'SNU13',
  'SNUPN',
  'SNURF',
  'SNW1',
  'SNX1',
  'SNX2',
  'SNX3',
  'SNX4',
  'SNX5',
  'SNX6',
  'SNX7',
  'SNX8',
  'SNX9',
  'SNX11',
  'SNX12',
  'SNX13',
  'SNX15',
  'SNX16',
  'SNX17',
  'SNX18',
  'SNX19',
  'SNX20',
  'SNX21',
  'SNX22',
  'SNX24',
  'SNX25',
  'SNX27',
  'SNX29',
  'SNX29P2',
  'SNX30',
  'SNX31',
  'SNX32',
  'SNX33',
  'SOAT1',
  'SOAT2',
  'SOBP',
  'SOCS1',
  'SOCS2-AS1',
  'SOCS2',
  'SOCS4',
  'SOCS5',
  'SOCS6',
  'SOCS7',
  'SOD1',
  'SOD2-OT1',
  'SOD2',
  'SOD3',
  'SOGA1',
  'SON',
  'SORBS1',
  'SORBS2',
  'SORBS3',
  'SORCS1',
  'SORD',
  'SORL1',
  'SORT1',
  'SOS1',
  'SOS2',
  'SOX4',
  'SOX6',
  'SOX7',
  'SOX8',
  'SOX10',
  'SOX11',
  'SOX12',
  'SOX13',
  'SOX15',
  'SOX17',
  'SOX18',
  'SOX21',
  'SP1',
  'SP2',
  'SP3',
  'SP4',
  'SP100',
  'SP110',
  'SP140',
  'SP140L',
  'SPAAR',
  'SPACA3',
  'SPACA9',
  'SPAG1',
  'SPAG5',
  'SPAG7',
  'SPAG9',
  'SPAG16',
  'SPANXA2-OT1',
  'SPANXB1',
  'SPANXC',
  'SPARC',
  'SPARCL1',
  'SPART',
  'SPAST',
  'SPATA1',
  'SPATA2',
  'SPATA4',
  'SPATA5L1',
  'SPATA6',
  'SPATA6L',
  'SPATA7',
  'SPATA9',
  'SPATA13',
  'SPATA17',
  'SPATA20',
  'SPATA21',
  'SPATA24',
  'SPATA25',
  'SPATA31D1',
  'SPATA31D3',
  'SPATA31E1',
  'SPATA45',
  'SPATA46',
  'SPATC1',
  'SPATC1L',
  'SPATS2',
  'SPATS2L',
  'SPC24',
  'SPC25',
  'SPCS1',
  'SPCS2',
  'SPCS3',
  'SPDEF',
  'SPDYA',
  'SPDYE1',
  'SPDYE2',
  'SPDYE5',
  'SPDYE6',
  'SPECC1',
  'SPECC1L',
  'SPEG',
  'SPEN',
  'SPESP1',
  'SPG7',
  'SPG11',
  'SPG21',
  'SPI1',
  'SPIB',
  'SPIC',
  'SPICE1',
  'SPIDR',
  'SPIN1',
  'SPIN2A',
  'SPIN2B',
  'SPIN3',
  'SPIN4',
  'SPINDOC',
  'SPINK1',
  'SPINK2',
  'SPINK4',
  'SPINK5',
  'SPINK7',
  'SPINT3',
  'SPIRE1',
  'SPIRE2',
  'SPN',
  'SPNS1',
  'SPNS2',
  'SPNS3',
  'SPOCD1',
  'SPOCK1',
  'SPOCK2',
  'SPOCK3',
  'SPON2',
  'SPOP',
  'SPOPL',
  'SPOUT1',
  'SPP2',
  'SPPL2A',
  'SPPL2B',
  'SPPL3',
  'SPR',
  'SPRED1',
  'SPRED2',
  'SPRED3',
  'SPRN',
  'SPRR1B',
  'SPRR2C',
  'SPRR4',
  'SPRTN',
  'SPRY1',
  'SPRY2',
  'SPRY3',
  'SPRY4',
  'SPRYD3',
  'SPRYD4',
  'SPRYD7',
  'SPSB1',
  'SPSB3',
  'SPSB4',
  'SPTA1',
  'SPTAN1',
  'SPTB',
  'SPTBN1',
  'SPTBN2',
  'SPTBN4',
  'SPTBN5',
  'SPTLC2',
  'SPTLC3',
  'SPTSSA',
  'SPTSSB',
  'SPTY2D1',
  'SPX',
  'SQLE',
  'SQOR',
  'SQSTM1',
  'SRA1',
  'SRARP',
  'SRC',
  'SRD5A1',
  'SRD5A3',
  'SREBF1',
  'SREK1',
  'SREK1IP1',
  'SRF',
  'SRFBP1',
  'SRGAP1',
  'SRGAP2',
  'SRGAP2B',
  'SRGAP2C',
  'SRGAP3',
  'SRGN',
  'SRI',
  'SRL',
  'SRM',
  'SRP9',
  'SRP14',
  'SRP19',
  'SRP54',
  'SRP68',
  'SRP72',
  'SRPK1',
  'SRPK2',
  'SRPK3',
  'SRPRA',
  'SRPRB',
  'SRPX',
  'SRR',
  'SRRD',
  'SRRM1',
  'SRRM2',
  'SRRM3',
  'SRRM4',
  'SRRM5',
  'SRSF1',
  'SRSF2',
  'SRSF3',
  'SRSF4',
  'SRSF5',
  'SRSF6',
  'SRSF7',
  'SRSF8',
  'SRSF9',
  'SRSF10',
  'SRSF11',
  'SRXN1',
  'SRY',
  'SS18',
  'SS18L1',
  'SS18L2',
  'SSBP1',
  'SSBP2',
  'SSBP3',
  'SSBP4',
  'SSC4D',
  'SSC5D',
  'SSH1',
  'SSH2',
  'SSH3',
  'SSNA1',
  'SSPN',
  'SSPO',
  'SSR1',
  'SSR2',
  'SSR3',
  'SSRP1',
  'SST',
  'SSTR1',
  'SSU72',
  'SSUH2',
  'SSX2',
  'SSX2IP',
  'SSX4',
  'SSX4B',
  'SSX5',
  'SSX7',
  'ST3GAL1',
  'ST3GAL2',
  'ST3GAL3',
  'ST3GAL4',
  'ST3GAL5',
  'ST3GAL6',
  'ST6GAL1',
  'ST6GAL2',
  'ST6GALNAC1',
  'ST6GALNAC2',
  'ST6GALNAC3',
  'ST6GALNAC4',
  'ST6GALNAC6',
  'ST7-AS2',
  'ST7-OT3',
  'ST7',
  'ST7L',
  'ST8SIA1',
  'ST8SIA2',
  'ST8SIA3',
  'ST8SIA4',
  'ST8SIA5',
  'ST13',
  'ST18',
  'ST20',
  'STAB1',
  'STAC2',
  'STAC3',
  'STAG1',
  'STAG2',
  'STAG3',
  'STAG3L4',
  'STAM',
  'STAMBP',
  'STAMBPL1',
  'STAP1',
  'STAP2',
  'STARD3',
  'STARD3NL',
  'STARD4',
  'STARD5',
  'STARD7',
  'STARD8',
  'STARD9',
  'STARD10',
  'STARD13',
  'STAT1',
  'STAT2',
  'STAT3',
  'STAT4',
  'STAT5A',
  'STAT5B',
  'STAT6',
  'STAU1',
  'STAU2',
  'STBD1',
  'STC1',
  'STC2',
  'STEAP1B',
  'STEAP2',
  'STEAP3',
  'STEAP4',
  'STH',
  'STIL',
  'STIM1',
  'STIM2',
  'STIMATE',
  'STING1',
  'STIP1',
  'STK3',
  'STK4',
  'STK10',
  'STK11',
  'STK16',
  'STK17A',
  'STK17B',
  'STK19',
  'STK24',
  'STK25',
  'STK32B',
  'STK32C',
  'STK33',
  'STK35',
  'STK36',
  'STK38',
  'STK38L',
  'STK39',
  'STK40',
  'STKLD1',
  'STMN1',
  'STMN2',
  'STMN3',
  'STMN4',
  'STN1',
  'STOM',
  'STOML1',
  'STOML2',
  'STON1-GTF2A1L',
  'STON1',
  'STON2',
  'STOX1',
  'STOX2',
  'STPG1',
  'STPG4',
  'STRADB',
  'STRAP',
  'STRBP',
  'STRC',
  'STRIP1',
  'STRIP2',
  'STRN',
  'STRN3',
  'STRN4',
  'STS',
  'STT3A',
  'STT3B',
  'STUB1',
  'STUM',
  'STX1A',
  'STX1B',
  'STX2',
  'STX3',
  'STX4',
  'STX5',
  'STX6',
  'STX7',
  'STX8',
  'STX10',
  'STX11',
  'STX12',
  'STX16',
  'STX17-AS1',
  'STX17',
  'STX18-AS1',
  'STX18',
  'STXBP1',
  'STXBP3',
  'STXBP4',
  'STXBP5',
  'STXBP6',
  'STYK1',
  'STYXL1',
  'SUB1',
  'SUCLA2',
  'SUCLG1',
  'SUCLG2',
  'SUCNR1',
  'SUDS3',
  'SUFU',
  'SUGCT',
  'SUGP1',
  'SUGP2',
  'SUGT1P1',
  'SUGT1P4-STRA6LP-CCDC180',
  'SULF1',
  'SULF2',
  'SULT1A1',
  'SULT1A2',
  'SULT1A3',
  'SULT1A4',
  'SULT1C4',
  'SUMF1',
  'SUMF2',
  'SUMO1',
  'SUMO2',
  'SUMO3',
  'SUMO4',
  'SUN1',
  'SUN2',
  'SUN5',
  'SUOX',
  'SUPT5H',
  'SUPT6H',
  'SUPT7L',
  'SUPT16H',
  'SUPT20H',
  'SUPV3L1',
  'SURF2',
  'SURF4',
  'SURF6',
  'SUSD1',
  'SUSD2',
  'SUSD3',
  'SUSD4',
  'SUSD6',
  'SUV39H1',
  'SUV39H2',
  'SUZ12',
  'SV2A',
  'SV2B',
  'SV2C',
  'SVBP',
  'SVEP1',
  'SVIL',
  'SVIP',
  'SWAP70',
  'SWI5',
  'SWT1',
  'SYBU',
  'SYCE2',
  'SYCP2',
  'SYCP2L',
  'SYCP3',
  'SYDE1',
  'SYDE2',
  'SYF2',
  'SYK',
  'SYMPK',
  'SYN2',
  'SYNC',
  'SYNCRIP',
  'SYNE1',
  'SYNE2',
  'SYNE3',
  'SYNGAP1',
  'SYNGR1',
  'SYNGR2',
  'SYNGR4',
  'SYNJ1',
  'SYNJ2',
  'SYNJ2BP',
  'SYNM',
  'SYNPO',
  'SYNPO2',
  'SYNPO2L',
  'SYNRG',
  'SYP',
  'SYPL1',
  'SYPL2',
  'SYS1',
  'SYT1',
  'SYT2',
  'SYT3',
  'SYT5',
  'SYT6',
  'SYT7',
  'SYT8',
  'SYT11',
  'SYT13',
  'SYT14',
  'SYT15',
  'SYTL1',
  'SYTL2',
  'SYTL3',
  'SYVN1',
  'SZRD1',
  'SZT2',
  'TAB1',
  'TAB2',
  'TAB3',
  'TAC4',
  'TACC1',
  'TACC2',
  'TACC3',
  'TACO1',
  'TACR1',
  'TACR2',
  'TACR3',
  'TACSTD2',
  'TADA1',
  'TADA2A',
  'TADA2B',
  'TADA3',
  'TAF1',
  'TAF1A',
  'TAF1B',
  'TAF1C',
  'TAF1D',
  'TAF1L',
  'TAF3',
  'TAF4',
  'TAF4B',
  'TAF5',
  'TAF5L',
  'TAF6',
  'TAF6L',
  'TAF7',
  'TAF8',
  'TAF9',
  'TAF9B',
  'TAF10',
  'TAF11',
  'TAF12',
  'TAF13',
  'TAF15',
  'TAFA4',
  'TAFA5',
  'TAGLN',
  'TAGLN2',
  'TAL1',
  'TAL2',
  'TALDO1',
  'TANC1',
  'TANC2',
  'TANGO2',
  'TANGO6',
  'TANK',
  'TAOK1',
  'TAOK2',
  'TAOK3',
  'TAP1',
  'TAPBP',
  'TAPBPL',
  'TAPT1-AS1',
  'TAPT1',
  'TARBP1',
  'TARBP2',
  'TARDBP',
  'TARP',
  'TARS1',
  'TARS2',
  'TARS3',
  'TAS2R3',
  'TAS2R4',
  'TAS2R5',
  'TAS2R7',
  'TAS2R10',
  'TAS2R13',
  'TAS2R14',
  'TAS2R19',
  'TAS2R43',
  'TASOR',
  'TASOR2',
  'TASP1',
  'TATDN1',
  'TATDN3',
  'TAX1BP3',
  'TAZ',
  'TBATA',
  'TBC1D1',
  'TBC1D2B',
  'TBC1D3',
  'TBC1D3C',
  'TBC1D3F',
  'TBC1D3G',
  'TBC1D3H',
  'TBC1D3I',
  'TBC1D3P1-DHX40P1',
  'TBC1D4',
  'TBC1D5',
  'TBC1D7',
  'TBC1D8',
  'TBC1D8B',
  'TBC1D9',
  'TBC1D9B',
  'TBC1D12',
  'TBC1D13',
  'TBC1D14',
  'TBC1D15',
  'TBC1D16',
  'TBC1D17',
  'TBC1D19',
  'TBC1D20',
  'TBC1D22A',
  'TBC1D22B',
  'TBC1D23',
  'TBC1D24',
  'TBC1D25',
  'TBC1D28',
  'TBC1D29P',
  'TBC1D31',
  'TBC1D32',
  'TBCB',
  'TBCC',
  'TBCD',
  'TBCE',
  'TBCEL',
  'TBCK',
  'TBK1',
  'TBL1X',
  'TBL1XR1',
  'TBL1Y',
  'TBL2',
  'TBL3',
  'TBP',
  'TBRG1',
  'TBRG4',
  'TBX1',
  'TBX2',
  'TBX3',
  'TBX6',
  'TBX10',
  'TBX15',
  'TBX18',
  'TBX20',
  'TBX21',
  'TBXA2R',
  'TBXAS1',
  'TCAF1',
  'TCAIM',
  'TCAP',
  'TCEA1',
  'TCEA2',
  'TCEA3',
  'TCEAL1',
  'TCEAL4',
  'TCEAL7',
  'TCEAL8',
  'TCEAL9',
  'TCEANC',
  'TCEANC2',
  'TCERG1',
  'TCF3',
  'TCF4',
  'TCF7L1',
  'TCF7L2',
  'TCF12',
  'TCF15',
  'TCF19',
  'TCF20',
  'TCF21',
  'TCF24',
  'TCF25',
  'TCFL5',
  'TCHP',
  'TCIM',
  'TCIRG1',
  'TCL1A',
  'TCL6',
  'TCN1',
  'TCN2',
  'TCOF1',
  'TCP1',
  'TCP10L',
  'TCP11L1',
  'TCP11L2',
  'TCTA',
  'TCTEX1D1',
  'TCTN1',
  'TCTN2',
  'TDG',
  'TDGF1',
  'TDGF1P3',
  'TDP1',
  'TDP2',
  'TDRD3',
  'TDRD5',
  'TDRD6',
  'TDRD10',
  'TDRD12',
  'TDRKH',
  'TDRP',
  'TEAD1',
  'TEAD2',
  'TEAD3',
  'TEAD4',
  'TEC',
  'TECPR1',
  'TECPR2',
  'TECR',
  'TECRL',
  'TECTA',
  'TECTB',
  'TEDC1',
  'TEF',
  'TEFM',
  'TEK',
  'TEKT4P2',
  'TENM1',
  'TENM2',
  'TENM3',
  'TENM4',
  'TENT4A',
  'TENT4B',
  'TENT5A',
  'TENT5C',
  'TENT5D',
  'TEP1',
  'TERB2',
  'TERF1',
  'TERF2',
  'TERF2IP',
  'TES',
  'TESC',
  'TESK1',
  'TESK2',
  'TESPA1',
  'TET1',
  'TET2',
  'TET3',
  'TEX2',
  'TEX9',
  'TEX10',
  'TEX12',
  'TEX19',
  'TEX22',
  'TEX29',
  'TEX30',
  'TEX33',
  'TEX36',
  'TEX43',
  'TEX47',
  'TEX49',
  'TEX101',
  'TEX261',
  'TEX264',
  'TFAM',
  'TFAP2A',
  'TFAP2B',
  'TFAP2C',
  'TFAP2D',
  'TFAP2E',
  'TFAP4',
  'TFB1M',
  'TFB2M',
  'TFDP1',
  'TFDP2',
  'TFE3',
  'TFEB',
  'TFEC',
  'TFF1',
  'TFG',
  'TFIP11',
  'TFPI',
  'TFPI2',
  'TFPT',
  'TFRC',
  'TGDS',
  'TGFB1',
  'TGFB1I1',
  'TGFB2',
  'TGFB3',
  'TGFBI',
  'TGFBR1',
  'TGFBR2',
  'TGFBR3',
  'TGFBRAP1',
  'TGIF1',
  'TGIF2',
  'TGM1',
  'TGM2',
  'TGM3',
  'TGM6',
  'TGOLN2',
  'TGS1',
  'THADA',
  'THAP1',
  'THAP2',
  'THAP3',
  'THAP4',
  'THAP5',
  'THAP6',
  'THAP7',
  'THAP8',
  'THAP9',
  'THAP10',
  'THAP11',
  'THAP12',
  'THBD',
  'THBS1',
  'THBS2',
  'THBS3',
  'THBS4',
  'THEM4',
  'THEM6',
  'THEMIS',
  'THEMIS2',
  'THNSL1',
  'THNSL2',
  'THOC2',
  'THOC3',
  'THOC5',
  'THOC6',
  'THOC7',
  'THOP1',
  'THPO',
  'THRA',
  'THRAP3',
  'THRB',
  'THRSP',
  'THSD1',
  'THSD4',
  'THSD7A',
  'THTPA',
  'THUMPD1',
  'THUMPD2',
  'THUMPD3',
  'THY1',
  'THYN1',
  'TIA1',
  'TIAF1',
  'TIAL1',
  'TIAM1',
  'TIAM2',
  'TICAM1',
  'TICAM2',
  'TICRR',
  'TIE1',
  'TIFA',
  'TIFAB',
  'TIGAR',
  'TIGD1',
  'TIGD2',
  'TIGD3',
  'TIGD5',
  'TIGD7',
  'TIMELESS',
  'TIMM8A',
  'TIMM8B',
  'TIMM9',
  'TIMM10',
  'TIMM13',
  'TIMM17A',
  'TIMM17B',
  'TIMM21',
  'TIMM22',
  'TIMM23',
  'TIMM29',
  'TIMM44',
  'TIMM50',
  'TIMMDC1',
  'TIMP1',
  'TIMP2',
  'TIMP3',
  'TIMP4',
  'TINAG',
  'TINAGL1',
  'TINCR',
  'TINF2',
  'TIPARP',
  'TIPIN',
  'TIPRL',
  'TIRAP',
  'TJAP1',
  'TJP1',
  'TJP2',
  'TK1',
  'TK2',
  'TKFC',
  'TKT',
  'TKTL1',
  'TKTL2',
  'TLCD2',
  'TLCD3A',
  'TLCD3B',
  'TLCD4',
  'TLCD5',
  'TLE1',
  'TLE2',
  'TLE3',
  'TLE4',
  'TLE5',
  'TLE6',
  'TLK1',
  'TLK2',
  'TLL1',
  'TLL2',
  'TLN1',
  'TLN2',
  'TLNRD1',
  'TLR2',
  'TLR4',
  'TLR5',
  'TLR7',
  'TLR8',
  'TLR9',
  'TLR10',
  'TLX2',
  'TM2D1',
  'TM2D2',
  'TM2D3',
  'TM4SF1',
  'TM4SF4',
  'TM4SF5',
  'TM4SF18',
  'TM6SF1',
  'TM6SF2',
  'TM7SF3',
  'TM9SF1',
  'TM9SF3',
  'TM9SF4',
  'TMA7',
  'TMBIM1',
  'TMBIM4',
  'TMBIM6',
  'TMC1',
  'TMC4',
  'TMC5',
  'TMC6',
  'TMC7',
  'TMC8',
  'TMCC1',
  'TMCC3',
  'TMCO1',
  'TMCO2',
  'TMCO4',
  'TMCO6',
  'TMED1',
  'TMED2',
  'TMED3',
  'TMED4',
  'TMED5',
  'TMED6',
  'TMED7',
  'TMED9',
  'TMED10',
  'TMEFF2',
  'TMEM8B',
  'TMEM9',
  'TMEM9B',
  'TMEM11',
  'TMEM14A',
  'TMEM14C',
  'TMEM17',
  'TMEM18',
  'TMEM19',
  'TMEM26',
  'TMEM30A',
  'TMEM31',
  'TMEM33',
  'TMEM35A',
  'TMEM37',
  'TMEM38A',
  'TMEM38B',
  'TMEM39A',
  'TMEM40',
  'TMEM41B',
  'TMEM42',
  'TMEM43',
  'TMEM44',
  'TMEM47',
  'TMEM50A',
  'TMEM50B',
  'TMEM51',
  'TMEM52',
  'TMEM52B',
  'TMEM53',
  'TMEM54',
  'TMEM59',
  'TMEM59L',
  'TMEM60',
  'TMEM61',
  'TMEM62',
  'TMEM63A',
  'TMEM63B',
  'TMEM63C',
  'TMEM64',
  'TMEM65',
  'TMEM67',
  'TMEM68',
  'TMEM69',
  'TMEM70',
  'TMEM74',
  'TMEM74B',
  'TMEM75',
  'TMEM79',
  'TMEM80',
  'TMEM86A',
  'TMEM86B',
  'TMEM87A',
  'TMEM87B',
  'TMEM88',
  'TMEM91',
  'TMEM94',
  'TMEM98',
  'TMEM99',
  'TMEM100',
  'TMEM101',
  'TMEM102',
  'TMEM104',
  'TMEM106B',
  'TMEM107',
  'TMEM108',
  'TMEM109',
  'TMEM114',
  'TMEM115',
  'TMEM116',
  'TMEM117',
  'TMEM119',
  'TMEM120A',
  'TMEM120B',
  'TMEM121B',
  'TMEM123',
  'TMEM125',
  'TMEM126A',
  'TMEM126B',
  'TMEM127',
  'TMEM128',
  'TMEM129',
  'TMEM130',
  'TMEM131',
  'TMEM131L',
  'TMEM132A',
  'TMEM132E',
  'TMEM134',
  'TMEM135',
  'TMEM138',
  'TMEM139',
  'TMEM140',
  'TMEM141',
  'TMEM143',
  'TMEM144',
  'TMEM147',
  'TMEM150A',
  'TMEM150C',
  'TMEM151B',
  'TMEM156',
  'TMEM158',
  'TMEM159',
  'TMEM160',
  'TMEM161A',
  'TMEM161B',
  'TMEM163',
  'TMEM164',
  'TMEM165',
  'TMEM167A',
  'TMEM167B',
  'TMEM169',
  'TMEM170A',
  'TMEM170B',
  'TMEM171',
  'TMEM174',
  'TMEM175',
  'TMEM176A',
  'TMEM176B',
  'TMEM177',
  'TMEM178A',
  'TMEM179B',
  'TMEM181',
  'TMEM182',
  'TMEM183A',
  'TMEM183B',
  'TMEM184A',
  'TMEM184B',
  'TMEM184C',
  'TMEM185A',
  'TMEM185B',
  'TMEM186',
  'TMEM187',
  'TMEM189-UBE2V1',
  'TMEM189',
  'TMEM190',
  'TMEM191A',
  'TMEM192',
  'TMEM196',
  'TMEM198',
  'TMEM198B',
  'TMEM200A',
  'TMEM200B',
  'TMEM200C',
  'TMEM201',
  'TMEM203',
  'TMEM204',
  'TMEM205',
  'TMEM209',
  'TMEM212',
  'TMEM214',
  'TMEM216',
  'TMEM217',
  'TMEM218',
  'TMEM220',
  'TMEM222',
  'TMEM223',
  'TMEM229B',
  'TMEM230',
  'TMEM231',
  'TMEM237',
  'TMEM238',
  'TMEM241',
  'TMEM242',
  'TMEM243',
  'TMEM245',
  'TMEM248',
  'TMEM250',
  'TMEM251',
  'TMEM252',
  'TMEM254',
  'TMEM255A',
  'TMEM255B',
  'TMEM258',
  'TMEM259',
  'TMEM260',
  'TMEM262',
  'TMEM263',
  'TMEM266',
  'TMEM267',
  'TMEM268',
  'TMEM272',
  'TMF1',
  'TMIE',
  'TMLHE',
  'TMOD1',
  'TMOD3',
  'TMOD4',
  'TMPO',
  'TMPPE',
  'TMPRSS4',
  'TMPRSS5',
  'TMPRSS6',
  'TMPRSS11B',
  'TMPRSS11D',
  'TMPRSS11E',
  'TMPRSS13',
  'TMPRSS15',
  'TMSB4X',
  'TMSB4Y',
  'TMSB10',
  'TMSB15A',
  'TMSB15B',
  'TMTC1',
  'TMTC2',
  'TMTC3',
  'TMTC4',
  'TMUB1',
  'TMUB2',
  'TMX1',
  'TMX2',
  'TMX4',
  'TNC',
  'TNFAIP1',
  'TNFAIP2',
  'TNFAIP3',
  'TNFAIP6',
  'TNFAIP8',
  'TNFAIP8L1',
  'TNFAIP8L2',
  'TNFRSF1A',
  'TNFRSF1B',
  'TNFRSF4',
  'TNFRSF8',
  'TNFRSF9',
  'TNFRSF10A',
  'TNFRSF10B',
  'TNFRSF12A',
  'TNFRSF13B',
  'TNFRSF14',
  'TNFRSF17',
  'TNFRSF18',
  'TNFRSF19',
  'TNFRSF21',
  'TNFRSF25',
  'TNFSF9',
  'TNFSF10',
  'TNFSF11',
  'TNFSF12-TNFSF13',
  'TNFSF12',
  'TNFSF13',
  'TNFSF13B',
  'TNFSF15',
  'TNIK',
  'TNIP1',
  'TNIP2',
  'TNK2',
  'TNKS',
  'TNKS1BP1',
  'TNKS2',
  'TNMD',
  'TNN',
  'TNNC1',
  'TNNC2',
  'TNNI1',
  'TNNI2',
  'TNNI3',
  'TNNI3K',
  'TNNT1',
  'TNNT3',
  'TNPO1',
  'TNPO2',
  'TNPO3',
  'TNRC6A',
  'TNRC6B',
  'TNRC6C',
  'TNRC18',
  'TNS2',
  'TNS3',
  'TNS4',
  'TOB1',
  'TOB2',
  'TOE1',
  'TOGARAM1',
  'TOGARAM2',
  'TOLLIP',
  'TOM1',
  'TOM1L2',
  'TOMM5',
  'TOMM6',
  'TOMM7',
  'TOMM20',
  'TOMM22',
  'TOMM34',
  'TOMM40',
  'TOMM40L',
  'TOMM70',
  'TOP1',
  'TOP1MT',
  'TOP2A',
  'TOP2B',
  'TOP3A',
  'TOP3B',
  'TOPBP1',
  'TOPORS',
  'TOR1A',
  'TOR1AIP1',
  'TOR1AIP2',
  'TOR1B',
  'TOR3A',
  'TOR4A',
  'TOX',
  'TOX2',
  'TOX3',
  'TOX4',
  'TP53',
  'TP53BP1',
  'TP53BP2',
  'TP53I3',
  'TP53I11',
  'TP53I13',
  'TP53INP1',
  'TP53INP2',
  'TP53RK',
  'TP53TG1',
  'TP53TG3',
  'TP53TG3B',
  'TP53TG3C',
  'TP53TG3D',
  'TP53TG5',
  'TP63',
  'TP73',
  'TPBG',
  'TPCN1',
  'TPCN2',
  'TPD52',
  'TPD52L1',
  'TPD52L2',
  'TPGS2',
  'TPH1',
  'TPH2',
  'TPI1',
  'TPK1',
  'TPM1',
  'TPM2',
  'TPM3',
  'TPM4',
  'TPMT',
  'TPO',
  'TPP1',
  'TPP2',
  'TPPP',
  'TPPP2',
  'TPPP3',
  'TPR',
  'TPRA1',
  'TPRG1',
  'TPRG1L',
  'TPRKB',
  'TPRN',
  'TPRXL',
  'TPSAB1',
  'TPSB2',
  'TPSD1',
  'TPSG1',
  'TPST1',
  'TPST2',
  'TPT1',
  'TPTE',
  'TPTEP1',
  'TPX2',
  'TRA2A',
  'TRA2B',
  'TRABD',
  'TRABD2A',
  'TRABD2B',
  'TRADD',
  'TRAF2',
  'TRAF3',
  'TRAF3IP1',
  'TRAF3IP2',
  'TRAF3IP3',
  'TRAF4',
  'TRAF5',
  'TRAF6',
  'TRAFD1',
  'TRAK1',
  'TRAK2',
  'TRAM1',
  'TRAM2',
  'TRANK1',
  'TRAP1',
  'TRAPPC1',
  'TRAPPC2',
  'TRAPPC2B',
  'TRAPPC2L',
  'TRAPPC3',
  'TRAPPC4',
  'TRAPPC6A',
  'TRAPPC8',
  'TRAPPC9',
  'TRAPPC10',
  'TRAPPC11',
  'TRAPPC12',
  'TRAPPC13',
  'TRAT1',
  'TRDMT1',
  'TRDN',
  'TREH',
  'TREM1',
  'TREM2',
  'TREML2',
  'TRERF1',
  'TREX1',
  'TREX2',
  'TRHDE',
  'TRIAP1',
  'TRIB1',
  'TRIB2',
  'TRIB3',
  'TRIL',
  'TRIM2',
  'TRIM3',
  'TRIM4',
  'TRIM5',
  'TRIM6-TRIM34',
  'TRIM6',
  'TRIM7',
  'TRIM8',
  'TRIM9',
  'TRIM10',
  'TRIM11',
  'TRIM13',
  'TRIM14',
  'TRIM16',
  'TRIM16L',
  'TRIM21',
  'TRIM22',
  'TRIM23',
  'TRIM24',
  'TRIM25',
  'TRIM26',
  'TRIM27',
  'TRIM28',
  'TRIM29',
  'TRIM32',
  'TRIM33',
  'TRIM34',
  'TRIM35',
  'TRIM36',
  'TRIM37',
  'TRIM38',
  'TRIM39',
  'TRIM40',
  'TRIM41',
  'TRIM42',
  'TRIM43',
  'TRIM43B',
  'TRIM44',
  'TRIM45',
  'TRIM47',
  'TRIM50',
  'TRIM52',
  'TRIM54',
  'TRIM55',
  'TRIM58',
  'TRIM59',
  'TRIM62',
  'TRIM63',
  'TRIM66',
  'TRIM67',
  'TRIM68',
  'TRIM69',
  'TRIM72',
  'TRIM73',
  'TRIM74',
  'TRIML1',
  'TRIO',
  'TRIOBP',
  'TRIP6',
  'TRIP10',
  'TRIP11',
  'TRIP12',
  'TRIP13',
  'TRIT1',
  'TRMO',
  'TRMT1',
  'TRMT1L',
  'TRMT2A',
  'TRMT2B',
  'TRMT5',
  'TRMT9B',
  'TRMT10B',
  'TRMT10C',
  'TRMT11',
  'TRMT12',
  'TRMT13',
  'TRMT44',
  'TRMT61A',
  'TRMT61B',
  'TRMT112',
  'TRMU',
  'TRNAU1AP',
  'TRNP1',
  'TRO',
  'TRPA1',
  'TRPC1',
  'TRPC2',
  'TRPC3',
  'TRPC4',
  'TRPC4AP',
  'TRPC6',
  'TRPC7',
  'TRPM4',
  'TRPM6',
  'TRPM7',
  'TRPS1',
  'TRPT1',
  'TRPV1',
  'TRPV2',
  'TRPV3',
  'TRPV4',
  'TRPV5',
  'TRPV6',
  'TRRAP',
  'TRUB2',
  'TSBP1',
  'TSC1',
  'TSC2',
  'TSC22D1',
  'TSC22D2',
  'TSC22D3',
  'TSEN2',
  'TSEN15',
  'TSEN34',
  'TSEN54',
  'TSFM',
  'TSG101',
  'TSGA10IP',
  'TSGA13',
  'TSHR',
  'TSHZ1',
  'TSHZ2',
  'TSHZ3',
  'TSKS',
  'TSLP',
  'TSN',
  'TSNARE1',
  'TSNAX',
  'TSPAN1',
  'TSPAN2',
  'TSPAN3',
  'TSPAN4',
  'TSPAN5',
  'TSPAN6',
  'TSPAN7',
  'TSPAN8',
  'TSPAN9',
  'TSPAN11',
  'TSPAN12',
  'TSPAN13',
  'TSPAN14',
  'TSPAN15',
  'TSPAN17',
  'TSPAN18',
  'TSPAN32',
  'TSPAN33',
  'TSPO',
  'TSPO2',
  'TSPY1',
  'TSPY3',
  'TSPY4',
  'TSPY26P',
  'TSPYL1',
  'TSPYL2',
  'TSPYL4',
  'TSPYL5',
  'TSR1',
  'TSR2',
  'TSR3',
  'TSSC4',
  'TSSK1B',
  'TSSK2',
  'TSSK3',
  'TSSK4',
  'TSSK6',
  'TST',
  'TSTA3',
  'TSTD1',
  'TSTD2',
  'TTBK2',
  'TTC1',
  'TTC3',
  'TTC4',
  'TTC5',
  'TTC7A',
  'TTC7B',
  'TTC8',
  'TTC9C',
  'TTC12',
  'TTC13',
  'TTC14',
  'TTC16',
  'TTC17',
  'TTC19',
  'TTC21A',
  'TTC21B',
  'TTC23',
  'TTC25',
  'TTC26',
  'TTC27',
  'TTC28-AS1',
  'TTC28',
  'TTC29',
  'TTC30A',
  'TTC31',
  'TTC32',
  'TTC33',
  'TTC38',
  'TTC39B',
  'TTC39C',
  'TTC41P',
  'TTF1',
  'TTI1',
  'TTI2',
  'TTK',
  'TTL',
  'TTLL1',
  'TTLL3',
  'TTLL5',
  'TTLL7',
  'TTLL12',
  'TTN',
  'TTPA',
  'TTPAL',
  'TTTY5',
  'TTTY8',
  'TTTY8B',
  'TTTY13',
  'TTYH1',
  'TTYH3',
  'TUB',
  'TUBA1A',
  'TUBA1B',
  'TUBA1C',
  'TUBA3C',
  'TUBA3D',
  'TUBA4A',
  'TUBA4B',
  'TUBA8',
  'TUBB',
  'TUBB1',
  'TUBB2A',
  'TUBB2B',
  'TUBB3',
  'TUBB4A',
  'TUBB4B',
  'TUBB6',
  'TUBD1',
  'TUBG1',
  'TUBG2',
  'TUBGCP2',
  'TUBGCP3',
  'TUBGCP4',
  'TUBGCP6',
  'TUFM',
  'TUFT1',
  'TUG1',
  'TULP2',
  'TULP3',
  'TULP4',
  'TUSC1',
  'TUSC2',
  'TUSC3',
  'TUT1',
  'TUT4',
  'TUT7',
  'TVP23A',
  'TVP23B',
  'TWF1',
  'TWF2',
  'TWIST1',
  'TWIST2',
  'TWISTNB',
  'TWNK',
  'TXK',
  'TXLNA',
  'TXLNB',
  'TXLNG',
  'TXLNGY',
  'TXNDC5',
  'TXNDC9',
  'TXNDC11',
  'TXNDC12',
  'TXNDC15',
  'TXNDC16',
  'TXNDC17',
  'TXNIP',
  'TXNL1',
  'TXNL4A',
  'TXNL4B',
  'TXNRD1',
  'TXNRD2',
  'TXNRD3',
  'TYMP',
  'TYMS',
  'TYRO3',
  'TYROBP',
  'TYRP1',
  'TYW1',
  'U2AF1',
  'U2AF1L4',
  'U2AF2',
  'U2SURP',
  'UACA',
  'UAP1',
  'UAP1L1',
  'UBA1',
  'UBA2',
  'UBA3',
  'UBA5',
  'UBA6',
  'UBA7',
  'UBA52',
  'UBAC1',
  'UBAC2',
  'UBALD1',
  'UBALD2',
  'UBAP1',
  'UBAP1L',
  'UBAP2',
  'UBAP2L',
  'UBASH3A',
  'UBASH3B',
  'UBB',
  'UBC',
  'UBD',
  'UBE2A',
  'UBE2B',
  'UBE2C',
  'UBE2D1',
  'UBE2D2',
  'UBE2D3',
  'UBE2D4',
  'UBE2E1',
  'UBE2E2',
  'UBE2E3',
  'UBE2F',
  'UBE2G1',
  'UBE2G2',
  'UBE2H',
  'UBE2I',
  'UBE2J1',
  'UBE2J2',
  'UBE2K',
  'UBE2L3',
  'UBE2L6',
  'UBE2M',
  'UBE2N',
  'UBE2O',
  'UBE2Q1',
  'UBE2Q2',
  'UBE2QL1',
  'UBE2R2',
  'UBE2T',
  'UBE2V1',
  'UBE2V2',
  'UBE2W',
  'UBE2Z',
  'UBE3A',
  'UBE3B',
  'UBE3C',
  'UBE3D',
  'UBE4A',
  'UBE4B',
  'UBFD1',
  'UBIAD1',
  'UBL3',
  'UBL4A',
  'UBL5',
  'UBL7',
  'UBLCP1',
  'UBN1',
  'UBN2',
  'UBOX5',
  'UBP1',
  'UBQLN1',
  'UBQLN2',
  'UBQLN4',
  'UBR1',
  'UBR2',
  'UBR3',
  'UBR5',
  'UBR7',
  'UBTD1',
  'UBTD2',
  'UBTF',
  'UBXN1',
  'UBXN2A',
  'UBXN2B',
  'UBXN4',
  'UBXN7',
  'UBXN8',
  'UBXN10',
  'UBXN11',
  'UCHL1',
  'UCHL3',
  'UCHL5',
  'UCK1',
  'UCK2',
  'UCKL1',
  'UCN',
  'UCN2',
  'UCN3',
  'UCP2',
  'UCP3',
  'UEVLD',
  'UFC1',
  'UFD1',
  'UFL1',
  'UFM1',
  'UFSP1',
  'UFSP2',
  'UGCG',
  'UGDH',
  'UGGT1',
  'UGP2',
  'UGT1A3',
  'UGT1A5',
  'UGT1A8',
  'UGT1A9',
  'UGT2B28',
  'UGT3A1',
  'UGT8',
  'UHMK1',
  'UHRF1',
  'UHRF1BP1',
  'UHRF1BP1L',
  'UIMC1',
  'ULBP1',
  'ULBP2',
  'ULBP3',
  'ULK1',
  'ULK2',
  'UMPS',
  'UNC5A',
  'UNC5B',
  'UNC5C',
  'UNC5CL',
  'UNC13B',
  'UNC13C',
  'UNC13D',
  'UNC45A',
  'UNC45B',
  'UNC50',
  'UNC80',
  'UNC93B1',
  'UNC119',
  'UNC119B',
  'UNG',
  'UNK',
  'UNKL',
  'UPB1',
  'UPF1',
  'UPF2',
  'UPF3A',
  'UPF3B',
  'UPK1A',
  'UPK3A',
  'UPK3BL1',
  'UPP1',
  'UQCC1',
  'UQCC2',
  'UQCC3',
  'UQCR10',
  'UQCR11',
  'UQCRB',
  'UQCRC1',
  'UQCRC2',
  'UQCRFS1',
  'UQCRH',
  'UQCRHL',
  'UQCRQ',
  'URB1-AS1',
  'URB1',
  'URB2',
  'URGCP',
  'URI1',
  'UROD',
  'USB1',
  'USE1',
  'USF2',
  'USF3',
  'USH1G',
  'USHBP1',
  'USO1',
  'USP1',
  'USP2',
  'USP3',
  'USP4',
  'USP5',
  'USP6',
  'USP6NL',
  'USP7',
  'USP8',
  'USP9X',
  'USP10',
  'USP11',
  'USP12',
  'USP13',
  'USP14',
  'USP15',
  'USP16',
  'USP18',
  'USP19',
  'USP20',
  'USP21',
  'USP24',
  'USP25',
  'USP27X',
  'USP28',
  'USP30',
  'USP31',
  'USP32',
  'USP32P2',
  'USP33',
  'USP34',
  'USP35',
  'USP36',
  'USP37',
  'USP38',
  'USP39',
  'USP42',
  'USP45',
  'USP46',
  'USP47',
  'USP48',
  'USP49',
  'USP51',
  'USP53',
  'USP54',
  'USPL1',
  'UST',
  'UTP3',
  'UTP4',
  'UTP6',
  'UTP11',
  'UTP14A',
  'UTP14C',
  'UTP15',
  'UTP18',
  'UTP20',
  'UTP23',
  'UTP25',
  'UTRN',
  'UTS2',
  'UTS2B',
  'UTS2R',
  'UTY',
  'UVRAG',
  'UVSSA',
  'UXS1',
  'VAC14',
  'VAMP1',
  'VAMP2',
  'VAMP3',
  'VAMP4',
  'VAMP5',
  'VAMP7',
  'VAMP8',
  'VANGL1',
  'VANGL2',
  'VAPA',
  'VAPB',
  'VARS1',
  'VARS2',
  'VASH1',
  'VASP',
  'VAT1',
  'VAT1L',
  'VAV1',
  'VAV2',
  'VAX2',
  'VBP1',
  'VCAM1',
  'VCAN',
  'VCL',
  'VCP',
  'VCPIP1',
  'VCPKMT',
  'VCX2',
  'VDAC1',
  'VDAC2',
  'VDAC3',
  'VDR',
  'VEGFA',
  'VEGFB',
  'VEGFC',
  'VEGFD',
  'VEZF1',
  'VEZT',
  'VGF',
  'VGLL2',
  'VGLL3',
  'VHL',
  'VILL',
  'VIM',
  'VIP',
  'VIPR1',
  'VIPR2',
  'VIRMA',
  'VKORC1',
  'VKORC1L1',
  'VLDLR',
  'VMA21',
  'VMO1',
  'VMP1',
  'VN1R1',
  'VN1R5',
  'VNN1',
  'VNN2',
  'VOPP1',
  'VPS4A',
  'VPS4B',
  'VPS8',
  'VPS9D1',
  'VPS11',
  'VPS13A',
  'VPS13B',
  'VPS13C',
  'VPS13D',
  'VPS16',
  'VPS18',
  'VPS25',
  'VPS26A',
  'VPS26B',
  'VPS26C',
  'VPS28',
  'VPS29',
  'VPS33A',
  'VPS33B',
  'VPS35',
  'VPS35L',
  'VPS36',
  'VPS37A',
  'VPS37B',
  'VPS37C',
  'VPS37D',
  'VPS39',
  'VPS41',
  'VPS45',
  'VPS50',
  'VPS51',
  'VPS52',
  'VPS53',
  'VPS54',
  'VPS72',
  'VRK1',
  'VRK3',
  'VRTN',
  'VSIG2',
  'VSIG4',
  'VSIG10',
  'VSIR',
  'VSNL1',
  'VSTM2A-OT1',
  'VSTM2A',
  'VSTM2L',
  'VSTM4',
  'VSX1',
  'VTA1',
  'VTI1A',
  'VTI1B',
  'VTN',
  'VWA1',
  'VWA3A',
  'VWA3B',
  'VWA5A',
  'VWA5B1',
  'VWA5B2',
  'VWA7',
  'VWA8',
  'VWF',
  'VXN',
  'WAC',
  'WAPL',
  'WARS1',
  'WARS2',
  'WASF2',
  'WASF3',
  'WASH2P',
  'WASH3P',
  'WASH7P',
  'WASHC2A',
  'WASHC2C',
  'WASHC3',
  'WASHC5',
  'WASL',
  'WBP1',
  'WBP1L',
  'WBP2',
  'WBP2NL',
  'WBP4',
  'WBP11',
  'WDCP',
  'WDFY1',
  'WDFY2',
  'WDFY3-AS2',
  'WDFY4',
  'WDPCP',
  'WDR1',
  'WDR3',
  'WDR4',
  'WDR5',
  'WDR5B',
  'WDR6',
  'WDR7',
  'WDR11',
  'WDR12',
  'WDR13',
  'WDR18',
  'WDR19',
  'WDR20',
  'WDR24',
  'WDR25',
  'WDR26',
  'WDR27',
  'WDR31',
  'WDR33',
  'WDR34',
  'WDR35',
  'WDR37',
  'WDR41',
  'WDR43',
  'WDR45',
  'WDR46',
  'WDR47',
  'WDR48',
  'WDR53',
  'WDR54',
  'WDR55',
  'WDR59',
  'WDR60',
  'WDR61',
  'WDR62',
  'WDR63',
  'WDR70',
  'WDR72',
  'WDR73',
  'WDR74',
  'WDR75',
  'WDR76',
  'WDR77',
  'WDR78',
  'WDR82',
  'WDR83',
  'WDR83OS',
  'WDR86',
  'WDR87',
  'WDR88',
  'WDR91',
  'WDR92',
  'WDR93',
  'WDSUB1',
  'WDTC1',
  'WDYHV1',
  'WEE1',
  'WFDC2',
  'WFDC5',
  'WFDC13',
  'WFDC21P',
  'WFIKKN1',
  'WFS1',
  'WHAMM',
  'WIPF1',
  'WIPF2',
  'WIPF3',
  'WIPI1',
  'WIPI2',
  'WIZ',
  'WLS',
  'WNK1',
  'WNK2',
  'WNT1',
  'WNT2B',
  'WNT4',
  'WNT5A',
  'WNT5B',
  'WNT6',
  'WNT7B',
  'WNT8A',
  'WNT9A',
  'WNT10B',
  'WNT11',
  'WRAP53',
  'WRAP73',
  'WRNIP1',
  'WSB1',
  'WSB2',
  'WSCD1',
  'WTAP',
  'WTIP',
  'WWC2',
  'WWC3',
  'WWOX',
  'WWP1',
  'WWP2',
  'WWTR1-AS1',
  'WWTR1',
  'XAB2',
  'XAF1',
  'XAGE1B',
  'XAGE3',
  'XBP1',
  'XCR1',
  'XDH',
  'XG',
  'XIAP',
  'XIRP1',
  'XIRP2',
  'XK',
  'XKR4',
  'XKR8',
  'XPA',
  'XPC',
  'XPNPEP1',
  'XPNPEP3',
  'XPO1',
  'XPO4',
  'XPO6',
  'XPO7',
  'XPR1',
  'XRCC1',
  'XRCC3',
  'XRCC4',
  'XRCC5',
  'XRCC6',
  'XRN2',
  'XRRA1',
  'XXYLT1',
  'XYLB',
  'XYLT1',
  'XYLT2',
  'YAE1',
  'YAF2',
  'YAP1',
  'YARS1',
  'YARS2',
  'YBEY',
  'YBX2',
  'YBX3',
  'YDJC',
  'YEATS2',
  'YEATS4',
  'YES1',
  'YIF1A',
  'YIF1B',
  'YIPF1',
  'YIPF2',
  'YIPF3',
  'YIPF5',
  'YIPF6',
  'YJEFN3',
  'YJU2',
  'YME1L1',
  'YOD1',
  'YPEL1',
  'YPEL2',
  'YPEL3',
  'YPEL4',
  'YPEL5',
  'YRDC',
  'YTHDC1',
  'YTHDC2',
  'YTHDF1',
  'YTHDF2',
  'YTHDF3',
  'YWHAB',
  'YWHAE',
  'YWHAG',
  'YWHAH',
  'YWHAQ',
  'YWHAZ',
  'YY1',
  'YY1AP1',
  'YY2',
  'ZADH2',
  'ZAN',
  'ZBED1',
  'ZBED3',
  'ZBED4',
  'ZBED5',
  'ZBED6CL',
  'ZBP1',
  'ZBTB1',
  'ZBTB2',
  'ZBTB3',
  'ZBTB4',
  'ZBTB6',
  'ZBTB7A',
  'ZBTB7B',
  'ZBTB7C',
  'ZBTB8A',
  'ZBTB8OS',
  'ZBTB9',
  'ZBTB10',
  'ZBTB11-AS1',
  'ZBTB11',
  'ZBTB16',
  'ZBTB17',
  'ZBTB18',
  'ZBTB20',
  'ZBTB21',
  'ZBTB24',
  'ZBTB25',
  'ZBTB32',
  'ZBTB33',
  'ZBTB34',
  'ZBTB38',
  'ZBTB39',
  'ZBTB40',
  'ZBTB41',
  'ZBTB42',
  'ZBTB43',
  'ZBTB44',
  'ZBTB45',
  'ZBTB46',
  'ZBTB47',
  'ZBTB48',
  'ZBTB49',
  'ZC2HC1A',
  'ZC2HC1B',
  'ZC2HC1C',
  'ZC3H3',
  'ZC3H4',
  'ZC3H6',
  'ZC3H7B',
  'ZC3H8',
  'ZC3H10',
  'ZC3H11A',
  'ZC3H12C',
  'ZC3H12D',
  'ZC3H14',
  'ZC3H15',
  'ZC3H18',
  'ZC3HAV1',
  'ZC3HAV1L',
  'ZC3HC1',
  'ZC4H2',
  'ZCCHC2',
  'ZCCHC3',
  'ZCCHC4',
  'ZCCHC7',
  'ZCCHC8',
  'ZCCHC9',
  'ZCCHC10',
  'ZCCHC14',
  'ZCCHC17',
  'ZCCHC18',
  'ZCCHC24',
  'ZCRB1',
  'ZCWPW1',
  'ZCWPW2',
  'ZDBF2',
  'ZDHHC2',
  'ZDHHC3',
  'ZDHHC4',
  'ZDHHC5',
  'ZDHHC6',
  'ZDHHC7',
  'ZDHHC9',
  'ZDHHC11',
  'ZDHHC12',
  'ZDHHC13',
  'ZDHHC15',
  'ZDHHC16',
  'ZDHHC17',
  'ZDHHC19',
  'ZDHHC20',
  'ZDHHC21',
  'ZDHHC22',
  'ZDHHC23',
  'ZDHHC24',
  'ZEB1',
  'ZEB2',
  'ZER1',
  'ZFAND1',
  'ZFAND2A',
  'ZFAND2B',
  'ZFAND3',
  'ZFAND4',
  'ZFAND5',
  'ZFAND6',
  'ZFAT',
  'ZFC3H1',
  'ZFHX2',
  'ZFHX3',
  'ZFHX4',
  'ZFP2',
  'ZFP3',
  'ZFP30',
  'ZFP36',
  'ZFP36L1',
  'ZFP36L2',
  'ZFP37',
  'ZFP41',
  'ZFP62',
  'ZFP64',
  'ZFP69',
  'ZFP69B',
  'ZFP82',
  'ZFP90',
  'ZFP91',
  'ZFP92',
  'ZFPL1',
  'ZFPM1',
  'ZFPM2',
  'ZFR',
  'ZFR2',
  'ZFX',
  'ZFYVE1',
  'ZFYVE9',
  'ZFYVE19',
  'ZFYVE21',
  'ZFYVE26',
  'ZFYVE27',
  'ZG16',
  'ZGPAT',
  'ZGRF1',
  'ZHX1-C8orf76',
  'ZHX1',
  'ZHX2',
  'ZHX3',
  'ZIC1',
  'ZIK1',
  'ZKSCAN1',
  'ZKSCAN2',
  'ZKSCAN5',
  'ZKSCAN7',
  'ZKSCAN8',
  'ZMAT2',
  'ZMAT3',
  'ZMAT5',
  'ZMIZ1',
  'ZMIZ2',
  'ZMPSTE24',
  'ZMYM2',
  'ZMYM3',
  'ZMYM4',
  'ZMYM5',
  'ZMYM6',
  'ZMYND8',
  'ZMYND11',
  'ZMYND12',
  'ZMYND15',
  'ZMYND19',
  'ZNF2',
  'ZNF3',
  'ZNF7',
  'ZNF8',
  'ZNF12',
  'ZNF14',
  'ZNF16',
  'ZNF17',
  'ZNF18',
  'ZNF19',
  'ZNF22',
  'ZNF23',
  'ZNF24',
  'ZNF25',
  'ZNF26',
  'ZNF28',
  'ZNF30',
  'ZNF32',
  'ZNF33A',
  'ZNF33B',
  'ZNF34',
  'ZNF35',
  'ZNF37BP',
  'ZNF41',
  'ZNF43',
  'ZNF44',
  'ZNF57',
  'ZNF70',
  'ZNF71',
  'ZNF75A',
  'ZNF75D',
  'ZNF76',
  'ZNF77',
  'ZNF79',
  'ZNF80',
  'ZNF83',
  'ZNF84',
  'ZNF90',
  'ZNF91',
  'ZNF92',
  'ZNF93',
  'ZNF99',
  'ZNF100',
  'ZNF101',
  'ZNF106',
  'ZNF107',
  'ZNF114',
  'ZNF117',
  'ZNF132',
  'ZNF133',
  'ZNF134',
  'ZNF135',
  'ZNF136',
  'ZNF138',
  'ZNF141',
  'ZNF142',
  'ZNF143',
  'ZNF146',
  'ZNF148',
  'ZNF154',
  'ZNF155',
  'ZNF160',
  'ZNF165',
  'ZNF169',
  'ZNF174',
  'ZNF175',
  'ZNF180',
  'ZNF182',
  'ZNF185',
  'ZNF189',
  'ZNF195',
  'ZNF197',
  'ZNF200',
  'ZNF205',
  'ZNF207',
  'ZNF211',
  'ZNF212',
  'ZNF213',
  'ZNF214',
  'ZNF215',
  'ZNF217',
  'ZNF219',
  'ZNF222',
  'ZNF224',
  'ZNF225',
  'ZNF227',
  'ZNF229',
  'ZNF232',
  'ZNF235',
  'ZNF236-DT',
  'ZNF236',
  'ZNF239',
  'ZNF248',
  'ZNF250',
  'ZNF251',
  'ZNF252P',
  'ZNF253',
  'ZNF256',
  'ZNF257',
  'ZNF260',
  'ZNF263',
  'ZNF264',
  'ZNF266',
  'ZNF267',
  'ZNF268',
  'ZNF271P',
  'ZNF273',
  'ZNF274',
  'ZNF275',
  'ZNF277',
  'ZNF280B',
  'ZNF280C',
  'ZNF280D',
  'ZNF281',
  'ZNF282',
  'ZNF284',
  'ZNF285',
  'ZNF286A',
  'ZNF286B',
  'ZNF287',
  'ZNF292',
  'ZNF296',
  'ZNF300',
  'ZNF302',
  'ZNF304',
  'ZNF316',
  'ZNF317',
  'ZNF318',
  'ZNF319',
  'ZNF320',
  'ZNF322',
  'ZNF324',
  'ZNF326',
  'ZNF329',
  'ZNF330',
  'ZNF331',
  'ZNF333',
  'ZNF335',
  'ZNF337',
  'ZNF341',
  'ZNF343',
  'ZNF346',
  'ZNF350',
  'ZNF354A',
  'ZNF354B',
  'ZNF354C',
  'ZNF362',
  'ZNF366',
  'ZNF367',
  'ZNF382',
  'ZNF383',
  'ZNF384',
  'ZNF385A',
  'ZNF385B',
  'ZNF385D',
  'ZNF391',
  'ZNF394',
  'ZNF395',
  'ZNF397',
  'ZNF398',
  'ZNF404',
  'ZNF407',
  'ZNF408',
  'ZNF410',
  'ZNF414',
  'ZNF415',
  'ZNF416',
  'ZNF418',
  'ZNF419',
  'ZNF420',
  'ZNF423',
  'ZNF425',
  'ZNF426',
  'ZNF428',
  'ZNF429',
  'ZNF430',
  'ZNF431',
  'ZNF432',
  'ZNF436-AS1',
  'ZNF436',
  'ZNF438',
  'ZNF439',
  'ZNF440',
  'ZNF441',
  'ZNF442',
  'ZNF443',
  'ZNF444',
  'ZNF445',
  'ZNF449',
  'ZNF451',
  'ZNF460',
  'ZNF461',
  'ZNF462',
  'ZNF467',
  'ZNF469',
  'ZNF470',
  'ZNF473',
  'ZNF474',
  'ZNF479',
  'ZNF480',
  'ZNF483',
  'ZNF484',
  'ZNF486',
  'ZNF490',
  'ZNF491',
  'ZNF493',
  'ZNF496',
  'ZNF497',
  'ZNF501',
  'ZNF502',
  'ZNF503-AS2',
  'ZNF506',
  'ZNF507',
  'ZNF510',
  'ZNF511',
  'ZNF512',
  'ZNF512B',
  'ZNF514',
  'ZNF516',
  'ZNF517',
  'ZNF518B',
  'ZNF519',
  'ZNF521',
  'ZNF524',
  'ZNF526',
  'ZNF527',
  'ZNF528',
  'ZNF529',
  'ZNF530',
  'ZNF532',
  'ZNF536',
  'ZNF540',
  'ZNF541',
  'ZNF542P',
  'ZNF543',
  'ZNF544',
  'ZNF547',
  'ZNF548',
  'ZNF549',
  'ZNF550',
  'ZNF551',
  'ZNF552',
  'ZNF555',
  'ZNF556',
  'ZNF559',
  'ZNF561',
  'ZNF562',
  'ZNF563',
  'ZNF564',
  'ZNF565',
  'ZNF566',
  'ZNF567',
  'ZNF569',
  'ZNF570',
  'ZNF571',
  'ZNF573',
  'ZNF574',
  'ZNF576',
  'ZNF579',
  'ZNF582',
  'ZNF583',
  'ZNF584',
  'ZNF585A',
  'ZNF585B',
  'ZNF586',
  'ZNF587',
  'ZNF589',
  'ZNF592',
  'ZNF593',
  'ZNF594',
  'ZNF597',
  'ZNF598',
  'ZNF600',
  'ZNF605',
  'ZNF609',
  'ZNF611',
  'ZNF614',
  'ZNF615',
  'ZNF618',
  'ZNF619',
  'ZNF620',
  'ZNF621',
  'ZNF622',
  'ZNF623',
  'ZNF625',
  'ZNF626',
  'ZNF627',
  'ZNF628',
  'ZNF630',
  'ZNF638',
  'ZNF639',
  'ZNF641',
  'ZNF644',
  'ZNF646',
  'ZNF649',
  'ZNF652',
  'ZNF654',
  'ZNF655',
  'ZNF664',
  'ZNF665',
  'ZNF667-AS1',
  'ZNF668',
  'ZNF669',
  'ZNF670',
  'ZNF671',
  'ZNF672',
  'ZNF674',
  'ZNF677',
  'ZNF678',
  'ZNF680',
  'ZNF682',
  'ZNF684',
  'ZNF687',
  'ZNF688',
  'ZNF689',
  'ZNF692',
  'ZNF695',
  'ZNF697',
  'ZNF700',
  'ZNF701',
  'ZNF703',
  'ZNF704',
  'ZNF706',
  'ZNF710',
  'ZNF711',
  'ZNF717',
  'ZNF718',
  'ZNF721',
  'ZNF738',
  'ZNF740',
  'ZNF746',
  'ZNF747',
  'ZNF749',
  'ZNF750',
  'ZNF763',
  'ZNF764',
  'ZNF765',
  'ZNF766',
  'ZNF767P',
  'ZNF768',
  'ZNF770',
  'ZNF771',
  'ZNF775',
  'ZNF776',
  'ZNF777',
  'ZNF778',
  'ZNF780A',
  'ZNF781',
  'ZNF782',
  'ZNF783',
  'ZNF784',
  'ZNF785',
  'ZNF786',
  'ZNF787',
  'ZNF789',
  'ZNF791',
  'ZNF792',
  'ZNF793',
  'ZNF800',
  'ZNF804B',
  'ZNF805',
  'ZNF808',
  'ZNF813',
  'ZNF814',
  'ZNF816',
  'ZNF821',
  'ZNF827',
  'ZNF829',
  'ZNF830',
  'ZNF831',
  'ZNF836',
  'ZNF839',
  'ZNF844',
  'ZNF846',
  'ZNF850',
  'ZNF852',
  'ZNF853',
  'ZNF862',
  'ZNF865',
  'ZNF875',
  'ZNF876P',
  'ZNF879',
  'ZNF880',
  'ZNF883',
  'ZNFX1',
  'ZNHIT1',
  'ZNHIT3',
  'ZNRD1',
  'ZNRD1ASP',
  'ZNRD2',
  'ZNRF1',
  'ZNRF2',
  'ZNRF3',
  'ZP1',
  'ZP3',
  'ZPLD1',
  'ZPR1',
  'ZRANB1',
  'ZRANB2',
  'ZRANB3',
  'ZRSR2',
  'ZSCAN1',
  'ZSCAN4',
  'ZSCAN5A',
  'ZSCAN9',
  'ZSCAN10',
  'ZSCAN16',
  'ZSCAN18',
  'ZSCAN20',
  'ZSCAN21',
  'ZSCAN23',
  'ZSCAN25',
  'ZSCAN26',
  'ZSCAN29',
  'ZSCAN30',
  'ZSCAN31',
  'ZSCAN32',
  'ZSWIM1',
  'ZSWIM2',
  'ZSWIM4',
  'ZSWIM5',
  'ZSWIM6',
  'ZSWIM8',
  'ZUP1',
  'ZW10',
  'ZWILCH',
  'ZWINT',
  'ZXDA',
  'ZXDB',
  'ZXDC',
  'ZYG11A',
  'ZYG11B',
  'ZYX',
  'ZZEF1',
  'ZZZ3',
];

export default MetaAnalysisGenes;
